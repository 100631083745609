import React from "react";

import { AppBar, Toolbar, IconButton, Typography, Box, useTheme, useMediaQuery } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import User from "../../types/User";
import { useLinkTo } from "./EditingDetection";


/** ヘッダーパラメーター */
export interface HeaderProps {
    user: User | null;
    title: string;
    img?: string;
    children?: React.ReactNode;
    onMenuClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/** ヘッダー */
const Header = (props: HeaderProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(() => theme.breakpoints.down("sm"));
    const linkTo = useLinkTo();
    const handleTitleClick = React.useCallback(() => linkTo("/"), [linkTo]);
    return (
        <AppBar>
            <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="sidebar toggle" sx={{ mr: 2 }} onClick={props.onMenuClick}>
                    <MenuIcon />
                </IconButton>
                <Box component="div" sx={{ flexGrow: 1, pl: "1rem", pr: "2rem" }}>
                    <Typography display="inline-block" variant="h6" sx={{cursor: "pointer", "&:hover": {textShadow: "2px 2px #ccc;"}}} onClick={handleTitleClick}>
                        {!props.img && props.img !== "" && <Box component="img" src={props.img} alt={props.title} />}
                        {[props.title, `${props.user?.corporate}: ${props.user?.userId}`, props.user?.officeName].filter(text => text !== undefined && text !== null).map((text, index) =>  (
                            <span key={index} style={{marginRight: "1rem", display: (isMobile && index > 0) ? "none" : undefined}}>{index !== 0 ? `[ ${text} ]` : text}</span>
                        ))}
                    </Typography>
                </Box>
                {props.user! && <Box component="div">{props.children}</Box>}
            </Toolbar>
        </AppBar>
    );    
};
export default Header;