import React from "react";

import { Box, CircularProgress, Container, Typography } from "@mui/material";

const DelayLoad = () => (
    <Container>
        <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size="1.5rem" sx={{mr: 2}}/>
            <Typography component="p" variant="h6" sx={{color: t=>t.palette.grey[600]}}>...Loading</Typography>
        </Box>
    </Container>
);
export default DelayLoad;