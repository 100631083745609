import { addDays, addHours, addMonths, format } from "date-fns";

const isNullOrUndefined = (value?: any | null) => value === undefined || value === null;
const isNullOrEmpty = (value?: string | null) => isNullOrUndefined(value) || value!.length === 0;

const getValueOrUndefined = <T = any>(value?: T | null): T | undefined => {
    if (!isNullOrUndefined(value)) {
        return value!;
    }
    return undefined;
};

const getValueOrNull = <T = any>(value?: T | null): T | null => {
    if (!isNullOrUndefined(value)) {
        return value!;
    }
    return null;
};

const selectValue = (value?: number | null, ignores?: number[]): number | undefined => {
    if (!isNullOrUndefined(value)) {
        if (!(ignores! && ignores.includes(value!))) {
            return value!;   
        }
    }
    return undefined;
};

const undefinedDate = new Date(1900, 0, 1, 0, 0, 0, 0);

const equalsUndefinedDate = (value?: Date | null): boolean => {
    if (value !== undefined && value !== null) {
        const date = new Date(value);
        return undefinedDate.getTime() === date.getTime();
    }
    return true;
};

const fromApiDate = (value?: Date | null): Date | undefined | null => {
    if (!equalsUndefinedDate(value)) {
        return new Date(value!);
    }
    return null;
    //return undefined;
};

const toApiDate = (value?: Date | null, offset?: number): Date => {
    const now = new Date();
    const timezoneOffset = offset ?? -(now.getTimezoneOffset() / 60);
    if (!equalsUndefinedDate(value)) {;
        return addHours(value!, timezoneOffset);
    }
    return addHours(undefinedDate, timezoneOffset);
};

const formatDate = (value?: Date | null, formatText?: string) => {
    if (value!) {
        return format(value, formatText ?? "yyyy-MM-dd");
    }
    return "";
}

const getAge = (birth: Date, when: Date): number => {
    const thisYaerBirth = new Date(when.getFullYear(), birth.getMonth(), birth.getDate(), 0, 0, 0);
    let age = when.getFullYear() - birth.getFullYear();
    if (when.getTime() < thisYaerBirth.getTime()) {
        age -= 1;
    }
    return age;
};

const getFirstOfMonth = (month: Date): Date => new Date(month.getFullYear(), month.getMonth(), 1);
const getEndOfMonth = (month: Date): Date => addDays(addMonths(new Date(month.getFullYear(), month.getMonth(), 1), 1), -1);

const getDate = (a?: Date | null): Date => !equalsUndefinedDate(a) ? a! : undefinedDate; 

const getMinDate = (a: Date, b: Date): Date => a.getTime() <= b.getTime() ? a : b;
const getMaxDate = (a: Date, b: Date): Date => a.getTime() >= b.getTime() ? a : b;

const containsDate = (min: Date, max: Date, value: Date): boolean => {
    const times = { min: min.getTime(), max: max.getTime(), value: value.getTime() };
    return times.min <= times.value && times.max >= times.value;
};

const sleep = (wait?: number) => new Promise<void>(resolve => window.setTimeout(() => resolve(), wait ?? 1000));

const Utility = {
    undefinedDate,
    sleep,
    isNullOrUndefined,
    isNullOrEmpty,
    getValueOrUndefined, getValueOrNull,
    selectValue,
    equalsUndefinedDate,
    formatDate,
    fromApiDate, toApiDate,
    getAge,
    getFirstOfMonth, getEndOfMonth,
    getMinDate, getMaxDate, getDate, containsDate,
} as const;
export default Utility;