import React from "react";
import { Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, Tooltip } from "@mui/material";
import { AutoAwesomeMotion, ExitToApp, ExpandLess, ExpandMore, Help, ManageAccounts, MenuBook, NoteAlt, YouTube } from "@mui/icons-material";

interface AccountMenuProps {
    onSelectOffice: () => void;
    onMemo: () => void;
    onHelp: () => void;
    onManual: () => void;
    onMovie: () => void;
    onLogout: () => void;
};

const MobileMenu = (props: AccountMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const closeAnchor = () => setAnchorEl(null);
    const handleAccount = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const [openHelp, setOpenHelp] = React.useState(false);

    const callEvent = React.useCallback((event: () => void) => {
        event();
        closeAnchor();
    }, []);

    return (
        <>
            <IconButton size="large" color="inherit" onClick={handleAccount}><ManageAccounts /></IconButton>
            <Menu
            PaperProps={{sx: {width: "16rem"}}}
            anchorEl={anchorEl} 
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)} onClose={closeAnchor}>
                <ListItemButton onClick={() => callEvent(props.onSelectOffice)}>
                    <ListItemIcon><AutoAwesomeMotion /></ListItemIcon>
                    <ListItemText primary="事業所切替" />
                </ListItemButton>
                <ListItemButton onClick={() => callEvent(props.onMemo)}>
                    <ListItemIcon><NoteAlt /></ListItemIcon>
                    <ListItemText primary="メモ" />
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => setOpenHelp(!openHelp)}>
                    <ListItemIcon><Help /></ListItemIcon>
                    <ListItemText primary="ヘルプ" />
                    {openHelp ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openHelp}>
                    <List disablePadding>
                        <ListItemButton onClick={() => callEvent(props.onHelp)} sx={{pl: "4rem"}}>
                            <ListItemIcon><Help /></ListItemIcon>
                            <ListItemText primary="ヘルプ" />
                        </ListItemButton>
                        <ListItemButton onClick={() => callEvent(props.onManual)} sx={{pl: "4rem"}}>
                            <ListItemIcon><MenuBook /></ListItemIcon>
                            <ListItemText primary="マニュアル" />
                        </ListItemButton>
                        <ListItemButton onClick={() => callEvent(props.onMovie)} sx={{pl: "4rem"}}>
                            <ListItemIcon><YouTube /></ListItemIcon>
                            <ListItemText primary="動画コンテンツ" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <Divider />
                <ListItemButton onClick={() => callEvent(props.onLogout)}>
                    <ListItemIcon><ExitToApp /></ListItemIcon>
                    <ListItemText primary="ログアウト" />
                </ListItemButton>
            </Menu>
        </>
    )
};

const OriginMenu = (props: AccountMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const closeAnchor = () => setAnchorEl(null);
    const handleHelpContainer = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const callEvent = React.useCallback((event: () => void) => {
        event();
        closeAnchor();
    }, []);

    return (
        <Box display="inline-flex" flexDirection="row">
            <Tooltip title="事業所切替" arrow placement="bottom-end">
                <IconButton color="inherit" onClick={() => callEvent(props.onSelectOffice)}>
                    <AutoAwesomeMotion />
                </IconButton>
            </Tooltip>
            <Tooltip title="メモ" arrow placement="bottom-end">
                <IconButton color="inherit" onClick={() => callEvent(props.onMemo)}>
                    <NoteAlt />
                </IconButton>
            </Tooltip>
            <Tooltip title="ヘルプ" arrow placement="bottom-end">
                <IconButton color="inherit" onClick={handleHelpContainer}>
                    <Help />
                </IconButton>
            </Tooltip>
            <Menu
            slotProps={{paper: {sx: {width: "16rem"}}}}
            anchorEl={anchorEl} 
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)} onClose={closeAnchor}>
                <ListItemButton onClick={() => callEvent(props.onHelp)} sx={{pl: "1rem"}}>
                    <ListItemIcon><Help /></ListItemIcon>
                    <ListItemText primary="ヘルプ" />
                </ListItemButton>
                <ListItemButton onClick={() => callEvent(props.onManual)} sx={{pl: "1rem"}}>
                    <ListItemIcon><MenuBook /></ListItemIcon>
                    <ListItemText primary="マニュアル" />
                </ListItemButton>
                <ListItemButton onClick={() => callEvent(props.onMovie)} sx={{pl: "1rem"}}>
                    <ListItemIcon><YouTube /></ListItemIcon>
                    <ListItemText primary="動画コンテンツ" />
                </ListItemButton>
            </Menu>
            <Tooltip title="ログアウト" arrow placement="bottom-end">
                <IconButton color="inherit" onClick={() => callEvent(props.onLogout)}>
                    <ExitToApp />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const AccountMenu = (props: AccountMenuProps & {isMobile: boolean}) => props.isMobile ? <MobileMenu {...props} /> : <OriginMenu {...props} />;
export default AccountMenu;