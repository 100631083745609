

export type CalendarReference = { jpcalendar: string, annodomini: string };

export interface ReferenceMaster {
    quick_reference: CalendarReference[];
};

export type ReferenceAd = { year: string, description: string };

export default interface JpCalender {
    references: ReferenceAd[];
};

export const mappingReference = (data: ReferenceMaster | null): JpCalender => ({
    references: data?.quick_reference.map(item => ({
        year: item.annodomini, 
        description: item.jpcalendar
    })) ?? []
});