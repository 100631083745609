import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import { useUser } from "../../providers/UserProvider";
import { MessageContext } from "../../providers/MessageProvider";
import Common, { OfficeInfo } from "../../../services/Common";
import * as Content from "./../../parts/SelectOffice"

const SelectOfficeContent = Content.default;

type SelectOfficeProps = {
    open: boolean;
    onClose: (selected: boolean, item?: OfficeInfo) => void;
};

const SelectOffice = (props: SelectOfficeProps) => {
    const { open, onClose } = props;

    const {getAccessToken} = useUser();
    const {showMessage} = React.useContext(MessageContext);

    const callOnClose = React.useCallback((item?: OfficeInfo) => {
        onClose! && onClose(item !== undefined, item);
    }, [onClose]);

    const [offices, setOffices] = React.useState<OfficeInfo[]>([]);    
    React.useEffect(() => {
        !open && setOffices([]);
        if (open) {
            const controller = new AbortController();
            getAccessToken(controller.signal)
                .then(accessToken => Common.getUserOffices(accessToken!, controller.signal))
                .then(data => {
                    if (data.length <= 1) {
                        callOnClose();
                        showMessage({
                            type: "warning", 
                            title: "事業所選択", 
                            description: ["複数の事業所を登録されている場合のみ", "ご利用可能な機能です。"]
                        });
                    } else {
                        setOffices(data);
                    }
                })
                .catch(error => console.error(error));
            return () => controller.abort();
        } else {
            setOffices([]);
        }
    }, [open, getAccessToken, callOnClose, showMessage]);

    const handleClose = React.useCallback(() => callOnClose(), [callOnClose]);
    const handleSelect = React.useCallback((item: OfficeInfo) => callOnClose(item), [callOnClose]);

    return (
        <Dialog open={open && offices.length > 0}>
            <DialogContent>
                <SelectOfficeContent offices={offices} onSelect={handleSelect}/>
            </DialogContent>
            <DialogActions>
                <Button type="button" sx={{width: "7rem"}} onClick={handleClose}>キャンセル</Button>
            </DialogActions>
        </Dialog>
    );
};
export default SelectOffice;