import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, ListItemText, Menu, MenuItem, Radio, RadioGroup, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { UpdateEventHandler } from "../../types/Callback";
import Corporate, { InputCorporateForm, InputCorporateSchema } from "../../types/Corporate";
import { ResourceContext } from "../providers/ResourceProvider";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Zip, { ZipAddress } from "../../services/Zip";
import Define from "../../configurations/Define";
import Prefectures from "../../configurations/PrefecturalCode";
import { SearchOutlined } from "@mui/icons-material";
import EnhancedForm from "./EnhancedForm";
import TextBox from "./controlled/TextBox";

export interface CorporateEditProps {
    updateName: string;
    data: Corporate;
    headerVariant?: "h6" | "subtitle1" | "subtitle2";
    divider?: boolean;
    onUpdate: UpdateEventHandler<Corporate>;
    changed?: boolean;
    onBeginEdit?: () => void;
}

const CorporateEdit = (props: CorporateEditProps) => {
    const theme = useTheme();
    const { settings } = React.useContext(ResourceContext);
    const { register, handleSubmit, control, formState: { errors }, watch, setValue, setFocus, trigger } = useForm<InputCorporateForm>({
        mode: "onBlur",
        resolver: yupResolver(InputCorporateSchema),
        defaultValues: {...props.data},
    });
    const onSubmit = (data: InputCorporateForm) => {
        props.onUpdate! && props.onUpdate(data);
    };
    const [zipLoading, setZipLoading] = React.useState(false);
    const zipRef = React.useRef<HTMLInputElement | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
    const [addresses, setAddresses] = React.useState<ZipAddress[] | null>(null);
    const handleSearchAddress = () => {
        const { zip } = watch();
        if (zip?.length === 7) {
            setZipLoading(true);
            Zip.searchAddress(zip).then(data => {
                if (data.length > 0) {
                    if (data.length === 1) {
                        selectAddress(data[0]);
                    } else {
                        setAddresses(data);
                        setAnchorEl(zipRef.current);
                    }
                }
            })
            .catch(error => console.log(error))
            .finally(() => setZipLoading(false));
        }
    };
    const selectAddress = (address: ZipAddress) => {
        setValue("addressPref", address.prefcode);
        trigger("addressPref");
        setValue("addressMunicipalities", `${address.address}`);
        trigger("addressMunicipalities");
        setFocus("addressDetails");
        setAddresses(null);
        closeAnchor();
    };
    const closeAnchor = () => setAnchorEl(null);

    React.useEffect(() => {
        const subscription = watch(() => {
            props.onBeginEdit! && props.onBeginEdit();
        });
        return () => subscription.unsubscribe();
    }, [watch, props]);

    return (
        <EnhancedForm onSubmit={handleSubmit(onSubmit)} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: (props.divider ?? false) ? 0 : 2 }}>
            <input type="hidden" className="editing" data-value={props.changed ?? false} />
            {(props.divider ?? false) === false && <Typography variant={props.headerVariant ?? "h5"} sx={{ mb: 1 }}>法人情報</Typography>}
            <Grid container spacing={2}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} md={8}>
                        <TextBox label="メールアドレス" control={control} name="mail" 
                        required fullWidth readOnly={!(props.data.constractUser ?? false)} />
                    </Grid>
                    <Grid item md={4}>
                        <FormControlLabel 
                        label="お知らせメール受信"
                        control={<Checkbox {...register("notify")} 
                        defaultChecked={watch("notify")??true}/>}  />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} container direction="column" spacing={2}>
                    <Grid item>
                        <TextField label="担当者" className="Mui-required" 
                        autoFocus fullWidth
                        sx={{imeMode: "active"}}
                        placeholder={`${Define.placeholders["lastName"]} ${Define.placeholders["firstName"]}`}
                        {...register("picName")}
                        error={"picName" in errors}
                        helperText={errors.picName?.message} />
                    </Grid>
                    <Grid item>
                        <TextField label="フリガナ(全角入力)" className="Mui-required"
                        fullWidth 
                        sx={{imeMode: "active"}}
                        placeholder={`${Define.placeholders["lastKana"]} ${Define.placeholders["firstKana"]}`}
                        {...register("picKana")}
                        error={"picKana" in errors}
                        helperText={errors.picKana?.message} />
                    </Grid>
                </Grid>
                
                <Grid item xs={12} md={4} container direction="column" spacing={2}>
                    <Grid item>
                        <TextField label="役職" select className="Mui-required"
                        {...register("picPost")}
                        fullWidth
                        value={watch("picPost") ?? ""}
                        error={"picPost" in errors}
                        helperText={errors.picPost?.message as string} >
                            {settings?.a.posts?.map(value => <MenuItem key={value.selectionCode} value={value.selectionCode}>{value.description}</MenuItem>)}
                        </TextField>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField label="法人形態" select className="Mui-required"
                        {...register("type")}
                        fullWidth
                        value={watch("type") ?? ""}
                        error={"type" in errors}
                        helperText={errors.type?.message as string} >
                            {settings?.a.corpTypes?.map(value => <MenuItem key={value.selectionCode} value={value.selectionCode}>{value.description}</MenuItem>)}
                        </TextField>
                        <Typography variant="body1" sx={{pl: 2, emptyCells: "show", height: "1.5rem"}}>{settings?.a.corpTypes.find(value => {const type=watch("type"); return value.selectionCode === type;})?.externalCode}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl key="typeOffset" fullWidth size="small" error={Boolean(errors.typeOffset)}>
                            <Controller name="typeOffset" control={control} render={({ field }) => (
                                <RadioGroup {...field} value={watch("typeOffset") ?? ""} row>
                                    {settings?.a.corpTypeOffsets?.map(value => (
                                        <FormControlLabel key={value.selectionCode} value={value.selectionCode} label={value.description} control={<Radio />} />
                                    ))}
                                </RadioGroup>
                            )} />
                            {"typeOffset" in errors && <FormHelperText>{errors.typeOffset?.message as string}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField label="法人名" className="Mui-required"
                        {...register("name")}
                        fullWidth
                        sx={{imeMode: "active"}}
                        placeholder={Define.placeholders["corporateName"]}
                        error={"name" in errors}
                        helperText={errors.name?.message as string} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="法人名(フリガナ:全角入力)" className="Mui-required"
                        {...register("kana")}
                        fullWidth
                        sx={{imeMode: "active"}}
                        placeholder={Define.placeholders["corporateKana"]}
                        error={"kana" in errors}
                        helperText={errors.kana?.message as string} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="法人電話番号" className="Mui-required"
                        {...register("tel")}
                        fullWidth
                        sx={{imeMode: "disabled"}}
                        placeholder={Define.placeholders["tel"] ?? ""}
                        error={"tel" in errors}
                        helperText={errors.tel?.message as string} />
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h6">法人住所</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="郵便番号"
                    {...register("zip")}
                    fullWidth
                    sx={{imeMode: "disabled"}}
                    placeholder={Define.placeholders["zip"] ?? ""}
                    error={"zip" in errors}
                    helperText={errors.zip?.message as string} 
                    inputRef={zipRef}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment title="郵便番号検索" position="end" sx={{cursor: "pointer"}} onClick={handleSearchAddress}>
                                {zipLoading ? <CircularProgress size="1em" /> : <SearchOutlined />}
                            </InputAdornment>
                        )
                    }} />
                    <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeAnchor}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }} 
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    {addresses && addresses.map((value, index) => (
                        <MenuItem key={index} onClick={() => selectAddress(value)}>
                            <ListItemText>{`${value.address}`}</ListItemText>
                        </MenuItem>
                    ))}                
                    </Menu>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="都道府県" select className="Mui-required"
                    {...register("addressPref")}
                    fullWidth
                    value={watch("addressPref") ?? ""}
                    error={"addressPref" in errors}
                    helperText={errors.addressPref?.message as string} >
                        {Prefectures.map(value => <MenuItem key={value.code} value={value.code}>{value.name}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="住所1" className="Mui-required"
                    {...register("addressMunicipalities")}
                    fullWidth
                    sx={{imeMode: "active"}}
                    placeholder={Define.placeholders["addressMunicipalities"]}
                    error={"addressMunicipalities" in errors}
                    helperText={errors.addressMunicipalities?.message as string} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="住所2" 
                    {...register("addressDetails")}
                    fullWidth
                    sx={{imeMode: "active"}}
                    placeholder={Define.placeholders["addressDetails"]}
                    error={"addressDetails" in errors}
                    helperText={errors.addressDetails?.message as string} />
                </Grid>
            </Grid>
            <Box sx={{ 
                position: "sticky", bottom: 0, 
                backgroundColor: theme.palette.background.default, 
                zIndex: 100, pb: 1
            }}>
                {(props.divider ?? false) && <Divider sx={{mt: 1, mb: 1}} />}
                <Grid container>
                    <Grid item container xs={12} alignItems="center" justifyContent="right">
                        <Grid item>
                        <Button type="submit" variant="contained" color="primary">{props.updateName}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </EnhancedForm>
    );
};
export default CorporateEdit;