import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Card, Box, Step, StepLabel, Stepper, Container, StepContent, Theme, useMediaQuery, Typography, Button, CardContent, Alert, AlertTitle } from "@mui/material";

import SignupInput, { DefaultSignup, SignupStepProps } from "./types";
import InputMail from "./InputMail";
import InputCode from "./InputCode";
import InputCorporate from "./InputCorporate";
import InputOffice from "./InputOffice";
import InputOffer from "./InputOffer";
import InputConfirm from "./InputConfirm";

/** 新規登録ページ */
const SignUp = () => {
    const mobile = useMediaQuery((theme: Theme)=>theme.breakpoints.down("sm"));
    const [input, setInput] = React.useState<SignupInput & { activeStep: number }>({...DefaultSignup, activeStep: 0});
    
    const handleNext = (data?: SignupInput) => {
        let newInput = input;
        if (data !== undefined) {
            newInput = { ...input, ...data };
        }
        setInput({ ...newInput, activeStep: input.activeStep + 1 });
    };
    const handleBack = (count?: number) => setInput({ ...input, activeStep: input.activeStep - (count ?? 1) });
    
    const steps = [
        "メールアドレス入力",
        "メールアドレス確認",
        "法人情報入力",
        "事業所情報入力",
        "申込み情報入力", 
        "入力内容の確認",
    ];
    const getStepContent = (step: number) => {
        const stepProps: SignupStepProps = { data: input, onNext: handleNext, onBack: handleBack };
        switch (step) {
            case 0:
                return <InputMail {...stepProps} />;
            case 1:
                return <InputCode {...stepProps} />;
            case 2:
                return <InputCorporate {...stepProps} />;
            case 3:
                return <InputOffice {...stepProps} />;
            case 4:
                return <InputOffer {...stepProps} />;
            case 5:
                return <InputConfirm {...stepProps} />;
        }
        return (
            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%", p: 2 }}>
                <Card sx={{width: 820, margin: "auto"}}>
                    <CardContent>
                        <Box m={2}>
                            <Typography component="p" variant="subtitle1" align="center" m={1}>ありがとうございます。</Typography>
                            <Typography component="p" variant="subtitle1" align="center" m={1}>無料体験申込みが完了しました。</Typography>
                        </Box>
                        <Box m={2}>
                            <Alert severity="warning">
                                <AlertTitle>ご案内メールについて</AlertTitle>
                                <Typography component="p" variant="subtitle1" align="center" m={1}>入力いただいたメールアドレス宛に下記件名のご案内メールが届きます。</Typography>
                                <Typography component="p" variant="subtitle1" align="center" m={1} sx={{fontWeight: "bold"}}>件名：【リハクム】ご利用手続き完了のお知らせ</Typography>
                                <Typography component="p" variant="subtitle1" align="center" m={1} sx={{color: "error"}}>注）メールが届かない場合、迷惑メールフォルダに入っていないかご確認ください。</Typography>
                            </Alert>
                        </Box>
                        <Box display="flex" justifyContent="center" m={2}>
                            <Button variant="contained" color="primary" sx={{width: "20rem"}} component={RouterLink} to="/signin">サービスを開始する</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    return (
        <Container sx={{ pt: 5, pl: "auto", pr: "auto" }}>
            <Typography variant="h4" align="center" sx={{mb: 2}}>リハクム 無料体験申込み</Typography>
            {input.activeStep < 6 && <Stepper activeStep={input.activeStep} orientation={(mobile ? "vertical" : "horizontal")}>
                {steps.map((text, index) => {
                    const stepProps: { completed?: boolean, last?: boolean } = {};
                    stepProps.completed = index < input.activeStep;
                    stepProps.last = index === steps.length - 1;
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel>{text}</StepLabel>
                            {mobile ? <StepContent sx={{mt: 2}}>{getStepContent(input.activeStep)}</StepContent> : <></>}
                        </Step>
                    );
                })}
            </Stepper>}
            {input.activeStep === 6 || !mobile ? <Box sx={{ mt: 2, height: 600 }}>{getStepContent(input.activeStep)}</Box> : <></>}
        </Container>
    );
};
export default SignUp;