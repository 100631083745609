export interface SelectionItem {
    classCode: string;
    selectionCode: number;
    selectionSubCode: number
    externalCode: string;
    priority: number;
    description: string;
}

export interface Master {
    /** A01 */
    person_position: SelectionItem[];
    /** A02 */
    corporation_form: SelectionItem[];
    /** A03 */
    front_or_rear: SelectionItem[];
    /** A04 */
    mode_of_operation: SelectionItem[];
    /** A05 */
    target_period1: SelectionItem[];
    /** A06 */
    target_period2: SelectionItem[];
    /** A07 */
    improveplan_evaluate: SelectionItem[];
    /** A08 */
    physical_func_check: SelectionItem[];
    /** A09 */
    service_type_code1: SelectionItem[];
    /** A10 */
    service_type_code2: SelectionItem[];
    /** A11 */
    staff_Job_type: SelectionItem[];
    /** A12 */
    staff_work_style: SelectionItem[];
    /** A13 */
    staff_qualification: SelectionItem[];
    /** A14 */
    support_category: SelectionItem[];

    /** B01 */
    user_sex: SelectionItem[];
    /** B02 */
    degree_of_nursing_care: SelectionItem[];
    /** B03 */
    certification_division: SelectionItem[];
    /** B04 */
    benefit_rate: SelectionItem[];
    /** B05 */
    handicapped_elderly: SelectionItem[];
    /** B06 */
    elderly_with_dementia: SelectionItem[];
    /** B07 */
    regular_place_day: SelectionItem[];
    /** B08 */
    pick_up: SelectionItem[];
    /** B09 */
    addition: SelectionItem[];
    /** B10 */
    user_status: SelectionItem[];
    /** B11 */
    family_living_together: SelectionItem[];
    /** B12 */
    time_for_family_caregiving: SelectionItem[];
    /** B13 */
    dementia: SelectionItem[];
    /** B14 */
    oral_cavity: SelectionItem[];
    /** B15 */
    dbd13: SelectionItem[];
    /** B16 */
    vitality_index_1: SelectionItem[];
    /** B17 */
    vitality_index_2: SelectionItem[];
    /** B18 */
    vitality_index_3: SelectionItem[];
    /** B19 */
    vitality_index_4: SelectionItem[];
    /** B20 */
    vitality_index_5: SelectionItem[];
    /** B21 */
    update_details_user: SelectionItem[];
    
    /** B23 */
    rate_reason: SelectionItem[];
    /** B24 */
    complications: SelectionItem[];


    /** C01 */
    explanation_receiver: SelectionItem[];
    /** C02 */
    plan_evaluation: SelectionItem[];
    /** C03 */
    progress_status: SelectionItem[];
    /** C04 */
    service_situation: SelectionItem[];
    /** C05 */
    is_plan_review: SelectionItem[];
    /** C06 */
    plan_term: SelectionItem[];
    /** C07 */
    program_frequency: SelectionItem[];
    /** C08 */
    program_time: SelectionItem[];
    /** C09 */
    program_exercise_intensity: SelectionItem[];
    /** C10 */
    update_details: SelectionItem[];
    /** C11 */
    main_implementer: SelectionItem[];
    /** C12 */
    planner_occupation: SelectionItem[];
    /** C13 */
    service_provider: SelectionItem[];
    /** C14 */
    embodiment: SelectionItem[];
    
    //assessment_practitioner: SelectionItem[];
    /** C15 */
    //planner: SelectionItem[];

    /** D01 */
    knee_high_bit: SelectionItem[];
    body_weight_capture: SelectionItem[];
    grip_capture: SelectionItem[];
    stand_and_sit_test_capture: SelectionItem[];
    single_leg_stand_capture: SelectionItem[];
    timed_up_go_capture: SelectionItem[];
    func_reac_capture: SelectionItem[];
    five_meter_walk_fastest_capture: SelectionItem[];

    /** E01 */
    check_content: SelectionItem[];
    check_content1: SelectionItem[];
    check_content2: SelectionItem[];
    check_content3: SelectionItem[];
    check_content4: SelectionItem[];
    check_content5: SelectionItem[];
    check_content6: SelectionItem[];
    /** F01 */
    life_func_environment1: SelectionItem[];
    life_func_environment2: SelectionItem[];
    life_func_environment3: SelectionItem[];
    life_func_environment4: SelectionItem[];
    life_func_environment5: SelectionItem[];
    life_func_environment6: SelectionItem[];
    life_func_environment7: SelectionItem[];
    life_func_environment8: SelectionItem[];
    life_func_environment9: SelectionItem[];
    life_func_environment10: SelectionItem[];
    life_func_environment11: SelectionItem[];
    life_func_environment12: SelectionItem[];
    life_func_environment13: SelectionItem[];
    life_func_environment14: SelectionItem[];
    life_func_environment15: SelectionItem[];
    life_func_environment16: SelectionItem[];
    life_func_environment17: SelectionItem[];
    life_func_environment18: SelectionItem[];
    life_func_environment19: SelectionItem[];
    life_func_life_task1: SelectionItem[];
    life_func_life_task2: SelectionItem[];
    life_func_life_task3: SelectionItem[];
    life_func_life_task4: SelectionItem[];
    life_func_life_task5: SelectionItem[];
    life_func_life_task6: SelectionItem[];
    life_func_life_task7: SelectionItem[];
    life_func_life_task8: SelectionItem[];
    life_func_life_task9: SelectionItem[];
    life_func_life_task10: SelectionItem[];
    life_func_life_task11: SelectionItem[];
    life_func_life_task12: SelectionItem[];
    life_func_life_task13: SelectionItem[];
    life_func_life_task14: SelectionItem[];
    life_func_life_task15: SelectionItem[];
    life_func_life_task16: SelectionItem[];
    life_func_life_task17: SelectionItem[];
    life_func_life_task18: SelectionItem[];
    life_func_life_task19: SelectionItem[];
    /** G01 */
    life_func_level1: SelectionItem[];
    life_func_level2: SelectionItem[];
    life_func_level3: SelectionItem[];
    life_func_level4: SelectionItem[];
    life_func_level_iadl: SelectionItem[];
    /** H01 */
    answer_result_pattern1: SelectionItem[];
    answer_result_pattern2: SelectionItem[];
    answer_result_pattern3: SelectionItem[];
    answer_result_pattern4: SelectionItem[];

    /** I01 */
    basic_enabled_settings: SelectionItem[];
    training_unit: SelectionItem[];
    user_pick_up: SelectionItem[];
    training_category: SelectionItem[];
    //
    water_intake: SelectionItem[];


    /** J01 */
    icf_code_function: SelectionItem[];
    icf_code_life_activity: SelectionItem[];
    support_code_cognitive: SelectionItem[];
    support_code_body: SelectionItem[];
    support_code_communication: SelectionItem[];
    support_code_adl: SelectionItem[];
    support_code_iadl: SelectionItem[];
    support_code_others: SelectionItem[];
    
    /** K01 */
    login_authority: SelectionItem[];
    initial_screen: SelectionItem[];
    system_notice_type: SelectionItem[];
    system_notice_type2: SelectionItem[];
    usage_plan: SelectionItem[];
    payment_method: SelectionItem[];
    usage_situation: SelectionItem[];

    /** L01 */
    attendance_category: SelectionItem[];
    work_style: SelectionItem[];

    /** M01 */
    cancell_reason: SelectionItem[];
    return_home: SelectionItem[];
    life_cognitive_function_scale1: SelectionItem[];
    life_cognitive_function_scale2: SelectionItem[];
    life_cognitive_function_scale3: SelectionItem[];
    life_cognitive_function_scale4: SelectionItem[];
    life_cognitive_function_scale5: SelectionItem[];
    life_cognitive_function_scale6: SelectionItem[];
    life_cognitive_function_scale7: SelectionItem[];


    /** N01 */
    assessment_question: SelectionItem[];
    assessment_observation: SelectionItem[];
    assessment_answer1: SelectionItem[];
    assessment_answer2: SelectionItem[];
    assessment_answer3: SelectionItem[];
    assessment_answer4: SelectionItem[];
    oral_test_not_reason: SelectionItem[];
    food_form: SelectionItem[];
    oral_intake_type: SelectionItem[];
    program_content: SelectionItem[];
    oral_cleaning: SelectionItem[];
    guidance_on_oral: SelectionItem[];
    guidance_on_voice: SelectionItem[];    
    to_carry_out: SelectionItem[];
    comprehensive_evaluation: SelectionItem[];
    oral_evaluation: SelectionItem[];
    oral_hygiene: SelectionItem[];
    oral_func_status: SelectionItem[];
    oral_remarks: SelectionItem[];
    guidance1: SelectionItem[];
    guidance2: SelectionItem[];
    guidance3: SelectionItem[];
    is_judgment_theme: SelectionItem[];
    is_judgment_improvement: SelectionItem[];
    aim_setting: SelectionItem[];
    life_submission_items: SelectionItem[];
    aim_setting_nutrition: SelectionItem[];
    guidance_on_prevention: SelectionItem[];
    other_teaching: SelectionItem[];
    oral_evaluation2: SelectionItem[];

    /** O01 */
    health_selection1: SelectionItem[];
    health_selection2: SelectionItem[];
    health_selection3: SelectionItem[];
    adl_selection1: SelectionItem[];
    adl_selection2: SelectionItem[];
    living_selection1: SelectionItem[];
    living_selection2: SelectionItem[];
    oral_selection1: SelectionItem[];
    oral_selection2: SelectionItem[];
    oral_selection3: SelectionItem[];
    oral_selection4: SelectionItem[];
    oral_selection5: SelectionItem[];
    oral_selection6: SelectionItem[];
    oral_selection7: SelectionItem[];
    oral_selection8: SelectionItem[];
    cognition_selection1: SelectionItem[];
}

const getSortedItems = (items: SelectionItem[]): SelectionItem[] => {
    const result = [...items];
    result.sort((a, b) => a.priority - b.priority);
    return result;
};

export const mappingSetting = (data: Master | null): Settings | null => {
    if (data === null) {
        return null;
    }
    return {
        a: {
            posts: getSortedItems(data.person_position),
            corpTypes: getSortedItems(data.corporation_form),
            corpTypeOffsets: getSortedItems(data.front_or_rear),
            bizHoursTypes: getSortedItems(data.mode_of_operation),
            longTermPeriod: getSortedItems(data.target_period1),
            shortTermPeriod: getSortedItems(data.target_period2),
            assessTarget: getSortedItems(data.improveplan_evaluate),
            physicalTest: getSortedItems(data.physical_func_check),
            serviceCode1: getSortedItems(data.service_type_code1),
            serviceCode2: getSortedItems(data.service_type_code2),
            staffOccupation: getSortedItems(data.staff_Job_type),
            workStyle: getSortedItems(data.staff_work_style),
            qualifications: getSortedItems(data.staff_qualification),
            supportCategories: getSortedItems(data.support_category),
        },

        b: {
            sexualities: getSortedItems(data.user_sex),
            degreeOfCare: getSortedItems(data.degree_of_nursing_care),
            certStatus: getSortedItems(data.certification_division),
            benefitRate: getSortedItems(data.benefit_rate),
            handicapLifeIndependenceLv: getSortedItems(data.handicapped_elderly),
            dementiaLifeIndependenceLv: getSortedItems(data.elderly_with_dementia),
            bizHours: getSortedItems(data.regular_place_day),
            shuttleServices: getSortedItems(data.pick_up),
            paidOptions: getSortedItems(data.addition),
            usages: getSortedItems(data.user_status),
            livingFamily: getSortedItems(data.family_living_together),
            oralCavity: getSortedItems(data.oral_cavity),
            dementia: getSortedItems(data.dementia),
            dbd13: getSortedItems(data.dbd13),
            vitalityIndex1: getSortedItems(data.vitality_index_1),
            vitalityIndex2: getSortedItems(data.vitality_index_2),
            vitalityIndex3: getSortedItems(data.vitality_index_3),
            vitalityIndex4: getSortedItems(data.vitality_index_4),
            vitalityIndex5: getSortedItems(data.vitality_index_5),
            familyCareGiveTimes: getSortedItems(data.time_for_family_caregiving),
            // B21: getSortedItems(data.update_details_user),
            // B22: getSortedItems(data.stop_reason),
            rateReasons: getSortedItems(data.rate_reason),
            complications: getSortedItems(data.complications),
        },

        c: {
            consenters: getSortedItems(data.explanation_receiver),
            achievement: getSortedItems(data.plan_evaluation),
            progressStatus: getSortedItems(data.progress_status),
            serviceProgressStatus: getSortedItems(data.service_situation),
            planReview: getSortedItems(data.is_plan_review),
            planPeriod: getSortedItems(data.plan_term),
            programFrequency: getSortedItems(data.program_frequency),
            programEmbodiment: getSortedItems(data.embodiment),
            programTime: getSortedItems(data.program_time),
            programIntensity: getSortedItems(data.program_exercise_intensity),
            updatePart: getSortedItems(data.update_details),
            mainImplementor: getSortedItems(data.main_implementer),
            plannerOccupation: getSortedItems(data.planner_occupation),
            serviceProviders: getSortedItems(data.service_provider),
            //assessmentOccupation: getSortedItems(data.assessment_practitioner),
            //oralCarePlanner: getSortedItems(data.planner),
        },
        
        d: {
            kneeHeights: getSortedItems(data.knee_high_bit),
            weights: getSortedItems(data.body_weight_capture),
            grips: getSortedItems(data.grip_capture),
            standAndSits: getSortedItems(data.stand_and_sit_test_capture),
            legStands: getSortedItems(data.single_leg_stand_capture),
            tugs: getSortedItems(data.timed_up_go_capture),
            frts: getSortedItems(data.func_reac_capture),
            walks: getSortedItems(data.five_meter_walk_fastest_capture),
        },

        e: {
            interests: getSortedItems(data.check_content),
            interest1: getSortedItems(data.check_content1),
            interest2: getSortedItems(data.check_content2),
            interest3: getSortedItems(data.check_content3),
            interest4: getSortedItems(data.check_content4),
            interest5: getSortedItems(data.check_content5),
            interest6: getSortedItems(data.check_content6),
        },

        f: {
            environment: {
                adl1: getSortedItems(data.life_func_environment1),
                adl2: getSortedItems(data.life_func_environment2),
                adl3: getSortedItems(data.life_func_environment3),
                adl4: getSortedItems(data.life_func_environment4),
                adl5: getSortedItems(data.life_func_environment5),
                adl6: getSortedItems(data.life_func_environment6),
                adl7: getSortedItems(data.life_func_environment7),
                adl8: getSortedItems(data.life_func_environment8),
                adl9: getSortedItems(data.life_func_environment9),
                adl10: getSortedItems(data.life_func_environment10),
                adl11: getSortedItems(data.life_func_environment11),

                iadl1: getSortedItems(data.life_func_environment12),
                iadl2: getSortedItems(data.life_func_environment13),
                iadl3: getSortedItems(data.life_func_environment14),
                
                living1: getSortedItems(data.life_func_environment15),
                living2: getSortedItems(data.life_func_environment16),
                living3: getSortedItems(data.life_func_environment17),
                living4: getSortedItems(data.life_func_environment18),
                living5: getSortedItems(data.life_func_environment19),
            },
            status: {
                adl1: getSortedItems(data.life_func_life_task1),
                adl2: getSortedItems(data.life_func_life_task2),
                adl3: getSortedItems(data.life_func_life_task3),
                adl4: getSortedItems(data.life_func_life_task4),
                adl5: getSortedItems(data.life_func_life_task5),
                adl6: getSortedItems(data.life_func_life_task6),
                adl7: getSortedItems(data.life_func_life_task7),
                adl8: getSortedItems(data.life_func_life_task8),
                adl9: getSortedItems(data.life_func_life_task9),
                adl10: getSortedItems(data.life_func_life_task10),
                adl11: getSortedItems(data.life_func_life_task11),

                iadl1: getSortedItems(data.life_func_life_task12),
                iadl2: getSortedItems(data.life_func_life_task13),
                iadl3: getSortedItems(data.life_func_life_task14),
                
                living1: getSortedItems(data.life_func_life_task15),
                living2: getSortedItems(data.life_func_life_task16),
                living3: getSortedItems(data.life_func_life_task17),
                living4: getSortedItems(data.life_func_life_task18),
                living5: getSortedItems(data.life_func_life_task19),
            }
        },

        g: {
            lv1: getSortedItems(data.life_func_level1),
            lv2: getSortedItems(data.life_func_level2),
            lv3: getSortedItems(data.life_func_level3),
            lv4: getSortedItems(data.life_func_level4),
            lvIADL: getSortedItems(data.life_func_level_iadl),
        },

        h: {
            answer1: getSortedItems(data.answer_result_pattern1),
            answer2: getSortedItems(data.answer_result_pattern2),
            answer3: getSortedItems(data.answer_result_pattern3),
            answer4: getSortedItems(data.answer_result_pattern4),
        },

        i: {
            usageCodes: getSortedItems(data.basic_enabled_settings),
            usageUnits: getSortedItems(data.training_unit),
            userPickup: getSortedItems(data.user_pick_up),
            categories: getSortedItems(data.training_category),
            //
            WaterIntake: getSortedItems(data.water_intake),
        },

        j: {
            icfFunctionaries: getSortedItems(data.icf_code_function),
            icfActivities: getSortedItems(data.icf_code_life_activity),
            supportCognitives: getSortedItems(data.support_code_cognitive),
            supportPhysicals: getSortedItems(data.support_code_body),
            supportCommunications: getSortedItems(data.support_code_communication),
            supportADL: getSortedItems(data.support_code_adl),
            supportIADL: getSortedItems(data.support_code_iadl),
            supportOthers: getSortedItems(data.support_code_others),
        },
        k: {
            usages: getSortedItems(data.usage_situation),
        },
        l: {},
        m: {
            cancellationReasons: getSortedItems(data.cancell_reason),
            returnHomeCare: getSortedItems(data.return_home),
            cognitiveScale1: getSortedItems(data.life_cognitive_function_scale1),
            cognitiveScale2: getSortedItems(data.life_cognitive_function_scale2),
            cognitiveScale3: getSortedItems(data.life_cognitive_function_scale3),
            cognitiveScale4: getSortedItems(data.life_cognitive_function_scale4),
            cognitiveScale5: getSortedItems(data.life_cognitive_function_scale5),
            cognitiveScale6: getSortedItems(data.life_cognitive_function_scale6),
            cognitiveScale7: getSortedItems(data.life_cognitive_function_scale7),
        },
        n: {
            assessment: {
                questions: getSortedItems(data.assessment_question),
                observations: getSortedItems(data.assessment_observation),
                answer1: getSortedItems(data.assessment_answer1),
                answer2: getSortedItems(data.assessment_answer2),
                answer3: getSortedItems(data.assessment_answer3),
                answer4: getSortedItems(data.assessment_answer4),
            },
            unimplementReason: getSortedItems(data.oral_test_not_reason),
            foodForm: getSortedItems(data.food_form),
            oralIntakes: getSortedItems(data.oral_intake_type),
            programContent: getSortedItems(data.program_content),
            oralCleaning: getSortedItems(data.oral_cleaning),
            eatTraining: getSortedItems(data.guidance_on_oral),
            voiceTraining: getSortedItems(data.guidance_on_voice),
            swallowTraining: getSortedItems(data.guidance_on_prevention),
            othersTraining: getSortedItems(data.other_teaching),
            selfCare: getSortedItems(data.to_carry_out),
            comprehensiveEvaluation: getSortedItems(data.comprehensive_evaluation),
            guidance1: getSortedItems(data.guidance1),
            guidance2: getSortedItems(data.guidance2),
            guidance3: getSortedItems(data.guidance3),
            evaluationValues: getSortedItems(data.oral_evaluation),
            oralHygienes: getSortedItems(data.oral_hygiene),
            oralFunctions: getSortedItems(data.oral_func_status),
            oralRemarks: getSortedItems(data.oral_remarks),
            issues: getSortedItems(data.is_judgment_theme),
            improvements: getSortedItems(data.is_judgment_improvement),
            targets: getSortedItems(data.aim_setting),
            lifeItems: getSortedItems(data.life_submission_items),
            targets2: getSortedItems(data.aim_setting_nutrition),
            evaluationValues2: getSortedItems(data.oral_evaluation2),
        },

        o: {
            health1: getSortedItems(data.health_selection1),
            health2: getSortedItems(data.health_selection2),
            health3: getSortedItems(data.health_selection3),
            adl1: getSortedItems(data.adl_selection1),
            adl2: getSortedItems(data.adl_selection2),
            living1: getSortedItems(data.living_selection1),
            living2: getSortedItems(data.living_selection2),
            oral1: getSortedItems(data.oral_selection1),
            oral2: getSortedItems(data.oral_selection2),
            oral3: getSortedItems(data.oral_selection3),
            oral4: getSortedItems(data.oral_selection4),
            oral5: getSortedItems(data.oral_selection5),
            oral6: getSortedItems(data.oral_selection6),
            oral7: getSortedItems(data.oral_selection7),
            oral8: getSortedItems(data.oral_selection8),
            cognition1: getSortedItems(data.cognition_selection1),
        },
    };
};

export default interface Settings {
    a: {
        posts: SelectionItem[];
        corpTypes: SelectionItem[];
        corpTypeOffsets: SelectionItem[];
        bizHoursTypes: SelectionItem[];
        longTermPeriod: SelectionItem[];
        shortTermPeriod: SelectionItem[];
        assessTarget: SelectionItem[];
        physicalTest: SelectionItem[];
        serviceCode1: SelectionItem[];
        serviceCode2: SelectionItem[];
        staffOccupation: SelectionItem[];
        workStyle: SelectionItem[];
        qualifications: SelectionItem[];
        supportCategories: SelectionItem[];
    };

    b: {
        sexualities: SelectionItem[];
        degreeOfCare: SelectionItem[];
        certStatus: SelectionItem[];
        benefitRate: SelectionItem[];
        handicapLifeIndependenceLv: SelectionItem[];
        dementiaLifeIndependenceLv: SelectionItem[];
        bizHours: SelectionItem[];
        shuttleServices: SelectionItem[];
        paidOptions: SelectionItem[];
        usages: SelectionItem[];
        livingFamily: SelectionItem[];
        oralCavity: SelectionItem[];
        dementia: SelectionItem[];
        dbd13: SelectionItem[];
        vitalityIndex1: SelectionItem[];
        vitalityIndex2: SelectionItem[];
        vitalityIndex3: SelectionItem[];
        vitalityIndex4: SelectionItem[];
        vitalityIndex5: SelectionItem[];
        familyCareGiveTimes: SelectionItem[];
        rateReasons: SelectionItem[];
        complications: SelectionItem[];
    };

    c: {
        consenters: SelectionItem[];
        achievement: SelectionItem[];
        progressStatus: SelectionItem[];
        serviceProgressStatus: SelectionItem[];
        planReview: SelectionItem[];
        planPeriod: SelectionItem[];
        programFrequency: SelectionItem[];
        programEmbodiment: SelectionItem[];
        programTime: SelectionItem[];
        programIntensity: SelectionItem[];
        updatePart: SelectionItem[];
        mainImplementor: SelectionItem[];
        plannerOccupation: SelectionItem[];
        serviceProviders: SelectionItem[];
        //assessmentOccupation: SelectionItem[];
        //oralCarePlanner: SelectionItem[];
    };

    d: {
        kneeHeights: SelectionItem[];
        weights: SelectionItem[];
        grips: SelectionItem[];
        standAndSits: SelectionItem[];
        legStands: SelectionItem[];
        tugs: SelectionItem[];
        frts: SelectionItem[];
        walks: SelectionItem[];
    };

    e: {
        interests: SelectionItem[];
        interest1: SelectionItem[];
        interest2: SelectionItem[];
        interest3: SelectionItem[];
        interest4: SelectionItem[];
        interest5: SelectionItem[];
        interest6: SelectionItem[];
    };

    f: {
        environment: {
            adl1: SelectionItem[];
            adl2: SelectionItem[];
            adl3: SelectionItem[];
            adl4: SelectionItem[];
            adl5: SelectionItem[];
            adl6: SelectionItem[];
            adl7: SelectionItem[];
            adl8: SelectionItem[];
            adl9: SelectionItem[];
            adl10: SelectionItem[];
            adl11: SelectionItem[];

            iadl1: SelectionItem[];
            iadl2: SelectionItem[];
            iadl3: SelectionItem[];

            living1: SelectionItem[];
            living2: SelectionItem[];
            living3: SelectionItem[];
            living4: SelectionItem[];
            living5: SelectionItem[];
        };
        status: {
            adl1: SelectionItem[];
            adl2: SelectionItem[];
            adl3: SelectionItem[];
            adl4: SelectionItem[];
            adl5: SelectionItem[];
            adl6: SelectionItem[];
            adl7: SelectionItem[];
            adl8: SelectionItem[];
            adl9: SelectionItem[];
            adl10: SelectionItem[];
            adl11: SelectionItem[];

            iadl1: SelectionItem[];
            iadl2: SelectionItem[];
            iadl3: SelectionItem[];

            living1: SelectionItem[];
            living2: SelectionItem[];
            living3: SelectionItem[];
            living4: SelectionItem[];
            living5: SelectionItem[];
        };
    };

    g: {
        lv1: SelectionItem[];
        lv2: SelectionItem[];
        lv3: SelectionItem[];
        lv4: SelectionItem[];
        lvIADL: SelectionItem[];
    };

    h: {
        answer1: SelectionItem[];
        answer2: SelectionItem[];
        answer3: SelectionItem[];
        answer4: SelectionItem[];
    };

    i: {
        usageCodes: SelectionItem[];
        usageUnits: SelectionItem[];
        userPickup: SelectionItem[];
        categories: SelectionItem[];

        WaterIntake: SelectionItem[];
    };

    j: {
        icfFunctionaries: SelectionItem[];
        icfActivities: SelectionItem[];

        supportCognitives: SelectionItem[];
        supportPhysicals: SelectionItem[];
        supportCommunications: SelectionItem[];
        supportADL: SelectionItem[];
        supportIADL: SelectionItem[];
        supportOthers: SelectionItem[];
    };
    /** 利用時に追加 */
    k: {
        usages: SelectionItem[];
    };
    /** 利用時に追加 */
    l: {};
    
    m: {
        cancellationReasons: SelectionItem[];
        returnHomeCare: SelectionItem[];
        cognitiveScale1: SelectionItem[];
        cognitiveScale2: SelectionItem[];
        cognitiveScale3: SelectionItem[];
        cognitiveScale4: SelectionItem[];
        cognitiveScale5: SelectionItem[];
        cognitiveScale6: SelectionItem[];
        cognitiveScale7: SelectionItem[];
    };
    /** 利用時に追加 */
    n: {
        assessment: {
            questions: SelectionItem[];
            observations: SelectionItem[];
            answer1: SelectionItem[];
            answer2: SelectionItem[];
            answer3: SelectionItem[];
            answer4: SelectionItem[];
        };
        unimplementReason: SelectionItem[];
        foodForm: SelectionItem[];
        oralIntakes: SelectionItem[];
        // N10
        programContent: SelectionItem[];
        oralCleaning: SelectionItem[];
        eatTraining: SelectionItem[];
        voiceTraining: SelectionItem[];
        swallowTraining: SelectionItem[];
        othersTraining: SelectionItem[];
        selfCare: SelectionItem[];
        comprehensiveEvaluation: SelectionItem[];
        guidance1: SelectionItem[];
        guidance2: SelectionItem[];
        guidance3: SelectionItem[];
        evaluationValues: SelectionItem[];
        oralHygienes: SelectionItem[];
        oralFunctions: SelectionItem[];
        oralRemarks: SelectionItem[];
        issues: SelectionItem[];
        improvements: SelectionItem[];
        targets: SelectionItem[];
        lifeItems: SelectionItem[];
        targets2: SelectionItem[];
        evaluationValues2: SelectionItem[];
    };

    o: {
        health1: SelectionItem[];
        health2: SelectionItem[];
        health3: SelectionItem[];
        adl1: SelectionItem[];
        adl2: SelectionItem[];
        living1: SelectionItem[];
        living2: SelectionItem[];
        oral1: SelectionItem[];
        oral2: SelectionItem[];
        oral3: SelectionItem[];
        oral4: SelectionItem[];
        oral5: SelectionItem[];
        oral6: SelectionItem[];
        oral7: SelectionItem[];
        oral8: SelectionItem[];
        cognition1: SelectionItem[];
    };
};