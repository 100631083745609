import React from "react";

import { Card, CardContent, CardHeader } from "@mui/material";

import { OfficeInfo } from "../../services/Common";
import DataTable from "./DataTable";
import { useUser } from "../providers/UserProvider";

export interface SelectOfficeProps {
    offices: OfficeInfo[];
    onSelect?: (selectedOffice: OfficeInfo) => void;
}

const SelectOffice = (props: SelectOfficeProps) => {
    const {user} = useUser();
    return (
        <Card sx={{m: 1, width: 520}}>
            <CardHeader title="事業所選択" />
            <CardContent sx={{pl: 2, pr: 2, pt: 0}}>                
                <DataTable 
                columns={[{field: "name", header: "事業所", width: 200, headerAlign: "center"}]} 
                rows={props.offices} 
                pageSize={4}
                pagination
                paginationLayout="Top"
                selectable
                selectItem={props.offices.find(item => item.id === user?.office)}
                onSelect={item => props.onSelect! && props.onSelect(item)}/>
            </CardContent>
        </Card>
    );
};
export default SelectOffice;