import Rest from "./Rest";

const getAll = async (accessToken: string, officeId: number): Promise<string[]> => {
    return await Rest.get<string[]>({
        url: `/api/screen_com/memos/${officeId}`,
        authorization: accessToken,
    }) as string[];
};

const updateMemo = async (accessToken: string, officeId: number, index: number, content: string): Promise<void> => {
    await Rest.post<string>({
        url: "/api/screen_com/memo/",
        authorization: accessToken,
        data: {
            officeId: officeId,
            memoNo: index + 1,
            memoContent: content
        },
        noWrap: true,
    });
};

export const Notepad = {
    getAll, updateMemo,
} as const;