import React from "react";

import { Box, BoxProps } from "@mui/material";

type Props = Omit<BoxProps<"form">, "component" | "onKeyDown">;
const EnhancedForm = (props: Props) => (
    <Box component="form" {...props} onKeyDown={e => 
        e.key.toLowerCase() === "enter" && 
        (document.activeElement?.tagName ?? "").toLowerCase() !== "textarea" && 
        e.preventDefault()
    }/>
);
export default EnhancedForm;