import React from "react";

import { useUser } from "./providers/UserProvider";
import { RouteObject, useNavigate, useRoutes } from "react-router-dom";

import Routing, { LayoutRoutes } from "../configurations/Routing";
import Layout from "./Layout";
import RequireSigned from "./RequireSigned";
import RequireOffice from "./RequireOffice";
import { addErrorFilter, clearErrorFilter } from "../services/Rest";

const UserRoutes = () => {
    const navigate = useNavigate();
    const {error, errorMessage, setErrorMessage, getEffective} = useUser();
    const effective = errorMessage.length > 0 || error > 0 ? 0 : getEffective();
    const layoutChildren = LayoutRoutes.filter(item => (item.access & effective) > 0);
    const layoutRoute: RouteObject = {        
        path: "/", element: <RequireSigned deny="/signin" />, 
        children: [{ 
            element: <RequireOffice />, 
            children: [{ element: <Layout />,  children: layoutChildren }]
        }]
    };

    const errorFilter = React.useCallback((response: Response) => {
        try {
            if (response.status === 503 && !response.bodyUsed) {
                response.json().then(data => {
                    if (data.details!) {
                        const msg = data.details as string;
                        setErrorMessage(msg);
                        navigate("/maintenance");
                    }
                }).catch(error => console.error(error));
            }
        } catch (e) {
            console.error(e);
        }
    }, [setErrorMessage, navigate]);
    React.useEffect(() => {
        addErrorFilter(errorFilter);
        return () => { clearErrorFilter(); };
    }, [errorFilter]);

    return useRoutes([layoutRoute, ...Routing]);
};
export default UserRoutes;