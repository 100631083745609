import React from "react";
import { Box, Collapse } from "@mui/material";
import { useTheme } from "@mui/material";

import User from "../../types/User";

import SubMenu, { MenuItemProps } from "./SubMenu";
import { useLocation } from "react-router-dom";
import { useUser } from "../providers/UserProvider";

/** サイドバーパラメーター */
export interface SidebarProps {
    user: User;
    open: boolean;
    menus?: MenuItemProps[] | null;
};

/** サイドバー */
const Sidebar = (props: SidebarProps) => {
    const category = { text: "MENU", items: props.menus }
    const location = useLocation();
    const theme = useTheme();
    const {getEffective} = useUser();

    const effective = getEffective();

    const content = props.user !== null ? (
        <Collapse
        in={props.open} orientation="horizontal" 
        sx={{
            position: {
                lg: "static",
                xs: "absolute"
            },
            zIndex: 500,
            width: props.open ? "18.75rem" : 0,
            height: "100%",
            backgroundColor: theme.palette.background.paper, 
            boxShadow: 3,
            overflow: "hidden"
        }}>
            <Box sx={{                 
                pb: 1,  
                maxWidth: "18.75rem", 
                width: "18.75rem", 
                height: "100%", 
                overflowX: "hidden",
                overflowY: "auto",
                scrollbarWidth: "thin",
                "& ul": { width: "18.75rem" },
                "&::-webkit-scrollbar": { width: "5px" },
                scrollbarColor: "rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)",
                "&::-webkit-scrollbar-thumb": { background: "rgba(0, 0, 0, 0.3)" },
                "&::-webkit-scrollbar-track": { background: "rgba(0, 0, 0, 0.1)" }
            }}>
                <SubMenu category={(
                    <Box pt={1} display="flex" justifyContent="space-between" alignItems="center">
                        {[category.text, /*props.user.officeName,*/ ].map((text, index) => (
                            <span key={index} style={{margin: ".25rem", lineHeight: 1, overflowWrap: "break-word"}}>{text}</span>
                        ))}
                    </Box>
                )} items={category.items} filter={effective} current={location.pathname} />
            </Box>
        </Collapse>
    ) : (<></>);
    return content;
};
export default Sidebar;