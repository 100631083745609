import React from "react";
import { Resource } from "../../services/Rest";
import DelayLoad from "./DelayLoad";
import ErrorBoundary from "./ErrorBoundary";

export type ResourceReceiver<T extends object> = React.FC<{resourceData?: T | null}>;

export interface AsyncResourceProps<T extends object> {
    resource: Resource<T>;
    receiver: ResourceReceiver<T>;
}

const ResourceHost = <T extends object>(props: AsyncResourceProps<T>, ...rest: any[]) => {
    const data = props.resource.read();
    return <props.receiver resourceData={data} {...rest}/>;
};

const AsyncResource = <T extends object>(props: AsyncResourceProps<T> & {errorFallback?: React.ReactNode}, ...rest: any[]) => {
    return (
        <ErrorBoundary fallback={props.errorFallback}>
            <React.Suspense fallback={<DelayLoad />}>
                <ResourceHost {...props} {...rest}/>
            </React.Suspense>
        </ErrorBoundary>
    );
};
export default AsyncResource;