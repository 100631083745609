import React from "react";

import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";

import { Link, Button, Avatar, Typography, Card, TextField, InputAdornment, Checkbox, FormControlLabel, Box } from "@mui/material";
import { LockOutlined, PersonAdd, Visibility, VisibilityOff } from "@mui/icons-material";
import { teal } from "@mui/material/colors";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { SigninInput, SigninSchema, useUser } from "../providers/UserProvider";
import { AppMenuTree } from "../../configurations/Routing"
import Footer from "../parts/Footer";
import { MessageContext } from "../providers/MessageProvider";
import { MultilineLabel } from "../parts/TabPanel";

type SavedUser = Omit<SigninInput, "password">;
const SignIn = () => {
    const {showMessage} = React.useContext(MessageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.state as string;

    const model = useUser();
    const { register, handleSubmit, formState: { errors }, setFocus, setValue } = useForm<SigninInput>({
        mode: "onBlur",
        resolver: yupResolver(SigninSchema),
    });

    const [visiblePassword, setVisiblePassword] = React.useState(false);
    const handleVisibility = () => setVisiblePassword(!visiblePassword);
    const [saveId, setSaveId] = React.useState(false);
    const handleSaving = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setSaveId(checked);

    React.useEffect(() => {
        const saved = localStorage.getItem("savedUser");
        if (saved !== null && saved.length > 0) {
            setSaveId(true);
            const value = JSON.parse(saved) as SavedUser;
            setValue("corporate", value.corporate);
            setValue("userId", value.userId);
            setFocus("password");            
        } else {
            setFocus("corporate");
        }
    }, [setFocus, setValue]);

    const onSubmit = (data: SigninInput) => {
        model.signin({ corporate: data.corporate, userId: data.userId, password: data.password }, (result, user, reason) => {
            if (result) { 
                let navigateUrl = path ?? "/";
                if (saveId) {
                    localStorage.setItem("savedUser", JSON.stringify({ corporate: data.corporate, userId: data.userId }));
                } else {
                    localStorage.removeItem("savedUser");
                }
                const defaultPage = user?.initial ?? 0;
                if (defaultPage !== 0) {
                    navigateUrl = AppMenuTree.find(value => value.menuKey === defaultPage)?.path!;
                }
                navigate(navigateUrl);
            } else {
                setValue("password", "");                
                showMessage({
                    type: "error", title: "ログインエラー", description: ["ログインに失敗しました。", reason ?? ""]
                });
            }
        });
    };

    return (
        <Box display="flex" flexDirection="column" sx={{height: "100%"}}>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{flex: "auto"}}>
                <Box sx={{overflow: "auto"}}>
                    <Card sx={{display: "inline-block", m: 2, p: 2, width: 320}}>
                        <Box component="div" pl={1} sx={theme => ({backgroundColor: theme.palette.primary.main, width: 1})}>
                            <Typography variant="h6" pl={1} sx={theme => ({backgroundColor: theme.palette.background.default})}>会員の方</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" m={2}>
                            <Avatar sx={{ bgcolor: teal[400] }}><LockOutlined /></Avatar>
                            <Typography variant={"h5"} sx={{ ml: 1 }}>ログイン</Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{height: 300}} display="flex" flexDirection="column">
                            <Box flex="auto">
                            <TextField label="法人コード"
                            {...register("corporate")}
                            autoFocus={true}
                            autoComplete="off"
                            error={"corporate" in errors}
                            helperText={errors.corporate && errors.corporate.message as string}
                            variant="outlined" fullWidth required />
                            <TextField label="ユーザーID" 
                            {...register("userId")}
                            autoComplete="off"
                            error={"userId" in errors}
                            helperText={errors.userId && errors.userId.message as string}
                            variant="outlined" fullWidth required sx={{ mt: 1 }} />
                            <TextField label="パスワード" 
                            {...register("password")} 
                            error={"password" in errors} 
                            helperText={errors.password && errors.password.message as string}
                            variant="outlined" fullWidth required 
                            sx={{ mt: 1, mb: 2 }}
                            type={visiblePassword ? "text" : "password"}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{cursor: "pointer"}} onClick={handleVisibility}>
                                        {visiblePassword ? <VisibilityOff /> : <Visibility />}
                                    </InputAdornment>
                                )
                            }}/>
                            <FormControlLabel label="法人コードとユーザーIDを保存" control={<Checkbox checked={saveId} onChange={handleSaving}/>}/>
                            </Box>
                            <Button type="submit" variant="contained" color="primary" sx={{width: "100%"}}>ログイン</Button>
                        </Box>
                    </Card>
                    <Card sx={{display: "inline-block", m: 2, p: 2, width: 320}}>
                        <Box component="div" pl={1} sx={theme => ({backgroundColor: theme.palette.primary.main, width: 1})}>
                            <Typography variant="h6" pl={1} sx={theme => ({backgroundColor: theme.palette.background.default})}>初めてご利用の方</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" m={2}>
                            <Avatar sx={{ bgcolor: teal[400] }}><PersonAdd /></Avatar>
                            <Typography variant={"h5"} sx={{ ml: 1 }}>無料会員登録</Typography>
                        </Box>
                        <Box sx={{height: 300}} display="flex" flexDirection="column">
                            <MultilineLabel sx={{flex: "auto"}} text={"ご利用には無料会員登録が必要です。\nはじめてご利用の方お客様は、こちらから無料会員登録を行ってください。"}/>
                            <Link component={RouterLink} to="/signup" sx={{width: "100%"}}>
                                <Button type="button" variant="contained" color="primary" sx={{width: "100%"}}>無料会員登録</Button>
                            </Link>
                        </Box>
                    </Card>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};
export default SignIn;