import React from "react";

import { Box, Typography, Button, Card, CardContent, Grid, Table, TableBody, TableRow, TableCell, CardActions, Dialog, LinearProgress, DialogContent } from "@mui/material";

import { ResourceContext } from "../../providers/ResourceProvider";
import { SignupStepProps } from "./types";
import Prefectures from "../../../configurations/PrefecturalCode";
import Register from "../../../services/Register";
import EnhancedForm from "../../parts/EnhancedForm";
import { MessageContext } from "../../providers/MessageProvider";
import { getErrorMessage } from "../../../services/Rest";

const InputConfirm = (props: SignupStepProps) => {
    const { settings } = React.useContext(ResourceContext);
    const { showMessage }= React.useContext(MessageContext);
    const corporateTypes: {[key: number]: string} = Object.assign({}, ...settings!.a.corpTypes.map(value => ({[value.selectionCode]: value.description})));
    const prefs: {[key: string]: string} = Object.assign({}, ...Prefectures.map(value => ({[value.code]: value.name})));
    const posts: {[key: number]: string} = Object.assign({}, ...settings!.a.posts.map(value => ({[value.selectionCode]: value.description})));
    const bizHours: {[key: number]: string} = Object.assign({}, ...settings!.a.bizHoursTypes.map(value => ({[value.selectionCode]: value.description})));
    const corpAddress = `${prefs[props.data.corporate.addressPref!]} ${props.data.corporate.addressMunicipalities} ${props.data.corporate.addressDetails}`;
    const officeAddress = `${prefs[props.data.office.addressPref!]} ${props.data.office.addressMunicipalities} ${props.data.office.addressDetails}`;

    const today = new Date();
    const beginOfMonth = new Date();
    beginOfMonth.setDate(1);
    let month = beginOfMonth.getMonth() + 2, year = beginOfMonth.getFullYear();
    if (month > 12) {
        month -= 12;
        year += 1;
    }
    beginOfMonth.setFullYear(year);
    beginOfMonth.setMonth(month);
    const freeLimit = new Date(beginOfMonth.getTime() - (1000 * 60 * 60 * 24));

    const handleBack = (count?: number) => {
        if (props.onBack !== undefined) {
            props.onBack(count);
        }
    };

    const [displayWait, setDisplayWat] = React.useState(false);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (props.data.office.sameCorporate ?? true) {
            props.data.office.mail = props.data.corporate.mail;
            props.data.office.tel = props.data.corporate.tel;
            props.data.office.zip = props.data.corporate.zip;
            props.data.office.addressPref = props.data.corporate.addressPref;
            props.data.office.addressMunicipalities = props.data.corporate.addressMunicipalities;
            props.data.office.addressDetails = props.data.corporate.addressDetails;
        }
        
        // ユーザー登録を実行する。
        setDisplayWat(true);
        Register.execute(props.data.corporate, props.data.office, props.data.user).then(() => {
            // 成功時は次へ
            setDisplayWat(false);
            if (props.onNext !== undefined) {
                props.onNext();
            }
        }).catch(error => {
            console.error(error);
            setDisplayWat(false);
            getErrorMessage(error).then(msg => showMessage({type: "error", title: "無料体験申し込み失敗", description: [
                "無料体験の申し込みに失敗しました。", msg.message
            ]}));
        }).finally(() => setDisplayWat(false));
    };

    return (
        <>
        <EnhancedForm onSubmit={onSubmit} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: 2 }}>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardActions>
                                <Button type="button" onClick={() => handleBack(3)}>修正</Button>
                            </CardActions>
                            <CardContent>
                                <Typography variant="h6" component="p" sx={{
                                    mb: {
                                        md: "37px"
                                    }
                                }}>
                                    {props.data.corporate.type! !== 9 && props.data.corporate.typeOffset === 1 && <span>{corporateTypes[props.data.corporate.type!]}</span>}
                                    <ruby>{props.data.corporate.name}<rt>{props.data.corporate.kana}</rt></ruby>
                                    {props.data.corporate.type! !== 9 && props.data.corporate.typeOffset !== 1 && <span>{corporateTypes[props.data.corporate.type!]}</span>}
                                    <span>様</span>
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" variant="head">担当者</TableCell>
                                            <TableCell>
                                                <ruby>{props.data.corporate.picName}<rt>{props.data.corporate.picKana}</rt></ruby>
                                                <span> 様</span>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">役職</TableCell>
                                            <TableCell>{posts[props.data.corporate.picPost!]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">メールアドレス</TableCell>
                                            <TableCell>{props.data.corporate.mail}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">法人電話番号</TableCell>
                                            <TableCell>{props.data.corporate.tel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">住所</TableCell>
                                            <TableCell>{`〒${props.data.corporate.zip}`}<br />{corpAddress}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardActions>
                                <Button type="button" onClick={() => handleBack(2)}>修正</Button>
                            </CardActions>
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" variant="head">事業所名</TableCell>
                                            <TableCell>{props.data.office.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">事業所定員数</TableCell>
                                            <TableCell>{`${props.data.office.employeeCount} 名`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">事業形態</TableCell>
                                            <TableCell>{`${bizHours[props.data.office.type!]}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">メールアドレス</TableCell>
                                            <TableCell>{props.data.office.sameCorporate??true ? props.data.corporate.mail : props.data.office.mail}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">事業所電話番号</TableCell>
                                            <TableCell>{props.data.office.sameCorporate??true ? props.data.corporate.tel : props.data.office.tel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">事業所FAX番号</TableCell>
                                            <TableCell>{props.data.office.sameCorporate??true ? "" : props.data.office.fax}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">住所</TableCell>
                                            <TableCell>{`〒${props.data.office.sameCorporate??true ? props.data.corporate.zip : props.data.office.zip}`}<br />{props.data.office.sameCorporate??true ? corpAddress : officeAddress}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardActions>
                                <Button type="button" onClick={() => handleBack()}>修正</Button>
                            </CardActions>
                            <CardContent>
                                <Typography variant="h6" component="p" mb={1}>申込み情報</Typography>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" variant="head">申込み日</TableCell>
                                            <TableCell>{today.toLocaleDateString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">プラン</TableCell>
                                            <TableCell>{`無料プラン 期間:${freeLimit.toLocaleDateString()}まで`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" variant="head">ログインID</TableCell>
                                            <TableCell>{props.data.user.userId}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ p: 1 }}>
                <Button variant="contained" onClick={() => handleBack()}>戻る</Button>
                <Button type="submit" variant="contained" color="primary">登録</Button>
            </Box>
        </EnhancedForm>
        <Dialog open={displayWait} maxWidth="sm" fullWidth>
            <DialogContent>
                <Typography variant="h6" textAlign="center">
                    <Typography>無料体験に申し込み中です。</Typography>
                    <Typography>しばらくお待ち下さい。</Typography>
                </Typography>
                <LinearProgress/>
            </DialogContent>
        </Dialog>
        </>
    );
}
export default InputConfirm;