import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Container, Drawer, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";

import { useUser } from "./providers/UserProvider";
import { MessageContext } from "./providers/MessageProvider";
import Define from "../configurations/Define";
import { AppBreadcrumbs, AppMenuTree } from "../configurations/Routing";

import Haeder from "./parts/Header";
import Sidebar from "./parts/Sidebar";
import Breadcrumbs from "./parts/Breadcrumbs";
import MemoList from "./parts/MemoList";
import DelayLoad from "./parts/DelayLoad";
import Footer from "./parts/Footer";
import AccountMenu from "./AccountMenu";
import { getEditing } from "./parts/EditingDetection";
import SelectOffice from "./pages/common/SelectOffice";
import { OfficeInfo } from "../services/Common";
import { KeyboardDoubleArrowUp } from "@mui/icons-material";

/** 共通レイアウト */
const Layout = () => {
    const theme = useTheme();
    const model = useUser();
    const navigate = useNavigate();
    const { showMessage } = React.useContext(MessageContext);
 
    const isMobile = useMediaQuery(() => theme.breakpoints.down("sm"));
    const isUpperFHD  = useMediaQuery(() => theme.breakpoints.up(1920));
    const previousToggled = localStorage.getItem("sidebarToggled");
    const [toggled, setToggled] = React.useState(!isMobile && previousToggled !== null ? Boolean(previousToggled) : false); 
    const handleToggled = () => setToggled(!toggled);

    const [openSelectOffice, setOpenSelectOffice] = React.useState(false);
    const handleSelectOffice = () => setOpenSelectOffice(true);
    const handleSelectedOffice = (selected: boolean, item?: OfficeInfo) => {
        setOpenSelectOffice(false);
        if (selected && item!) {
            model.updateOffice(item.id, item.name, item.bizType, item.dataPreserve, item.adlPersonalized, item.availableReceipt, item.physicalPlan);
            navigate("/");
        }
    };

    const openManual = React.useCallback((url: string) => url.length > 0 && window.open(url, "_manual"), []);

    const handleHelp = () => openManual(Define.links.help);
    const handleManual = () => openManual(Define.links.manual);
    const handleMovie = () => openManual(Define.links.movie);
    const [openMemo, setOpenMemo] = React.useState(false);
    const handleMemo = () => setOpenMemo(true);
    const closeMemo = () => setOpenMemo(false);
    const logout = React.useCallback(() => {
        model.signout(model.user!.userId);
        navigate("/");
    }, [model, navigate]);
    const handleLogout = () => {
        const editing = getEditing();
        if (editing) {
            showMessage({
                type: "warning", ask: true, title: "編集された情報の破棄",
                description: ["保存されていない編集された情報を破棄します。", "よろしいですか？"],
                onClose: yes => { yes && logout(); }
            });
            return;
        }
        logout();
    };

    React.useEffect(() => {
        if (toggled) {
            localStorage.setItem("sidebarToggled", toggled.toString());
        } else {
            localStorage.removeItem("sidebarToggled");
        }
    }, [toggled]);

    const ScrollLimit = 300;
    const [usageScroll, setUsageScroll] = React.useState(false);
    const scrollContainerRef = React.useRef<HTMLDivElement>();
    const handleScroll = React.useCallback(() => {
        scrollContainerRef.current! && setUsageScroll(scrollContainerRef.current.scrollTop > ScrollLimit);
    }, []);
    const handleScrollToTop = React.useCallback(() => {
        if (scrollContainerRef.current!) {
            scrollContainerRef.current.scrollTop = 0;
            setUsageScroll(false);
        }
    }, []);
    React.useEffect(() => {
        const container = scrollContainerRef.current;
        container! && container.addEventListener("scroll", handleScroll);
        return () => container! && container.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    return (
        <>
            <Haeder user={model.user} title={`${Define.appTitle}`} img={Define.appIcon} onMenuClick={handleToggled}>
                <AccountMenu isMobile={isMobile}
                onSelectOffice={handleSelectOffice}
                onHelp={handleHelp}
                onManual={handleManual}
                onMovie={handleMovie}
                onMemo={handleMemo}
                onLogout={handleLogout}/>
                <Drawer anchor="right" open={openMemo} onClose={closeMemo}>
                    <MemoList open={openMemo} />
                </Drawer>
            </Haeder>
            <Box sx={{ 
                background: theme.palette.background.default,
                height: "100%", 
                display: "flex", 
                flexDirection: "column"
            }}>
                <Toolbar />
                <Box component="div" sx={{ position: "relative", flexGrow: 1,overflow: "hidden", display: "flex", flexDirection: "row" }}>
                    <Sidebar menus={AppMenuTree} user={model.user!} open={toggled} />
                    <Box component="main" ref={scrollContainerRef} sx={{ 
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1, 
                        overflowY: "auto",
                        "&::-webkit-scrollbar": { width: "5px" },
                        scrollbarColor: "rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)",
                        "&::-webkit-scrollbar-thumb": { background: "rgba(0, 0, 0, 0.3)" },
                        "&::-webkit-scrollbar-track": { background: "rgba(0, 0, 0, 0.1)" }
                    }}>
                        <Breadcrumbs map={AppBreadcrumbs} user={model.user!} />
                        <Box sx={{flexGrow:1, display: "flex", flexDirection: "column"}}>
                            <Container maxWidth={(isUpperFHD ? "xl" : "lg")} sx={{ pt: 2, flexGrow:1 }}>
                                <React.Suspense fallback={<DelayLoad />}>
                                    <Outlet />
                                </React.Suspense>
                            </Container>
                            <Footer />
                        </Box>
                    </Box>
                    <IconButton className={"To-top-button"} title="TOPへ" sx={{
                        position: "fixed", zIndex: 9999, alignItems: "center", 
                        width: "3.5rem", height:"3.5rem", lineHeight: "3.5rem", 
                        top: "70%", right: "1.1rem", borderRadius: ".25rem", 
                        display: usageScroll ? "inline-flex" : "none",                        
                    }} onClick={handleScrollToTop}>
                        <KeyboardDoubleArrowUp />
                    </IconButton>
                </Box>
            </Box>
            <SelectOffice open={openSelectOffice} onClose={handleSelectedOffice} />
        </>
    );
};
export default Layout;