import yup from "../extends/yup.extends";

/** 事業所情報 */
export default interface Office {
    /** 事務所ID */
    id?: number;
    /** 事務所名 */
    name?: string;
    /** 従業員数 */
    employeeCount?: number;
    /** 運営形態 */
    type?: number;
    /** お知らせ */
    notify?: boolean;
    /** メールアドレス */
    mail?: string;
    /** 電話番号 */
    tel?: string;
    /** Fax番号 */
    fax?: string;
    /** 郵便番号 */
    zip?: string;
    /** 県コード */
    addressPref?: string;
    /** 住所1 */
    addressMunicipalities?: string;
    /** 住所2 */
    addressDetails?: string;

    /** 通所介護計画 長期目標期間 */
    dayCareLongTermPeriod?: number;
    /** 通所介護計画 短期目標機関 */
    dayCareShortTermPeriod?: number;
    /** 通所介護計画 アラート対象 */
    alertTarget?: number,
    /** 通所介護計画 モニタリング作成者印刷 */
    printMonitoringAuthors?: boolean,

    /** 運動器機能向上計画書 作成有効 */
    availablePhysicalPlan?: boolean,
    /** 運動器機能向上計画 評価目標 */
    athleticAbilityAssessment?: number;
    /** 運動器機能向上計画 主な実施者 */
    athleticAbilityImplementor?: number;

    /** 個別機能訓練計画 長期目標期間 */
    personalLongTermPeriod?: number;
    /** 個別機能訓練計画 短期目標期間 */
    personalShortTermPeriod?: number;
    /** 個別機能訓練計画 評価目標 */
    personalAssessment?: number;
    /** 個別機能訓練計画 主な実施者 */
    personalImplementor?: number;

    /** 身体機能 */
    physicalTest?: number;

    /** 連絡帳・帳票 出力設定 */
    outputReport?: number;

    /** LIFE ADL維持等加算 */
    lifeAdlEnableCustomer?: boolean;
};

/** 新規登録時の除外プロパティ */
type ignoreSignup = "id" | 
"dayCareLongTermPeriod" | "dayCareShortTermPeriod" | 
"availablePhysicalPlan" | "athleticAbilityAssessment" | "athleticAbilityImplementor" |
"personalLongTermPeriod" | "personalShortTermPeriod" | "personalAssessment" | "personalImplementor" |
"physicalTest";

/** 事業所入力情報 */
export type InputOfficeForm = Omit<Office, ignoreSignup> & {
    sameCorporate?: boolean;
};
/** 事業所入力情報スキーマ */
export const InputOfficeSchema = yup.object({
    name: yup.string().required("入力必須項目です。").max(274, "274文字以下で入力してください。"),
    employeeCount: yup.number().typeError("数値を入力してください。").max(500, "500名以下で入力してください。"),
    type: yup.number().typeError("項目を選択してください。").required("項目を選択してください。"),
    /* yupのバグ？前半部分に全角文字が許可される  
    mail: yup.string().required("入力必須項目です。").email("Eメールアドレスを半角英数字で入力してください。").max(254, "254文字以下で入力してください。"), */
    mail: yup.string().required("入力必須項目です。").mailAddress("Eメールアドレスを半角英数字で入力してください。").max(254, "254文字以下で入力してください。"),
    tel: yup.string().required("入力必須項目です。").phone("電話番号を半角数値ハイフンなしで入力してください。"),
    fax: yup.string().nullable().notRequired().when({
        is: (value?: string | null) => value! && value?.length > 0,
        then: (rule) => rule.homePhone("FAX番号を半角数値ハイフンなしで入力してください。"),
    }),
    zip: yup.string().when({
        is: (value?: string | null) => value! && value?.length > 0,
        then: (rule) => rule.zip("郵便番号を半角数値ハイフンなしで入力してください")
    }),
    addressPref: yup.string().required("入力必須項目です。"),
    addressMunicipalities: yup.string().required("入力必須項目です。").max(483, "483文字以下で入力してください。"),
    addressDetails: yup.string().max(80, "80文字以下で入力してください。"),
});

const OfficeOtherSchema = yup.object({
});

/** 事業所情報スキーマ */
export const FullOfficeSchema = InputOfficeSchema.concat(OfficeOtherSchema);

/** 事業所計画書設定スキーマ */
export const OfficePlanSettingSchema = yup.object({
    dayCareLongTermPeriod: yup.number().typeError("項目を選択してください。"),
    dayCareShortTermPeriod: yup.number().typeError("項目を選択してください。"),
    athleticAbilityAssessment: yup.number().when("availablePhysicalPlan", {
        is: true,
        then: rule => rule.typeError("項目を選択してください。")
    }).typeError("数値を入力してください。"),
    personalLongTermPeriod: yup.number().typeError("項目を選択してください。"),
    personalShortTermPeriod: yup.number().typeError("項目を選択してください。"),
    personalAssessment: yup.number(),
    physicalTest: yup.number().min(1, "1項目は必ず選択してください。"),
});

/** 介護事業情報 */
export interface CareBizInfo {
    index?: number;
    licenseCode?: string;
    type?: string;    
    serviceCode?: number;
}
/** 介護事業情報スキーマ */
export const CareBizSchema = yup.object({
    licenseCode: yup.string().required("入力必須項目です。").numeric(10, "10桁の数字を入力してください。"),
    serviceCode: yup.number().typeError("項目を選択してください。"),
});

export type OfficeSet = {
    office: Office,
    attributes?: CareBizInfo[] | null
};