import React from "react";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";

export type MultilineLabelProps = TypographyProps & {text: string};

export const MultilineLabel = (props: MultilineLabelProps) => {
    const lines = props.text.split("\n");
    return (
        <Typography {...props}>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {line}
                </React.Fragment>
            ))}
        </Typography>
    );
};

export interface TabPanelProps<T> extends BoxProps {
    tab: T;
    value: T;
};

const TabPanel = <T = number | string>(props: TabPanelProps<T>) => {
    const {tab, value, ...boxProps} = props; 
    const active = tab === value;
    return (
        <Box display={active ? "block" : "none"} sx={{p: 2}} {...boxProps}>
            {active && props.children}
        </Box>
    );
};
export default TabPanel;