import React from "react";

/** Default Timeout MilliSeconds */
const defaultTimeoutMS = 10 * 1000;

/** Timeout Error */
export class CustomTimeoutError extends Error {};

/** Response Error */
export class ResponseError extends Error {
    constructor(message: string, public readonly response: Response) {
        super(message);
        this.name = "ResponseError";
    }
};

/** standard error types */
export const ErrorTypes = {
    Ignore: 0, User: 1, Timeout: 2, Unauthorized: 3, Fatal: 8
} as const;
export type ErrorType = typeof ErrorTypes[keyof typeof ErrorTypes];

/** error type & message */
export interface ErrorMessage {
    response: boolean;
    responseData?: any;
    status: number;
    type: ErrorType;
    message: string;
}

/** api error result types */
interface ErrorResult { details: string; };
const isErrorResult = (data: unknown): data is ErrorResult => (data as ErrorResult).details !== undefined;
const getJsonData = async (response: Response): Promise<any | undefined> => {
    try {
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

/** api error message */
export const getErrorMessage = async (error: Error): Promise<ErrorMessage>  => {
    let response = false;
    let responseData: any | undefined = undefined;
    let status = 0;
    let type: ErrorType = ErrorTypes.Ignore;
    let message: string = "処理に失敗しました。";
    if (error instanceof ResponseError) {
        response = true;
        status = error.response.status;
        if (!error.response.bodyUsed) {
            responseData = await getJsonData(error.response);        
        }
        if (error.response.status === 400) {
            if (responseData! && isErrorResult(responseData)) {
                type = ErrorTypes.User;
                message = responseData.details;
            }
        } else if (error.response.status === 401) {
            type = ErrorTypes.Unauthorized;
            message = "ユーザー認証の有効期限切れが発生しました。";
        } else {
            type = ErrorTypes.Fatal;
            message = "サーバーでエラーが発生しました。";
        }
    } else if (error instanceof CustomTimeoutError) {
        type = ErrorTypes.Timeout;
        message = "処理がタイムアウトしました。";
    } else {
        if (error.name === "AbortError") {
            type = ErrorTypes.User;
            message = "処理がキャンセルされました。";
        } else if (error.name === "TimeoutError") {
            type = ErrorTypes.Timeout;
            message = "処理がタイムアウトしました。";
        }
    }
    return { response, responseData, status, type, message };
};

/** error filter function types */
export type ErrorFilter = (response: Response) => void;

/** error filter */
const errorFilters: ErrorFilter[] = [];
export const clearErrorFilter = () => errorFilters.splice(0);
export const addErrorFilter = (filter: ErrorFilter) => errorFilters.push(filter);
const errorFiltering = (response: Response) =>  {
    for (let filter of errorFilters) {
        filter(response);
    }
    return new ResponseError("fetch web request response error", response);
};

/** for Fetch Timeout */
const timeout = <T>(task: Promise<T>, waitMS?: number) => {
    const timeoutMS = waitMS ?? defaultTimeoutMS;
    const timeoutTask = new Promise((resolve, _) => setTimeout(resolve, timeoutMS))
    .then(() => Promise.reject(new CustomTimeoutError(`Operation timed out after ${timeoutMS} ms`)));
    return Promise.race([task, timeoutTask]);
};

const wrapString = (task: Promise<Response | void>): Promise<string> => {
    return new Promise((resolve, reject) => {
        task.then(response => {
            if (response !== undefined && response !== null) {
                if (response.ok) {
                    response.text().then(text => {                        
                        if (text !== null) {
                            resolve(text);
                        } else {
                            reject("type missmatch");
                        }
                    })
                    .catch(error => reject(error));
                } else {
                    reject(errorFiltering(response));
                }
            } else {
                reject(new Error("Unintended usage"));    
            }
        }).catch(error => {
            if (error.name === "AbortError") {
                reject(new CustomTimeoutError("user cancel request"))
            } else {
                reject(error);
            }
        });
    });
};

/** Wrapping JSON for Response Types */
const wrap = <T>(task: Promise<Response | void>): Promise<T> => {
    return new Promise((resolve, reject) => {
        task.then(response => {
            if (response !== undefined && response !== null) {
                if (response.ok) {
                    response.json().then(json => {
                        const result = json as T;
                        if (result !== null) {
                            resolve(result);
                        } else {
                            reject("type missmatch");
                        }
                    })
                    .catch(error => reject(error));
                } else {
                    reject(errorFiltering(response));
                }
            } else {
                reject(new Error("Unintended usage"));    
            }
        }).catch(error => reject(error));
    });
};

/** get url concatenated with query string */
const toUrl = (url: string, query?: object): string => {
    if (query !== undefined) {
        const queryString = Object.entries(query).map(value => `${value[0]}=${value[1]}`).join("&");
        return `${url}?${queryString}`;
    }
    return url;
};

/** get json : method = [ *GET | DELETE ] */
const get = <T = any>(request: { 
    url: string, 
    method?: "GET" | "DELETE",
    query?: object,
    authorization?: string,
    wait?: number,
    noWrap?: boolean,
    signal?: AbortSignal,
}) => {
    const url = toUrl(request.url, request.query);
    const headers: HeadersInit = { "Accept": "application/json" };
    if (request.authorization!) {
        headers["Authorization"] = `Bearer ${request.authorization!}`;
    }
    const getMethod = request.method ?? "GET";
    const init: RequestInit = { 
        method: getMethod, 
        headers: headers,
        signal: request.signal, 
    };

    process.env.NODE_ENV === "development" &&
        console.log(request.url, {method: getMethod, headers: headers, data: {queries: request.query, url: url}});
    return (request.noWrap ?? false) ? 
    wrapString(timeout(fetch(url, init), request.wait)) :
    wrap<T>(timeout(fetch(url, init), request.wait));
};

/** post json : method = [ *POST | PUT | PATCH | DELETE ] */
const post = <T = any>(request: { 
    url: string, 
    method?: "POST" | "PUT" | "PATCH" | "DELETE", 
    data: object | string, 
    query?: object,
    authorization?: string, 
    wait?: number,
    noWrap?: boolean,
    signal?: AbortSignal,
}) => {
    const url = toUrl(request.url, request.query);
    const headers: HeadersInit = { 
        "Accept": "application/json", 
        "Content-Type": "application/json" 
    };
    if (request.authorization!) {
        headers["Authorization"] = `Bearer ${request.authorization!}`;
    }
    const postMethod = request.method ?? "POST";
    const init: RequestInit = { 
        method: postMethod,
        headers: headers, 
        body: JSON.stringify(request.data),
        signal: request.signal,
    };

    process.env.NODE_ENV === "development" &&
        console.log(url, {method: postMethod, headers: headers, body: {data: request.data, json: init.body}});
    return (request.noWrap ?? false) ? 
    wrapString(timeout(fetch(url, init), request.wait)) : 
    wrap<T>(timeout(fetch(url, init), request.wait));
};

/** download file */
const download = (request: {
    url: string,
    authorization?: string, 
    wait?: number,
    signal?: AbortSignal, 
}) => {
    const headers: HeadersInit = {};
    if (request.authorization!) {
        headers["Authorization"] = `Bearer ${request.authorization!}`;
    }
    const init: RequestInit = { 
        method: "GET", 
        headers: headers, 
        signal: request.signal
    };
    return timeout(fetch(request.url, init), request.wait);
};

/** upload file */
const upload = <T = any>(request: { 
    url: string, 
    data: FormData, 
    authorization?: string, 
    wait?: number,
    signal?: AbortSignal, 
}) => {
    const headers: HeadersInit = { 
        "Accept": "application/json",
        "Content-Type": "multipart/form-data"
    };
    // note: content-type[multipart/form-data]
    // ref: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
    // normal case. it seems that the area length (boundary) is set.
    // explicitly remove it and let the browser reconfigure it...?
    delete headers["Content-Type"];

    if (request.authorization!) {
        headers["Authorization"] = `Bearer ${request.authorization!}`;
    }
    const init: RequestInit = { 
        method: "POST", 
        headers: headers, 
        body: JSON.stringify(request.data),
        signal: request.signal,
    };
    return wrap<T>(timeout(fetch(request.url, init), request.wait));
};

/** react suspense resource type */
export type Resource<T = any> = {
    read: () => T | null;
}

/** wrap promise for react suspense */
const wrapPromise = <T = any>(promise: Promise<T>, min?: number): Resource<T> => {
    const waitMin = min ?? 250;
    const begin = new Date();
    let status: "pending" | "fulfilled" | "rejected" = "pending";
    let result: T | null = null;
    let error: any = null;
    const suspender = promise.then(data => { 
        status = "fulfilled";
        result = data; 
    }).catch(err => {
        status = "rejected";
        error = err;
    });
    return { read: () => {
        const now = new Date();
        const dif = now.getTime() - begin.getTime();
        if (dif < waitMin) {
            throw suspender;
        }
        if (status === "pending") {
            throw suspender;
        } else if (status === "rejected") {
            throw error;
        }
        return result;
    }};
};

/** REST JSON API + File Upload */
const Rest = { 
    get, 
    post, 
    download,
    upload, 
    resource: wrapPromise,
} as const;
export default Rest;

export type ResourceOption = {
    hash?: number;
    authorization?: string;
    wait?: number;
};

/** get Json resource */
export const useJsonData = <T>(url: string, options?: ResourceOption) => {
    const [data, setData] = React.useState<T | null>(null);

    const load = React.useCallback((signal?: AbortSignal) => {
        const resourceUrl = options?.hash! ? `${url}?${options.hash}` : url;
        Rest.get<T>({
            url: resourceUrl, 
            authorization: options?.authorization, 
            wait: options?.wait,
            signal: signal,
        }).then(data => {
            const typedData = data !== undefined ? data as T : null;
            setData(typedData);
        }).catch(error => {
            if (error["name"] === "AbortError") {
                console.log(`cancel request ${resourceUrl}`);
            } else {
                console.error(error);
            }
            setData(null);
        });
    }, [url, options]);

    React.useEffect(() => {
        const controller = new AbortController();
        load(controller.signal);
        return () => controller.abort();
    }, [load]);
    
    return { data, reload: load };
};