import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useUser } from "./providers/UserProvider";
import AccessControl from "../configurations/AccessControl";

/** パラメーター */
export interface RequireSignedProps {
    /** 拒否時のリダイレクトするパス */
    deny?: string | null;
}

/** 署名(ログイン)必須なページ */
const RequireSigned = (props: RequireSignedProps) => {
    const location = useLocation();
    const model = useUser();
    const permitted = AccessControl.authorizeRole(location.pathname, model.user!);

    return model.error > 0 || (model.user !== null && permitted) ? <Outlet /> : <Navigate to={props.deny!} state={location} />;
};

export default RequireSigned;