export interface Syllabary {
    value: number;
    description: string;
    letters: string[];
};

export interface CodeSummary {
    code: number;
    description: string;
};

export interface DefineType {
    appId: string;
    appTitle: string;
    appIcon: string;
    copyright: {
        reserved: string;
        supportTel: string;
        privacyPolicy: string;
        termsOfService: string;
    };
    placeholders: {[key:string]:string};    
    revision: string;
    resource: string;
    help: string;
    jpChronology: string;
    jpSyllabary: Syllabary[];
    degreeOfCares: CodeSummary[];
    plannings: CodeSummary[];
    assessments: CodeSummary[];
    planStatus: CodeSummary[];
    oralStatus: CodeSummary[];
    colors: (string|undefined)[];
    reportWaitMax: number;
    links: {
        help: string;
        manual: string;
        movie: string;
    };
    invalidError: string;

    accessableLimit: number;
    accessableMargin: number;
    expirationLimit: number;
    
};

export const Occupations = {
    Administrators: 0,
    Nurses: 1,
    Carers: 2,
    Instructors: 3,
    Counselors: 4,
    Clerks: 5,
    Drivers: 6,
    Cooks: 7,
    Others: 8
} as const;
export type OccupationType = typeof Occupations[keyof typeof Occupations];

/** 固定値の定義 */
const Define: DefineType = {
    /** アプリケーションのID */
    appId: "rehaqum-0.0.0.1",
    /** アプリケーションのタイトル */
    appTitle: "Rehaqum(リハクム)",
    /** アプリケーションのロゴ */
    appIcon: "",
    /** 著作権情報等 */
    copyright: {
        reserved: "Copyright © 2023 meuxia Co.,Ltd.",
        supportTel: "052-999-9999",
        privacyPolicy: "https://softlinx.info/privacypolicy/",
        termsOfService: "https://softlinx.info/terms/"
    },
    /** 入力例 */
    placeholders: {
        "userId": "", "password": "", "passwordConfirm": "",
        "mail": "example@example.com", "code": "認証コードの有効期限は発行後30分間です",
        "lastName": "山田", "lastKana": "ヤマダ",
        "firstName": "太郎", "firstKana": "タロウ",
        "corporateName": "法人形態を除く会社名", "corporateKana": "法人名のカタカナ",
        "officeName": "事業所名", 
        "tel": "09011119999", "fax": "0521119999", "zip": "4600001",
        "addressMunicipalities": "市区町村及び丁目・番地・号など", 
        "addressDetails": "建物・ビル名や部屋番号など",        
    },

    /** リビジョン */
    revision: "revision.json",
    /** 選択データマスター */
    resource: "/settings/SelectionData.json",
    /** ヘルプメセージ */
    help: "/settings/helpitem.json",
    /** 和暦表 */
    jpChronology: "/settings/CalendarQuickReference.json",
    /** ５０音順 */
    jpSyllabary: [
        { value: 0, description: "全て", letters: [] },
        { value: 1, description: "ア", letters: ["ア","イ","ウ","エ","オ","ヴ"] },
        { value: 2, description: "カ", letters: ["カ","キ","ク","ケ","コ","ガ","ギ","グ","ゲ","ゴ"] },
        { value: 3, description: "サ", letters: ["サ","シ","ス","セ","ソ","ザ","ジ","ズ","ゼ","ゾ"] },
        { value: 4, description: "タ", letters: ["タ","チ","ツ","テ","ト","ダ","ヂ","ヅ","デ","ド"] },
        { value: 5, description: "ナ", letters: ["ナ","ニ","ヌ","ネ","ノ"] },
        { value: 6, description: "ハ", letters: ["ハ","ヒ","フ","ヘ","ホ","バ","ビ","ブ","ベ","ボ","パ","ピ","プ","ペ","ポ"] },
        { value: 7, description: "マ", letters: ["マ","ミ","ム","メ","モ"] },
        { value: 8, description: "ヤ", letters: ["ヤ","ユ","ヨ"] },
        { value: 9, description: "ラ", letters: ["ラ","リ","ル","レ","ロ"] },
        { value: 10, description: "ワ", letters: ["ワ","ヲ","ン"] },
    ],
     degreeOfCares: [{
        code: 1, description: "事業対象者"
    }, {
        code: 2, description: "要支援"
    }, {
        code: 3, description: "要介護"
    }],
    plannings: [{
        code: 1, description: "今月中に作成が必要"
    }, {
        code: 2, description: "来月末に作成が必要"
    }, {
        code: 3, description: "期限切れ(未作成)"
    }],
    assessments: [{
        code: 1, description: "今月中に評価が必要"
    }, {
        code: 2, description: "来月末に評価が必要"
    }, {
        code: 3, description: "期限切れ"
    }],
    planStatus: [{
        code: 1, description: "作成済"
    }, {
        code: 2, description: "未作成"
    }],
    oralStatus: [{
        code: 1, description: "対象者"
    }, {
        code: 2, description: "未算定"
    }, {
        code: 3, description: "未判定"
    }],
    colors: [undefined,"#ff9800","#ef5350"],

    /** レポート待ち最大秒数 */
    reportWaitMax: 20000,

    /** 外部リンク */
    links: {
        help: "https://help.softlinx.info/",
        manual: "https://help.softlinx.info/manual/",
        movie: "https://help.softlinx.info/video-contents/"
    },

    /** 入力規則エラー */
    invalidError: "上記必須項目が未入力、または入力フォーマット/入力規則の誤った 入力項目があります。",

    /** アクセストークンの有効期間 */
    accessableLimit: 1000 * 60 * 60,
    /** アクセストークンの有効期限の余白(調整値) */
    accessableMargin: 1000 * 60 * 1,
    /** リフレシュトークンの有効期間 */
    expirationLimit: 1000 * 60 * 60 * 24,

    /* TODO:ここにアプリケーション固有の値を設定していく */
};
export default Define;