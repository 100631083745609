import Rest from "./Rest";
import { InputCandidate, InputCandidateDetails } from "../types/Memo";

const ServerOptions = {
    receipt: 0x10,
} as const;
//type ServerOption = typeof ServerOptions[keyof typeof ServerOptions];

export interface OfficeInfo {
    id: number;
    name: string;
    bizType: number;
    dataPreserve: boolean;
    adlPersonalized: boolean;
    availableReceipt: boolean;
    physicalPlan: boolean;
}

type ApiOfficeInfo = {
    officeId?: number,
    officeName?: string,
    modeOfOperation?: number,
    isReferenceOnly?: boolean,
    isAdlMainteAdd?: boolean,
    serviceOption?: number;
    isImproveplanDocument?: boolean,
};

const toOfficeInfo = (data: ApiOfficeInfo): OfficeInfo => ({
    id: data.officeId!,
    name: data.officeName!,
    bizType: data.modeOfOperation!,
    dataPreserve: data.isReferenceOnly ?? false,
    adlPersonalized: data.isAdlMainteAdd ?? false,
    availableReceipt: ((data.serviceOption ?? 0) & ServerOptions.receipt) > 0,
    physicalPlan: data.isImproveplanDocument ?? false,
});

const getUserOffices = async (accessToken: string, signal?: AbortSignal): Promise<OfficeInfo[]> => {
    const data = await Rest.get<ApiOfficeInfo[]>({
        url: "/api/screen_com/offices",
        authorization: accessToken,
        signal: signal,
    }) as ApiOfficeInfo[];
    return data.map(value => toOfficeInfo(value));
};

export interface MemoContent {
    index: number,
    content: string,
}

const getUserMemos = async (accessToken: string, officeId: number): Promise<MemoContent[]> => {
    const data = await Rest.get<string[]>({
        url: `/api/screen_com/memo/${officeId}`,
        authorization: accessToken
    }) as string[];
    return data.map((value, index) => ({
        index: index + 1,
        content: value,
    }));
};

type ApiCandidate = {
    selectCode?: number,
    selectedItemContent?: string,
};

const getCandidates = async (accessToken: string, officeId: number, classCode: string): Promise<InputCandidate[]> => {
    const data = await Rest.get<ApiCandidate[]>({
        url: `/api/screen_com/input_candidates/${officeId}/${classCode}`,
        authorization: accessToken,
    }) as ApiCandidate[];
    return data.map(item => ({ code: item.selectCode, content: item.selectedItemContent }));
};

const setCandidate = async (accessToken: string, officeId: number, classCode: string, data: InputCandidate): Promise<void> => {
    const postData: ApiCandidate = { selectCode: data.code, selectedItemContent: data.content };
    await Rest.post<void>({
        url: `/api/screen_com/input_candidates`,
        authorization: accessToken,
        data: { ...postData, officeId: officeId, classCode: classCode },
        noWrap: true,
    });
};

type ApiCandidateDetails = ApiCandidate & {
    selectSubCode?: number,
    longAndShortCode?: number,
    auxiliaryContent1?: string,
    auxiliaryContent2?: string,
    auxiliaryContent3?: string,
    auxiliaryContent4?: string,
    auxiliaryContent5?: string,
};

const toCandidateDetails = (data: ApiCandidateDetails): InputCandidateDetails => ({
    code: data.selectCode,
    subCode: data.selectSubCode,
    isShortTerm: (data.longAndShortCode??1) === 2,
    content: data.selectedItemContent,
    complemente1: data.auxiliaryContent1,
    complemente2: data.auxiliaryContent2,
    complemente3: data.auxiliaryContent3,
    complemente4: data.auxiliaryContent4,
    complemente5: data.auxiliaryContent5,
});

const fromCandidateDetails = (data: InputCandidateDetails): ApiCandidateDetails => ({
    selectCode: data.code,
    selectSubCode: data.subCode,
    longAndShortCode: (data.ignoreTerm ?? false)? 0 : (data.isShortTerm??false) ? 2 : 1,
    selectedItemContent: data.content,
    auxiliaryContent1: data.complemente1,
    auxiliaryContent2: data.complemente2,
    auxiliaryContent3: data.complemente3,
    auxiliaryContent4: data.complemente4,
    auxiliaryContent5: data.complemente5,
});

const getCandidateDetails = async (accessToken: string, officeId: number, classCode: string, code: number, isShortTerm: boolean, ignoreTerm?: boolean): Promise<InputCandidateDetails[]> => {
    const data = await Rest.get<ApiCandidateDetails[]>({
        url: `/api/screen_com/input_candidate_subs/${officeId}/${classCode}`,
        authorization: accessToken,
        query: {
            SelectCode: code,
            LongAndShortCode: (ignoreTerm ?? false)? 0 : (isShortTerm ? 2 : 1)
        }
    }) as ApiCandidateDetails[];
    return data.map(item => toCandidateDetails(item));
};

const setCandidateDetails = async (accessToken: string, officeId: number, classCode: string, data: InputCandidateDetails): Promise<void> => {
    const postData = fromCandidateDetails(data);
    await Rest.post<void>({
        url: "/api/screen_com/input_candidate_subs",
        authorization: accessToken,
        data: {...postData, officeId: officeId, classCode: classCode},
        noWrap: true,
    });
};

export interface Disease {
    diseaseName?: string;
    icd?: string;
    receiptCode?: string;
};

export interface DiseaseClass {
    code?: string;
    className?: string;
    diseases?: Disease[];
};

type ApiDisease = {
    diseaseName?: string,
    icdCode?: string,
    receiptComputerCode?: string,
};
type ApiDiseaseClasses = {
    mains?: {
        classCode?: string,
        diseaseClassesName?: string,
    }[],
    subs?: {
        classCode?: string,
        names?: ApiDisease,
    }[],
};
const getDiseaseClasses = async (accessToken: string): Promise<DiseaseClass[]> => {
    const data = await Rest.get<ApiDiseaseClasses>({
        url: "/api/screen_com/disease_classes",
        authorization: accessToken,
    }) as ApiDiseaseClasses;
    return data.mains!.map(value => ({
        code: value.classCode,
        className: value.diseaseClassesName,
        diseases: data.subs?.filter(item => item.classCode === value.classCode).map(item => ({
            diseaseName: item.names?.diseaseName,
            icd: item.names?.icdCode,
            receiptCode: item.names?.receiptComputerCode,
        }))
    }));
};
const getDiseases = async (accessToken: string, diseasePattern: string): Promise<Disease[]> => {
    const data = await Rest.get<ApiDisease[]>({
        url: `/api/screen_com/disease_names/${diseasePattern}`,
        authorization: accessToken,
    }) as ApiDisease[];
    return data.map(item => ({
        diseaseName: item.diseaseName,
        icd: item.icdCode,
        receiptCode: item.receiptComputerCode,
    }));
};


const Common = {
    getUserOffices, getUserMemos, 
    getCandidates, setCandidate, 
    getCandidateDetails, setCandidateDetails,
    getDiseaseClasses, getDiseases
};
export default Common;