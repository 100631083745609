import React, { useContext } from "react";
import { FieldValues, useFormContext, Path } from "react-hook-form";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

import { MessageContext } from "../providers/MessageProvider";

export interface EditingDetectionProps<TForm> {
    id: string;
    value?: boolean;
    onChange?: (value: boolean, name?: Path<TForm>) => void;
};

const EditingDetection = <TForm extends FieldValues>(props: EditingDetectionProps<TForm>) => {
    const {watch} = useFormContext<TForm>();
    React.useEffect(() => {
        const subscription = watch((values, info) => {
            const now = (new Date()).toLocaleString();
            const value = Object.entries(values).find(item => item[0] === info.name)?.[1];
            console.log(now, {...info, value, valueType: typeof value});
            props.onChange! && props.onChange(true, info.name);
        });
        return () => subscription.unsubscribe();
    }, [props, watch]);
    return <input type="hidden" id={props.id} className="editing" data-value={props.value ?? false}/>;
};
export default EditingDetection;

export const getEditing = () => {
    const editing = document.querySelector(".editing") as HTMLInputElement;
    return JSON.parse(editing?.dataset.value ?? "false") as boolean;
};

export const useLinkTo = () => {
    const navigate = useNavigate();
    const {showMessage} = useContext(MessageContext);

    const to = (to: To, options?: NavigateOptions) => {
        const editing = getEditing();
        if (editing) {
            showMessage({
                type: "warning", ask: true, title: "編集された情報の破棄",
                description: ["保存されていない編集された情報を破棄します。", "よろしいですか？"],
                onClose: yes => { yes && navigate(to, options); }
            });
            return;
        }
        navigate(to, options);
    };
    return to;
};