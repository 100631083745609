import React from "react";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLinkTo } from "./EditingDetection";
import { useUser } from "../providers/UserProvider";

export const AccessFilters = {
    PhysicalPlan: 0x1,
} as const;

/** メニュー項目パラメーター */
export interface MenuItemProps {
    path?: string | null,
    icon?: React.ReactNode | null;
    name: string;
    menuKey: number;
    description?: string;
    items?: MenuItemProps[] | null;
    current?: string;

    accessFilter?: number;
};

/** Linkメニュー */
const LinkMenuItem = (props: MenuItemProps) => {
    const { path, current, icon, name } = props;
    const linkTo = useLinkTo();
    const handleTo = React.useCallback(() => linkTo(path!), [linkTo, path]);
    const active = React.useMemo(() => current === path || (path !== "/" && current?.startsWith(path!)), [path, current]);
    const className = React.useMemo(() => ["Selectable-item", (active ? "Selected-item" : undefined)].filter(name => name!).join(" "), [active]);
    
    return (
        <ListItemButton className={className} sx={{cursor: "pointer"}} onClick={handleTo}>
            <ListItemIcon>{icon && (icon)}</ListItemIcon>
            <ListItemText primary={name} />
        </ListItemButton>
    );
};

/** 展開メニュー */
const ExpandMenuItem = (props: MenuItemProps & {filter: number}) => {
    const { path, current, icon, name, items, filter } = props;
    const { user } = useUser();
    const [open, setOpen] = React.useState(false);
    const handleClick = React.useCallback(() => setOpen(value => !value), []);
    const active = React.useMemo(() => current === path, [path, current]);
    const className = React.useMemo(() => ["Selectable-item", (active ? "Selected-item" : undefined)].filter(name => name!).join(" "), [active]);

    const displayItems = React.useMemo(() => {
        return items?.filter(item  => { 
            const physical = ((item.accessFilter ?? 0) & AccessFilters.PhysicalPlan) === AccessFilters.PhysicalPlan;
            if (physical && !(user?.availablePhysicalPlan ?? false)) {
                return false;
            }
            return (item.menuKey & filter) === item.menuKey;
        }) ?? [];
    }, [user, items, filter]);

    return (
        <>
            <ListItemButton className={className} onClick={handleClick}>
                <ListItemIcon>{icon! && (icon)}</ListItemIcon>
                <ListItemText primary={name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                {displayItems.map(item => <MenuItem key={item.path!.replaceAll("/", "-")} {...item} filter={props.filter} current={props.current} />)}
                </List>
            </Collapse>
        </>
    );
};

/** メニュー項目 */
const MenuItem = (props: MenuItemProps & {filter: number}) => {
    const menuItem = (props.items && props.items!.length > 0
        ? <ExpandMenuItem {...props}/>
        : <LinkMenuItem {...props} />);
    return menuItem;
};

/** メニューリスト */
const SubMenu = (props: { category: string | React.ReactNode, items?: MenuItemProps[] | null, filter: number, current?: string }) => {
    const filtered = props.items!.filter(item => (item.menuKey & props.filter) > 0);
    return (
        <List subheader={<ListSubheader>{props.category}</ListSubheader>}>
        {filtered.map(item => <MenuItem key={item.path!.replaceAll("/", "-")} {...item} filter={props.filter} current={props.current}/>)}
        </List>
    );
};
export default SubMenu;