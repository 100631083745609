import { ArrowDropDown, ArrowDropUp, EditOutlined, MailOutlined, DeleteOutlined } from "@mui/icons-material";
import { Box, IconButton, Pagination, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";


export interface DataColumn<T> {
    field: string;
    header: string;
    width: number;
    sortable?: boolean;
    defaultSort?: boolean;
    headerAlign?: "left" | "center" | "right";
    contentAlign?: "left" | "center" | "right";
    mailTo?: boolean;
    getDisableMailTo?: (item: T) => boolean;
    action?: boolean;
    getAction?: (item: T) => boolean;
    disabledAction?: boolean;
    editAction?: boolean;
    deleteAction?: boolean;
    getTextColor?: (item: T) => string | undefined;
    getFieldText?: (item: T) => string;
    getFieldContent?: (item: T) => React.ReactNode;
    getHeaderContent?: () => React.ReactNode;
    onCompare?: (a: T, b: T, asc: boolean) => number;
}

export interface DataTableProps<T> {
    rows: T[];
    columns: DataColumn<T>[];
    updateOnPageReset?: boolean;
    pageSize: number;
    pagination?: boolean;
    paginationLayout?: "Top" | "Bottom" | "Both";
    selectable?: boolean;
    selectItem?: T | null;
    stickyHeader?: boolean;
    minHeight?: number;
    maxHeight?: number;
    minWidth?: number;
    gridLine?: boolean;
    rowHeight?: string;
    onCellClick?: (row: T, rowIndex: number, columnIndex?: number) => void;
    onSelect?: (row: T, rowIndex: number, columnIndex?: number) => void;
    onEdit?: (row: T, rowIndex: number, columnIndex?: number) => void;
    onDelete?: (row: T, rowIndex: number, columnIndex?: number) => void;
    noDataBoundary?: React.ReactNode;
    onSortChange?: (column: string, ascending: boolean) => void;
    display?: boolean;
};

const DataTable = <T extends object>(props: DataTableProps<T>) => {
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState<DataColumn<T> | null>(props.columns.find(column => column.defaultSort ?? false) ?? null);
    const [orderAsc, setOrderAsc] = React.useState(true);
    const pageCount = Math.floor(props.rows.length / props.pageSize) + ((props.rows.length % props.pageSize) > 0 ? 1 : 0); 
    const [items, setItems] = React.useState<T[]>([]);

    const handleColumnClick = (column: DataColumn<T>) => {
        const columnSortable = (column.sortable ?? false) && column.onCompare !== undefined;
        if (columnSortable) {
            let ascending = true;
            if (sortColumn! && sortColumn.field === column.field) {
                ascending = !orderAsc;
                setOrderAsc(ascending);
            } else {
                setSortColumn(column);
                setOrderAsc(ascending);
            }
            props.onSortChange! && props.onSortChange(column.field, ascending);
        }
    };

    const handleCellClick = (row: number, column: number, rowItem: T) => {
        if (props.selectable! && props.selectable && props.onSelect!) {
            props.onSelect(rowItem, row, column);
        } else if (props.onCellClick!) {
            props.onCellClick(rowItem, row, column);
        }
    };

    const handleMailTo = (value: string) => {
        window.location.href = `mailto:${value}`;
    };

    const getPageItems = React.useCallback((pageMax: number, page: number, orderAsc: boolean, sortColumn: DataColumn<T> | null): T[] => {
        let items = [...props.rows];
        if (sortColumn !== null && sortColumn.onCompare!) {
            items = items.sort((a, b) => sortColumn.onCompare!(a, b, orderAsc));
        }
        if (pageMax > 0) {
            const index = page > 0 ? page - 1 : 0;
            const offset = index * props.pageSize;
            const remaining = items.length - offset;
            const count = remaining > props.pageSize ? props.pageSize : remaining;
            items = items.slice(offset, offset + count);
        }
        return items;
    }, [props.rows, props.pageSize]);

    React.useLayoutEffect(() => {
        if ((props.updateOnPageReset ?? true)) {
            setPage(1);
        }
    }, [props.rows, props.pageSize, props.updateOnPageReset]);

    React.useLayoutEffect(() => {
        setItems(getPageItems(pageCount, page, orderAsc, sortColumn));
    }, [pageCount, setItems, page, sortColumn, orderAsc, getPageItems]);

    const rowSx: SxProps = {
        height: props.rowHeight ?? "2.5rem",
        "& td:nth-of-type(n + 2)": {
            borderLeftColor: "#aaa",
            borderLeftStyle: "solid",
            borderLeftWidth: (props.gridLine! && props.gridLine) ? 1 : 0
        }
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ mt: "5px", mb: "5px", minHeight: (props.minHeight!) ? props.minHeight : "auto", display: !(props.display??true) ? "none": undefined}}>
            {items.length === 0 && (props.noDataBoundary)}
            {(props.pagination ?? false) && ["Top", "Both"].includes((props.paginationLayout ?? "Both")) && (
                <Box sx={{ mb: 1, textAlign: "right", height: "1.5rem" }}>
                    {pageCount > 1 && 
                    <Pagination count={pageCount} page={page} size="small" shape="rounded" sx={{display: "inline-block"}} onChange={(e, value) => setPage(value)} />}
                </Box>
            )}
            <TableContainer sx={{flex: "auto", maxHeight: props.maxHeight, overflow: "auto"}}>
                <Table 
                style={{tableLayout: "fixed"}} 
                stickyHeader={(items.length > 0 && props.stickyHeader! ? props.stickyHeader : false)} 
                size="small" 
                sx={{border: "1px solid #aaa", minWidth: props.minWidth ?? "default"}}>
                    <TableHead>
                        {items.length > 0 && <TableRow key={0} sx={{
                            height: props.rowHeight ?? "3rem",
                        "& th:nth-of-type(n + 2)": {
                            borderLeftColor: "#aaa",
                            borderLeftStyle: "solid",
                            borderLeftWidth: (props.gridLine! && props.gridLine) ? 1 : 0
                        }}}>
                            {props.columns.map((column, index) => (
                                <TableCell key={index} variant="head" align={column.headerAlign ?? "left"} width={column.width}
                                sx={{
                                    fontSize: "1rem",
                                    width: column.width,
                                    minWidth: column.width,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    cursor: ((column.sortable ?? false) && column.onCompare!) ? "pointer" : "default",
                                    backgroundColor: ((column.sortable ?? false) && column.onCompare!) ? "#ddd" : "#eee",
                                }}
                                onClick={() => {
                                    if ((column.sortable ?? false) && column.onCompare!) {
                                        handleColumnClick(column);
                                    }
                                }}>
                                    <Box display="inline-flex" alignItems="center">
                                        {column.getHeaderContent! && column.getHeaderContent()}
                                        {column.header}
                                        {sortColumn! && column.field === sortColumn.field && (orderAsc ? <ArrowDropDown/> : <ArrowDropUp/>)}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>}
                    </TableHead>
                    <TableBody>
                        {items.map((item, rowIndex) => (
                            <TableRow className={["Alternate-item", ((props.selectable ?? false) ? "Selectable-item" : undefined), (props.selectItem === item ? "Selected-item" : undefined)].filter(name => name!).join(" ")} key={rowIndex} sx={rowSx}>
                                {props.columns.map((column, colIndex) => (
                                    <TableCell key={`${rowIndex}-${colIndex}`} align={column.contentAlign ?? "left"} sx={{
                                        width: column.width, 
                                        fontSize: "1rem", 
                                        minWidth: column.width,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        color: column.getTextColor! ? column.getTextColor(item) : undefined
                                        }} onClick={() => handleCellClick(rowIndex, colIndex, item)}>
                                        {((column.getAction! && column.getAction(item)) || (column.action ?? false)) && (column.editAction ?? false) && (
                                            <IconButton size="small" color="success" disabled={column.disabledAction??false} onClick={e => {
                                                e.stopPropagation();
                                                props.onEdit! && props.onEdit(item, rowIndex);
                                            }}>
                                                <EditOutlined fontSize="small"/>
                                            </IconButton>
                                        )}
                                        {((column.getAction! && column.getAction(item)) || (column.action ?? false)) && (column.deleteAction ?? false) && (
                                            <IconButton size="small" color="error" disabled={column.disabledAction??false} onClick={e => {
                                                e.stopPropagation();
                                                props.onDelete! && props.onDelete(item, rowIndex);
                                            }}>
                                                <DeleteOutlined fontSize="small"/>
                                            </IconButton>
                                        )}
                                        {column.mailTo! && column.mailTo && (
                                            <IconButton size="small" color="info" disabled={(column.getDisableMailTo! ? column.getDisableMailTo(item) : false)} onClick={e => {
                                                e.stopPropagation();
                                                handleMailTo(Object.getOwnPropertyDescriptor(item, column.field)?.value);                                        
                                            }}>
                                                <MailOutlined fontSize="small"/>
                                            </IconButton>
                                        )}
                                        {column.getFieldContent! && column.getFieldContent(item)}
                                        {column.getFieldContent === undefined && (column.getFieldText! ? 
                                        column.getFieldText(item) : 
                                        Object.getOwnPropertyDescriptor(item, column.field)?.value)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.pagination ?? false) && ["Bottom", "Both"].includes((props.paginationLayout ?? "Both")) && (
                <Box sx={{ mt: 1, textAlign: "right", height: "1.5rem" }}>
                    {pageCount > 1 && 
                    <Pagination count={pageCount} page={page} size="small" shape="rounded" sx={{display: "inline-block"}} onChange={(e, value) => setPage(value)} />}
                </Box>
            )}
        </Box>
    );
};
export default DataTable;