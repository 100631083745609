import React from "react";

import { useLocation } from "react-router-dom";

import { useTheme, Breadcrumbs as MuiBreadcrumbs, Link as MuiLink } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

import User from "../../types/User";
import { useLinkTo } from "./EditingDetection";

/** パンくずリスト */
const Breadcrumbs = (props: { map: { [key: string]: string }, user: User }) => {
    const theme = useTheme();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);
    const linkTo = useLinkTo();
    const items = pathNames.map((value, index) => {
        const last = index === pathNames.length - 1;
        const to = `/${pathNames.slice(0, index + 1).join('/')}/`;
        return (last
            ? <span key={to}>{props.map[to]}</span>
            : <MuiLink key={to} onClick={() => linkTo(to)} sx={{cursor: "pointer"}} underline="hover" color="inherit">{props.map[to]}</MuiLink>);
    });
    return items.length > 1 ? (
        <MuiBreadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb"
        sx={{
            position: "sticky", top: 0, 
            p: 2, pt: 1, pb: 1, 
            zIndex: 100,
            backgroundColor: theme.palette.background.paper, 
            opacity: 1
        }}>{items}</MuiBreadcrumbs>
    ) : <></>;
};
export default Breadcrumbs;