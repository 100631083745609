import React from "react";

import { TextField, Typography, FormControlLabel, Checkbox, Link, Button, Box, Tooltip } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import InputSignupForm, { InputMailForm, InputMailSchema, SignupStepProps } from "./types";
import Define from "../../../configurations/Define";
import Register from "../../../services/Register";
import { getErrorMessage } from "../../../services/Rest";
import { MessageContext } from "../../providers/MessageProvider";
import EnhancedForm from "../../parts/EnhancedForm";

const InputMail = (props: SignupStepProps) => {
    const { data, onNext } = props;
    const { showMessage } = React.useContext(MessageContext);
    const { register, handleSubmit, setFocus, formState: { errors } } = useForm<InputMailForm>({
        mode: "onBlur",
        resolver: yupResolver(InputMailSchema),
        defaultValues: { ...data.corporate }
    });
    const [agree, setAgree] = React.useState(false);
    const handleAgree = React.useCallback(() => setAgree(current => !current), []);

    const callNext = React.useCallback((formData: InputSignupForm) => onNext! && onNext(formData), [onNext]);

    const onSubmit = React.useCallback((formData: InputMailForm) => {
        Register.mailRequest(formData.mail!).then(() => {
            callNext({...data, corporate:{ mail: formData.mail }});
        }).catch(error => getErrorMessage(error).then(message => showMessage({
            type: "error",
            title: "通知先のメールアドレス", 
            description: ["案内メール送信の要求に失敗しました。", message.message],
            onClose: () => setFocus("mail")
        })));
    }, [data, callNext, showMessage, setFocus]);

    const [viewPolicy, setViewPolicy] = React.useState(false);
    const handlePrivacyPolicy = React.useCallback(() => !viewPolicy && setViewPolicy(true), [viewPolicy]);

    return (
        <EnhancedForm onSubmit={handleSubmit(onSubmit)} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>通知先のメールアドレス</Typography>
            <Box flexGrow={1} sx={{ p: 3 }}>
                <TextField label="メールアドレス" className="Mui-required" 
                    {...register("mail")}
                    sx={{ mt: 2, mb: 2 }}
                    autoFocus fullWidth
                    placeholder={Define.placeholders["mail"]}
                    error={"mail" in errors}
                    helperText={errors.mail?.message} />
                <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                    {"取得した個人情報を、弊社が定める「"}
                    <Link target="_policy" href={Define.copyright.privacyPolicy} onClick={handlePrivacyPolicy}>個人情報の取扱い</Link>
                    {"」に従って適正に保管します。個人情報に関する取り扱いに同意のうえ、申し込みください。"}
                </Typography>
                <Tooltip placement="bottom" arrow title={viewPolicy ? "":"個人情報の取扱いのリンクを開くことで有効化します。"}>
                    <FormControlLabel label="「個人情報の取扱い」についてに同意します。" disabled={!viewPolicy} control={<Checkbox checked={agree} onChange={handleAgree} />} />
                </Tooltip>
            </Box>            
            <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ p: 1 }}>
                <Button type="submit" variant="contained" color="primary" disabled={!agree}>次へ</Button>
            </Box>
        </EnhancedForm>
    );
};
export default InputMail;