import React from "react";

import { TextField, Typography, Button, Box, Grid, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { SignupStepProps } from "./types";
import { InputUserForm, InputUserSchema } from "../../../types/User";
import Define from "../../../configurations/Define";
import EnhancedForm from "../../parts/EnhancedForm";

const InputOffer = (props: SignupStepProps) => {
    const { register, handleSubmit, formState: { errors } } = useForm<InputUserForm>({
        mode: "onBlur",
        resolver: yupResolver(InputUserSchema),
        defaultValues: props.data.user
    });

    const [visiblePassword, setVisiblePassword] = React.useState(false);
    const handleVisiblePassword = () => setVisiblePassword(!visiblePassword);
    const [visiblePassword2, setVisiblePassword2] = React.useState(false);
    const handleVisiblePassword2 = () => setVisiblePassword2(!visiblePassword2);

    const handleBack = () => {
        if (props.onBack !== undefined) {
            props.onBack();
        }
    };
    const onSubmit = (data: InputUserForm) => {
        if (props.onNext !== undefined) {
            props.onNext({...props.data, ...{user: data}});
        }
    };

    const today = new Date();
    const beginOfMonth = new Date();
    beginOfMonth.setDate(1);
    let month = beginOfMonth.getMonth() + 2, year = beginOfMonth.getFullYear();
    if (month > 12) {
        month -= 12;
        year += 1;
    }
    beginOfMonth.setFullYear(year);
    beginOfMonth.setMonth(month);
    const freeLimit = new Date(beginOfMonth.getTime() - (1000 * 60 * 60 * 24));
    //new Date(beginOfMonth.getTime() + (1000 * 60 * 60 * 24 * 30));

    return (
        <EnhancedForm onSubmit={handleSubmit(onSubmit)} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>申込み情報</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <TextField label="申込み日" size="small" variant="outlined" fullWidth disabled InputProps={{ readOnly: true }} value={today.toLocaleDateString()} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="プラン" size="small" variant="outlined" fullWidth disabled InputProps={{ readOnly: true }} value={"無料プラン"} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="無料期間" size="small" variant="outlined" fullWidth disabled InputProps={{ readOnly: true }} value={freeLimit.toLocaleDateString()} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="ログインID" className="Mui-required"  
                    {...register("userId")}
                    fullWidth
                    placeholder={Define.placeholders["userId"]}
                    error={"userId" in errors}
                    helperText={errors.userId?.message} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="パスワード" className="Mui-required"  
                    {...register("password")}
                    fullWidth autoComplete="new-password"
                    placeholder={Define.placeholders["password"]}
                    type={visiblePassword ? "text" : "password"}
                    error={"password" in errors}
                    helperText={errors.password?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{cursor: "pointer"}} onClick={handleVisiblePassword}>
                                {visiblePassword ? <VisibilityOff /> : <Visibility />}
                            </InputAdornment>
                        )
                    }} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField label="パスワードの確認" className="Mui-required" 
                    {...register("passwordConfirm")}
                    fullWidth
                    placeholder={Define.placeholders["passwordConfirm"]}
                    type={visiblePassword2 ? "text" : "password"}
                    autoComplete="new-password"       
                    error={"passwordConfirm" in errors}
                    helperText={errors.passwordConfirm?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{cursor: "pointer"}} onClick={handleVisiblePassword2}>
                                {visiblePassword2 ? <VisibilityOff /> : <Visibility />}
                            </InputAdornment>
                        )
                    }} />
                </Grid>
            </Grid>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
                <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ p: 1 }}>
                    <Button variant="contained" onClick={handleBack}>戻る</Button>
                    <Button type="submit" variant="contained" color="primary">次へ</Button>
                </Box>
            </Box>
        </EnhancedForm>
    );
};
export default InputOffer;