import React from "react";

import { SignupStepProps } from "./types";
import Corporate from "../../../types/Corporate";
import CorporateEdit from "../../parts/CorporateEdit";

const InputCorporate = (props: SignupStepProps) => {
    const handleUpdate = (data: Corporate) => props.onNext! && props.onNext({...props.data, ...{corporate: data}});
    return <CorporateEdit data={props.data.corporate} updateName="次へ" onUpdate={handleUpdate} />; 
};

export default InputCorporate;