import { createTheme } from "@mui/material/styles";

/** 赤系のテーマ */
const red = {
    /** テーマのメインカラー */
    mainColor: "#ff1e46",
    /** テーマの第2カラー */
    secondaryColor: "#de3cfb",
    /** テーマの背景色 */
    backgroundCOlor: "#fafafa", 

    /** 必須項目枠の色 */
    form_requireColor: "#e90",

    /** 読み取り専用のテキスト内部の色 */
    input_readOnlyColor: "#eee",

    /** タブの背景色 */
    tab_backColor: "rgba(255, 30, 70, 0.05)",
    /** タブの前景色 */
    tab_foreColor: "#f22",
    /** 選択されたタブの背景色 */
    tab_selected_BackColor: "#ff1e46",
    /** 選択されたタブの前景色 */
    tab_selected_ForeColor: "#fff",
    /** タブのカーソルホバー時の背景色 */
    tab_hover_BackColor: "#ff8eb6",
    /** タブのカーソルホバー時の前景色 */
    tab_hover_ForeColor: "#f88",
    /** 選択されたタブのカーソルホバー時の前景色 */
    tab_selected_hover_ForeColor: "#eee",

    /** リスト項目のカーソルホバー時の背景色 */
    selectable_hover_BackColor: "#fee",
    /** リスト項目の選択時の背景色 */
    selectable_selected_BackColor: "#fee",

    /** テーブル行のカーソルホバー時の背景色 */
    row_selectable_hover_BackColor: "#fee",
    /** テーブル行の選択時の背景色 */
    row_selectable_selected_BackColor: "#f7dcd7",
    /** テーブル行の交互(偶数)の背景色 */
    row_alternate_BackColor: "#fff4f4",

    /** スクロールTOPの背景色 */
    scrollTop_BackColor: "#888",
    /** スクロールTOPの前景色 */
    scrollTop_ForeColor: "#fff",
    /** スクロールTOPのカーソルホバー時の背景色 */
    scrollTop_hover_BackColor: "#ff1e46",
    /** スクロールTOPのカーソルホバー時の前景色 */
    scrollTop_hover_ForeColor: "#fff",

    /** 読み取り専用時の前景色 */
    readOnly_ForeColor: "#888",
};
/** 緑系のテーマ */
const green = {
    /** テーマのメインカラー */
    mainColor: "#484",
    /** テーマの第2カラー */
    secondaryColor: "#698",
    /** テーマの背景色 */
    backgroundCOlor: "#fafafa",

    /** 必須項目枠の色 */
    form_requireColor: "#e90",

    /** 読み取り専用のテキスト内部の色 */
    input_readOnlyColor: "#eee",

    /** タブの背景色 */
    tab_backColor: "rgba(30, 255, 70, 0.05)",
    /** タブの前景色 */
    tab_foreColor: "#282",
    /** 選択されたタブの背景色 */
    tab_selected_BackColor: "#484",
    /** 選択されたタブの前景色 */
    tab_selected_ForeColor: "#fff",
    /** タブのカーソルホバー時の背景色 */
    tab_hover_BackColor: "#698",
    /** タブのカーソルホバー時の前景色 */
    tab_hover_ForeColor: "#8f8",
    /** 選択されたタブのカーソルホバー時の前景色 */
    tab_selected_hover_ForeColor: "#eee",

    /** リスト項目のカーソルホバー時の背景色 */
    selectable_hover_BackColor: "#efe",
    /** リスト項目の選択時の背景色 */
    selectable_selected_BackColor: "#dfd",

    /** テーブル行のカーソルホバー時の背景色 */
    row_selectable_hover_BackColor: "#efe",
    /** テーブル行の選択時の背景色 */
    row_selectable_selected_BackColor: "#dfd",
    /** テーブル行の交互(偶数)の背景色 */
    row_alternate_BackColor: "#f4fff4",

    /** スクロールTOPの背景色 */
    scrollTop_BackColor: "#888",
    /** スクロールTOPの前景色 */
    scrollTop_ForeColor: "#fff",
    /** スクロールTOPのカーソルホバー時の背景色 */
    scrollTop_hover_BackColor: "#484",
    /** スクロールTOPのカーソルホバー時の前景色 */
    scrollTop_hover_ForeColor: "#fff",

    /** 読み取り専用時の前景色 */
    readOnly_ForeColor: "#888",
};
/** 利用するテーマを設定する。 */
const usingTheme = true ? red : green;

/** アプリケーションで利用するテーマを作成します。 */
export const createAppTheme = () => createTheme({
    /** MUIを拡張する部分のCSS設定を記述する。 
    https://bareynol.github.io/mui-theme-creator/
    MUIのバージョンが古いので、一部プロパティ名等を変更する必要があるが、大まかには同じなので、 
    デザインツールとして利用して、サイト右上のコードの[ThemeOptions]をコピーすると便利 */
    palette: {
        mode: "light",
        primary: {
            main: usingTheme.mainColor,
        },
        secondary: {
            main: usingTheme.secondaryColor,
        },
        background: {
            default: usingTheme.backgroundCOlor,
        }
    },


    /** 以下の部分はMUIコンポーネントのデフォルト設定とスタイルの上書き */
    components: {
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    "&.Mui-required .MuiInputLabel-root::after": {
                        fontFamily: "\"ヒラギノ角ゴ ProN W3\",\"Hiragino Kaku Gothic ProN\",\"メイリオ\",Meiryo,sans-serif",
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        content: '" *"',
                        display: "inline-block",
                        paddingLeft: ".25rem",
                        paddingRight: ".25rem",
                    },
                    "&.Mui-required .MuiOutlinedInput-notchedOutline>legend": {
                        paddingRight: ".75rem"
                    }
                }
            }
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: "dense",
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    fontFamily: "\"ヒラギノ角ゴ ProN W3\",\"Hiragino Kaku Gothic ProN\",\"メイリオ\",Meiryo,sans-serif",
                    fontWeight: "bold",
                }
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
                margin: "dense",
                sx: { fontSize: "1.1rem" }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: "small",
                autoComplete: "off",
                notched: true,
                margin: "dense",
            },
            styleOverrides: {
                notchedOutline: {
                    ".Mui-required &": {
                        borderColor: usingTheme.form_requireColor,
                        borderWidth: "2px"
                    },
                    "legend": {
                        fontSize: "0.85rem"
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.MuiInputBase-readOnly": {
                        backgroundColor: usingTheme.input_readOnlyColor,
                    }
                }
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                size: "small"
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: "5.75rem"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: "150px",
                    border: "1px solid #ccc",
                    borderBottom: "none",
                    borderTopLeftRadius: ".25rem",
                    borderTopRightRadius: ".25rem",
                    backgroundColor: usingTheme.tab_backColor,
                    color: usingTheme.tab_foreColor,
                    fontSize: "1rem",
                    "&.Mui-selected": {
                        backgroundColor: usingTheme.tab_selected_BackColor,
                        color: usingTheme.tab_selected_ForeColor,
                        fontWeight: "bold"
                    },
                    "&:hover": {
                        backgroundColor: usingTheme.tab_hover_BackColor,
                        color: usingTheme.tab_hover_ForeColor,
                        fontWeight: "bold"
                    },
                    "&.Mui-selected:hover": {
                        color: usingTheme.tab_selected_hover_ForeColor,
                    },
                    "&:nth-of-type(n+2)": {
                        marginLeft: "2px"
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: "1rem"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: "2rem",
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    "&.Msg-box.Msg-autoClose": {
                        fontWeight: "bold",
                        p: 2,
                    }
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Selectable-item.Selected-item": {
                        backgroundColor: usingTheme.selectable_selected_BackColor,
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&.Alternate-item:nth-of-type(even)": {
                        backgroundColor: usingTheme.row_alternate_BackColor,
                    },
                    "&.Selectable-item": {
                        cursor: "pointer",
                    },
                    "&.Selectable-item:hover": {
                        backgroundColor: usingTheme.row_selectable_hover_BackColor,
                    },
                    "&.Selectable-item.Selected-item": {
                        backgroundColor: usingTheme.row_selectable_selected_BackColor,
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&.To-top-button": {
                        backgroundColor: usingTheme.scrollTop_BackColor,
                        color: usingTheme.scrollTop_ForeColor,
                    },
                    "&.To-top-button:hover": {
                        backgroundColor: usingTheme.scrollTop_hover_BackColor,
                        color: usingTheme.scrollTop_hover_ForeColor,
                    },
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&.Radio-readOnly": {
                        pointerEvents: "none",
                    },
                    "&.Radio-readOnly *": {
                        pointerEvents: "none",
                        color: usingTheme.readOnly_ForeColor,
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "&.Radio-readOnly": {
                        pointerEvents: "none",
                    },
                    "&.Radio-readOnly *": {
                        pointerEvents: "none",
                        color: usingTheme.readOnly_ForeColor,
                    },
                    "&.Check-readOnly": {
                        pointerEvents: "none",
                    },
                    "&.Check-readOnly *": {
                        pointerEvents: "none",
                        color: usingTheme.readOnly_ForeColor,
                    },
                }
            }
        },
    }
});