import React from "react";

import { Controller, FieldValues } from "react-hook-form";
import { TextField } from "@mui/material";

import { ControlledFieldProps, FieldProps, OmittedControllerProps } from "./types";
import Utility from "../../../services/Utility";

const TextBox = <TForm extends FieldValues>(props: ControlledFieldProps<TForm>) => {
    const controllerProps: OmittedControllerProps<TForm> = {...props};
    const required = props.required ?? false;
    const className = required ? "Mui-required" : "";
    const fieldProps: FieldProps = {
        ...props, 
        required: false, 
        InputProps: {
            ...props.InputProps, 
            readOnly: props.readOnly
        }
    };

    return (
        <Controller {...controllerProps} render={({field: {value, ...rest}, fieldState: {error}}) => (
            <TextField className={className}
            {...rest} {...fieldProps} value={Utility.getValueOrUndefined(value) ?? ""}
            error={!!error?.message} helperText={error?.message} />
        )}/>
    );
};
export default TextBox;