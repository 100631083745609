import Rest from "./Rest";

import Corporate from "../types/Corporate";
import Office from "../types/Office";
import { UserSign } from "../types/User";

/** 案内メール要求 */
const mailRequest = (mail: string) => {
    return Rest.post<void>({ 
        url: "/public_api/free_trial/email_send", 
        noWrap: true, 
        data: mail 
    });
}

/** 認証コード確認 */
const authorization = (mail: string, code: string) => {
    return Rest.post<void>({ 
        url: "/public_api/free_trial/auth_code_check", 
        noWrap: true, 
        data: {
            emailAddress: mail, 
            authenticationCode: code 
        }
    });
};

/** 実行 */
const execute = (corporate: Corporate, office: Office, user: Omit<UserSign, "corporate">, timeout?: number) => {
    return Rest.post<void>({
        url: "/public_api/free_trial/apply", 
        noWrap: true, 
        wait: timeout ?? (60 * 1000),
        data: {
            corporation: {
                corporationCode: "",
                corporationName: corporate.name,
                corporationNameKana: corporate.kana,
                personName: corporate.picName,
                personNameKana: corporate.picKana,
                personPosition: corporate.picPost,
                corporationForm: corporate.type,
                frontOrRear: corporate.typeOffset,
                phoneNumber: corporate.tel,
                emailAddress: corporate.mail,
                isReceiveNewsEmail: corporate.notify,
                postCode: corporate.zip,
                prefectureCode: corporate.addressPref,
                address1: corporate.addressMunicipalities,
                address2: corporate.addressDetails,
            },
            office: {
                corporationCode: "",
                officeId: 1,
                officeManagerId: 0,
                officeName: office.name,
                postCode: office.zip,
                prefectureCode: office.addressPref,
                address1: office.addressMunicipalities,
                address2: office.addressDetails,
                phoneNumber: office.tel,
                emailAddress: office.mail,
                faxNumber: office.fax,
                isReceiveNewsEmail: corporate.notify,
                capacity: office.employeeCount,
                modeOfOperation: office.type,
                dayCareplanLongTargetPeriod: 0,
                dayCareplanShortTargetPeriod: 0,
                improveplanEvaluate: 0,
                trainingplanLongTargetPeriod: 0,
                trainingplanShortTargetPeriod: 0,
                trainingplanEvaluate: 0,
                physicalFuncCheck: 0
            },
            login: {
                loginUser: user.userId,
                password: user.password
            }
        }
    });
};

/** ユーザー(無料体験)登録 */
const Register = {
    mailRequest, authorization, execute
} as const;
export default Register;