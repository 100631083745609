import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import Define from "../../configurations/Define";

const Footer = () => {
    return (
        <Box sx={{ zIndex: 100, backgroundColor: "#666", color: "#fff", boxShadow: "0 0 3px #000"}}>
                <Box display="flex" sx={{
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: { xs: "flex-start", md: "space-between" },
                    alignItems: { xs: "flex-start", md: "center" }
                }}>
                    <Box sx={{display: { xs: "none", md: "initial" }, width: 200}}></Box>
                    <Grid container spacing={1} sx={{width: 300, mt: 1, mb: 1, textAlign: {xs: "left", md: "center"}}}>
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                <Link color="#fff" target="_terms" href={Define.copyright.termsOfService} sx={{mr: 1}}>利用規約</Link>
                                <Link color="#fff" target="_policy" href={Define.copyright.privacyPolicy}>プライバシーポリシー</Link>
                            </Typography>
                        </Grid>                        
                        <Grid item xs={12}>
                            <Typography variant="body2">{Define.copyright.reserved}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ flexDirection: {xs: "row", md: "column"}, width: 200, textAlign: "center"}}>
                        <Grid item>
                            <Typography variant="body2">サポートセンター</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">{Define.copyright.supportTel}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            
        </Box>
    );
};
export default Footer;