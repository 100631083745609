import { Alert, AlertColor, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import Utility from "../../services/Utility";

export type MessageType = AlertColor;
export interface Message {
    ask?: boolean;
    type?: MessageType;
    title: string;
    description: string[];
    fatal?: boolean;
    onClose?: (yes?: boolean) => void;
    autoCloseWait?: number;
}

export type MessageModel = { 
    showMessage: (message: Message) => void, 
    showAutoClose: (message: Message) => void,
};

export const MessageContext = React.createContext<MessageModel>({showMessage: () => {}, showAutoClose: () => {}});

const MessageProvider = (props: { children: React.ReactNode }) => {
    const [message, setMessage] = React.useState<Message>();
    const [shown, setShown] = React.useState(false);
    const [autoClose, setAutoClose] = React.useState(false);
    const showMessage = (message: Message) => {
        setMessage(message);
        setShown(true);
    };

    const handleClose = (yes?: boolean) => {
        Utility.sleep(100).then(() => message! && message.onClose! && message.onClose(yes));
        
        setShown(false);
        setAutoClose(false);
        setMessage(undefined);
    };
    const showAutoClose = (message: Message) => {
        setMessage(message);
        setShown(true);
        setAutoClose(true);
        Utility.sleep(message.autoCloseWait ?? 1500).then(() => handleClose(false));
    };

    const createActions = (ask: boolean, autoClose: boolean) => {
        if (ask) {
            return (
                <DialogActions>
                    <Button type="button" variant="contained" color={message?.type} onClick={() => handleClose(true)}>はい</Button>
                    <Button type="button" variant="outlined" color={message?.type} onClick={() => handleClose(false)}>いいえ</Button>
                </DialogActions>
            );
        } else if (!autoClose) {
            return (
                <DialogActions>
                    <Button type="button" variant="outlined" color={message?.type} onClick={() => handleClose()}>閉じる</Button>
                </DialogActions>
            );
        }
        return <></>;
    };

    return (
        <MessageContext.Provider value={{showMessage, showAutoClose}}>
            {props.children}
            {shown && <Dialog open={shown} PaperProps={{style: {backgroundColor: autoClose ? "transparent" : undefined}}} sx={{p: autoClose ? 0 : undefined}}>
                <DialogContent sx={{p: autoClose ? 0 : undefined}}>
                    <Alert className={`Msg-box${autoClose ? " Msg-autoClose" : ""}`} variant={autoClose ? "filled" : undefined} severity={message?.type}>
                        {(message?.title.length ?? 0) > 0 && <AlertTitle color={message?.type}>{message?.title}</AlertTitle>}
                        {(message?.description.length ?? 0) > 0 && <Box sx={{m: autoClose ? undefined : 1}}>
                            {message?.description.map((value, index) => (
                                <React.Fragment key={index}>
                                {index > 0 && <br />}
                                <span>{value}</span>
                                </React.Fragment>
                            ))}
                        </Box>}
                    </Alert>
                    {(message?.fatal ?? false) && <Box>
                        <Typography variant="body2" color="error"></Typography>
                    </Box>}
                </DialogContent>
                {createActions(message?.ask ?? false, autoClose)}
            </Dialog>}
        </MessageContext.Provider>
    );
};
export default MessageProvider;