import React from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button, Card, CardContent, Container, Typography } from "@mui/material";
import { Home, ReportProblem } from "@mui/icons-material";
import { useUser } from "../providers/UserProvider";
import Maintenance from "./Maintenance";

const NotFound = () => {
  const {clearError, error, errorMessage} = useUser();
  const navigate = useNavigate();
  const title = error === 0 ? "404 NotFound" : "401 Unauthorized";
  const message = error === 0 ? "お探しのページが見つかりませんでした。" : "アクセス権が無い、または有効期限切れです。";
  const buttons = error === 0 ? "TOPページに戻る" : "ログインページに戻る"

  const handleToTop = () => {
    clearError();
    navigate("/");
  };

  return errorMessage.length > 0 ? (<Maintenance/>) : (
    <Container sx={{ pt: 5, pl: "auto", pr: "auto", height: "100%" }}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%", p: 2 }}>
        <Card sx={{width: "100%", maxWidth: "sm", m: 1, margin: "auto"}}>
          <CardContent>
            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{height: 200}}>
              <Typography variant="h4" textAlign="center">{title}</Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <ReportProblem fontSize="large" color="error" />
                <Typography component="p" variant="h6">{message}</Typography>
              </Box>
              <Box textAlign="center">
                <Button variant="contained" sx={{width: "15rem"}} startIcon={<Home />} onClick={handleToTop}>{buttons}</Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>    
  );
}
export default NotFound;