import React from "react";
import { Outlet } from "react-router-dom";

import { useUser } from "./providers/UserProvider";
import Rest, { getErrorMessage } from "../services/Rest";
import AsyncResource from "./parts/AsyncResource";
import { Container, Typography } from "@mui/material";
import Common, { OfficeInfo } from "../services/Common";
import SelectOffice from "./parts/SelectOffice";

const Receiver = (props: {resourceData?: OfficeInfo[] | null}) => {
    const model = useUser();
    const handleSelectOffice = (item: OfficeInfo) => {
        model.updateOffice(
            item.id, 
            item.name, 
            item.bizType, 
            item.dataPreserve, 
            item.adlPersonalized, 
            item.availableReceipt,
            item.physicalPlan
        );
    };
    
    React.useLayoutEffect(() => {
        if (props.resourceData?.length === 1) {
            model.updateOffice(
                props.resourceData[0].id!, props.resourceData[0].name, 
                props.resourceData[0].bizType, 
                props.resourceData[0].dataPreserve, 
                props.resourceData[0].adlPersonalized,
                props.resourceData[0].availableReceipt,
                props.resourceData[0].physicalPlan
            );
        }
    }, [model, props.resourceData]);

    return (props.resourceData?.length ?? 0) > 1 ? (
        <Container sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
            <SelectOffice offices={props.resourceData!} onSelect={handleSelectOffice} />
        </Container>
    ) : <></>;
};

const RequireOffice = () => {
    const model = useUser();
    const [message, setMessage] = React.useState("");
    const getUserOffices = async (): Promise<OfficeInfo[]> => {
        const accessToken = await model.getAccessToken();
        return new Promise((resolve, reject) => {
            Common.getUserOffices(accessToken!).then(data => resolve(data)).catch(error => {
                getErrorMessage(error).then(msg => setMessage(msg.message));
                reject(error);
            });
        });
    };

    if (model.user!.office === 0) {
        return (
            <AsyncResource resource={Rest.resource(getUserOffices())} receiver={Receiver} errorFallback={
                <Container>
                    <Typography variant="h6" color="error">事業所情報の取得に失敗しました。</Typography>
                    <Typography variant="body2" color="error">{message}</Typography>
                </Container>                
            } />
        );
    } 
    return <Outlet />;
};
export default RequireOffice;