import yup from "../../../extends/yup.extends";

import { InputCorporateForm } from "../../../types/Corporate";
import { InputOfficeForm } from "../../../types/Office";
import { InputUserForm } from "../../../types/User";

/** メールアドレス入力情報 */
export interface InputMailForm {
    /** メールアドレス */
    mail?: string;
}

/** メールアドレス入力情報スキーマ */
export const InputMailSchema = yup.object({
    mail: yup.string().required("入力必須項目です。").mailAddress("Eメールアドレスを半角英数字で入力してください。").max(254, "254文字以下で入力してください。"),
});

/** 認証入力情報 */
export interface InputCodeForm {
    /** 認証コード */
    code?: string;
}

/** 認証入力情報スキーマ */
export const InputCodeSchema = yup.object({
    code: yup.string().required("入力必須項目です。"),
});

/** 新規登録入力情報 */
type InputSignupForm = InputCodeForm & {
    corporate: InputCorporateForm;
    office: InputOfficeForm;
    user: InputUserForm;
};
export default InputSignupForm;
/** 新規登録デフォルト値 */
export const DefaultSignup: InputSignupForm = { 
    corporate: {}, 
    office: {}, 
    user: {} 
};

/** 新規登録ステップパラメーター */
export interface SignupStepProps {
    /** 新規登録情報 */
    data: InputSignupForm;
    /** 次ステップ変更(次へ)イベント */
    onNext?: (data?: InputSignupForm) => void;
    /** 前ステップ変更(戻る)イベント */
    onBack?: (count?: number) => void;
}