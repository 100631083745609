import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { getErrorMessage } from "../../services/Rest";
import { MultilineLabel } from "./TabPanel";

interface Props { 
    children?: React.ReactNode;
    fallback?: React.ReactNode;
}
interface State { hasError: boolean; message?: string; error?: any; }

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error: any, message?: string): State {
        return { hasError: true, error };
    }

    componentDidMount(): void {
        window.addEventListener("unhandledrejection", this.onUnhandleRejection);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.state.message === undefined && this.state.error !== undefined) {
            getErrorMessage((this.state.error as Error)).then(msg => {
                this.setState({message: msg.message});
            });
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("unhandledrejection", this.onUnhandleRejection);
    }

    onUnhandleRejection = (event: PromiseRejectionEvent) => {
        event.promise.catch(error => {
            getErrorMessage(error).then(msg => this.setState(ErrorBoundary.getDerivedStateFromError(error, msg.message)));
        });
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log("Unexpected error occurred!", error, errorInfo);
    }

    render(): React.ReactNode {
        return !this.state.hasError ? this.props.children : this.props.fallback! ? this.props.fallback : (
            <Box>
                <Typography component="div" variant="h6" sx={{color: t => t.palette.error.main}}>エラーが発生しました。</Typography>
                <Container sx={{mt: "1rem"}}>
                    {this.state.message! && (
                        <MultilineLabel variant="body2" color="error" text={this.state.message} />
                    )}
                    {/*process.env.NODE_ENV === "development" && this.state.error! && (
                        <pre>
                            <Typography component="code" variant="body2">
                                {`${JSON.stringify({...this.state.error}, null, "\t")}`}
                            </Typography>
                        </pre>
                    )*/}
                </Container>
            </Box>
        );
    }
}

export default ErrorBoundary;