import User from "../types/User";

/** 指定されたパスに対して、ユーザーが処理できるかを判定します。 */
const authorizeRole = (path: string, user: User): boolean => {
    return true;
};

/** アクセス制御 */
const AccessControl = {
    authorizeRole,
} as const;
export default AccessControl;;