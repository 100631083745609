import React from "react";
import Define from "../../configurations/Define";
import { useJsonData } from "../../services/Rest";
import Help, { HelpMaster, mappingHelp } from "../../types/HelpTexts";
import Settings, { mappingSetting, Master } from "../../types/Setting";
import JpCalender, { ReferenceMaster, mappingReference } from "../../types/JpCalender";

/** コンテキストモデル */
export type ResourceContextModel = {
    settings: Settings | null;
    helps: Help | null;
    calendar: JpCalender | null;
    reload?: () => void;
};

/** コンテキスト */
export const ResourceContext = React.createContext<ResourceContextModel>({settings: null, helps: null, calendar: null});

/** リソースプロバイダー */
const ResourceProvider = (props: { children: React.ReactNode }) => {
    const [time, setTime] = React.useState(new Date());
    const settingsData = useJsonData<Master>(`${Define.resource}?${time.getTime()}`);
    const settings = mappingSetting(settingsData.data);
    const helpsData = useJsonData<HelpMaster>(`${Define.help}?${time.getTime()}`);
    const helps = mappingHelp(helpsData.data);
    const calendarData = useJsonData<ReferenceMaster>(`${Define.jpChronology}?${time.getTime()}`);
    const calendar = mappingReference(calendarData.data);
    const reload = () => setTime(new Date());
    return (
        <ResourceContext.Provider value={{ settings, helps, calendar, reload }}>
            {props.children}
        </ResourceContext.Provider>
    );
};
export default ResourceProvider;