import yup from "../extends/yup.extends";

/** 法人情報 */
export default interface Corporate {
    /** 契約ユーザー */
    constractUser?: boolean;

    /** 法人コード */
    corprateCode?: string;
    /** お知らせメール */
    notify?: boolean;
    /** 名称 */
    name?: string;
    /** かな */
    kana?: string;
    /** 法人形態 */
    type?: number;
    /** 法人形態位置 */
    typeOffset?: number;
    /** 電話番号 */
    tel?: string;
    /** 郵便番号 */
    zip?: string;
    /** 県コード */
    addressPref?: string;
    /** 住所1 */
    addressMunicipalities?: string;
    /** 住所2 */
    addressDetails?: string;
    /** 担当者役職 */
    picPost?: number;
    /** 担当者名 */
    picName?: string;
    /** 担当者名(かな) */
    picKana?: string;
    /** Eメールアドレス */
    mail?: string;
};

/** 法人入力情報 */
export type InputCorporateForm = Omit<Corporate, "corprateCode">;

/** 法人入力情報スキーマ */
export const InputCorporateSchema = yup.object({
    mail: yup.string().when("constractUser", {
        is: true,
        then: (rule) => rule.required("入力必須項目です。").mailAddress("Eメールアドレスを半角英数字で入力してください。"),
    }),
    notify: yup.boolean(),
    name: yup.string().required("入力必須項目です。").max(274, "274文字以下で入力してください。"),
    kana: yup.string().required("入力必須項目です。").nameKatakana("全角カタカナで入力してください。").max(274, "274文字以下で入力してください。"),
    type: yup.number().typeError("項目を選択してください。"),
    typeOffset: yup.number().required("項目を選択してください。"),
    tel: yup.string().required("入力必須項目です。").phone("電話番号を半角数値ハイフンなしで入力してください。"),
    zip: yup.string().when({
        is: (value?: string | null) => value! && value?.length > 0,
        then: (rule) => rule.zip("郵便番号を半角数値ハイフンなしで入力してください")
    }),
    addressPref: yup.string().required("入力必須項目です。"),
    addressMunicipalities: yup.string().required("入力必須項目です。").max(483, "483文字以下で入力してください。"),
    addressDetails: yup.string().max(80, "80文字以下で入力してください。"),
    picPost: yup.number().typeError("項目を選択してください。"),
    picName: yup.string().required("入力必須項目です。").max(60, "60文字以下で入力してください。"),
    picKana: yup.string().required("入力必須項目です。").nameKatakana("全角カタカナで入力してください。").max(60, "60文字以下で入力してください。"),
});
