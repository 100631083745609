import User, { UserSign } from "../types/User";
import Rest from "./Rest";

type Tokens = {
    idToken: string,
    accessToken: string,
    refreshToken: string,
};
type Ticket = Tokens & {
    initialMenu: number, 
    effectiveMenu: number,
    readonlyFunction: number,
};

/** ログインします。 */
const login = async (sign: UserSign, signal?: AbortSignal): Promise<User> => {
    const ticket = await Rest.post<Ticket>({
        url: "/public_api/login",
        data: {
            corporationCode: sign.corporate,
            login: {
                loginUser: sign.userId,
                password: sign.password
            }
        },
        signal: signal,
    }) as Ticket;
    return {
        corporate: sign.corporate,
        office: 0, officeName: "", availableReceipt: false,
        userId: sign.userId!,

        //accessToken: ticket.idToken,
        //awsToken: ticket.accessToken,
        accessToken: ticket.accessToken,
        awsToken: ticket.idToken,
        refreshToken: ticket.refreshToken,
        
        loggedInAt: new Date(),

        initial: ticket.initialMenu,
        effective: ticket.effectiveMenu,
        referenceOnly: ticket.readonlyFunction,
        dataPreserve: false,
        adlPersonalized: false,
        availablePhysicalPlan: false,
        bizType: 0,
    };
};

/** ログアウトします。 */
const logout = async (accessToken: string, userId: string) => {
    /*return await Rest.post<void>({
        url: "/api/logout", 
        data: { userId: userId }, 
        authorization: accessToken
    });*/
};

const refresh = async (refreshToken: string, signal?: AbortSignal): Promise<Tokens> => {
    const tokens = await Rest.post<Tokens>({
        url: "/public_api/login/token",
        data: refreshToken,
        signal: signal,
    }) as Tokens;
    return tokens;
};


/** 認証機能 */
const UserAuth = { login, logout, refresh } as const;
export default UserAuth;