import React from "react";

import { CircularProgress, TextField, Typography, FormControlLabel, Checkbox, Button, Box, Grid, MenuItem, ListItemText, InputAdornment, Menu, Collapse } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { SignupStepProps } from "./types";
import { InputOfficeForm, InputOfficeSchema } from "../../../types/Office";
import { ResourceContext } from "../../providers/ResourceProvider";
import Define from "../../../configurations/Define";
import Zip, { ZipAddress } from "../../../services/Zip";
import Prefectures from "../../../configurations/PrefecturalCode";
import EnhancedForm from "../../parts/EnhancedForm";

const InputOffice = (props: SignupStepProps) => {
    const { settings } = React.useContext(ResourceContext);
    const { register, handleSubmit, formState: { errors }, watch, setValue, setFocus, trigger } = useForm<InputOfficeForm>({
        mode: "onBlur",
        resolver: yupResolver(InputOfficeSchema),
        defaultValues: {...props.data.office, ...{
            mail: props.data.office.mail ?? props.data.corporate.mail,
            tel: props.data.office.tel ?? props.data.corporate.tel,
            zip: props.data.office.zip ?? props.data.corporate.zip,
            addressPref: props.data.office.addressPref ?? props.data.corporate.addressPref,
            addressMunicipalities: props.data.office.addressMunicipalities ?? props.data.corporate.addressMunicipalities,
            addressDetails: props.data.office.addressDetails ?? props.data.corporate.addressDetails,
        }}
    });

    const [sameCorp, setSameCorp] = React.useState(props.data.office.sameCorporate??true);
    const handleSameCorp = () => {
        const value = !sameCorp;
        setValue("sameCorporate", value)
        setSameCorp(value);
    }
    /*React.useEffect(() => {
        if (!sameCorp) {
            setValue("mail", props.data.corporate.mail);
            trigger("mail");
            setValue("tel", props.data.corporate.tel);
            trigger("tel");
            setValue("fax", "");
            trigger("fax");
            setValue("zip", props.data.corporate.zip);
            trigger("zip");
            setValue("addressPref", props.data.corporate.addressPref);
            trigger("addressPref");
            setValue("addressMunicipalities", props.data.corporate.addressMunicipalities);
            trigger("addressMunicipalities");
            setValue("addressDetails", props.data.corporate.addressDetails);
            trigger("addressDetails");
        }
    }, [sameCorp, setValue, trigger, props.data.corporate]);*/

    const handleBack = () => {
        if (props.onBack !== undefined) {
            props.onBack();
        }
    };
    const onSubmit = (data: InputOfficeForm) => {
        if (props.onNext !== undefined) {
            props.onNext({...props.data, ...{office: data}});
        }
    };

    const [zipLoading, setZipLoading] = React.useState(false);
    const zipRef = React.useRef<HTMLInputElement | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
    const [addresses, setAddresses] = React.useState<ZipAddress[] | null>(null);
    const handleSearchAddress = () => {
        const { zip } = watch();
        if (zip?.length === 7) {
            setZipLoading(true);
            Zip.searchAddress(zip)
            .then(data => {
                if (data.length > 0) {
                    if (data.length === 1) {
                        selectAddress(data[0]);
                    } else {
                        setAddresses(data);
                        setAnchorEl(zipRef.current);
                    }
                } else {
                    console.log(`[${zip}]に該当する住所は見つかりませんでした。`);
                }
            })
            .catch(error => console.log(error))
            .finally(() => setZipLoading(false));
        }
    };
    const selectAddress = (address: ZipAddress) => {
        setValue("addressPref", address.prefcode);
        trigger("addressPref");
        setValue("addressMunicipalities", `${address.address}`);
        trigger("addressMunicipalities");
        setFocus("addressDetails");
        setAddresses(null);
        closeAnchor();
    };
    const closeAnchor = () => setAnchorEl(null);

    const current = watch();
    return (
        <EnhancedForm onSubmit={handleSubmit(onSubmit)} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>事業所情報</Typography>
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-start">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField label="事業所名" className="Mui-required"  
                        autoFocus fullWidth
                        sx={{imeMode: "active"}}
                        placeholder={Define.placeholders["officeName"]}
                        {...register("name")}
                        error={"name" in errors}
                        helperText={errors.name?.message} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField label="事業所定員数" className="Mui-required"  
                        {...register("employeeCount")}
                        type="number" fullWidth
                        error={"employeeCount" in errors}
                        helperText={errors.employeeCount?.message}
                        InputProps={{ inputProps: { min: 0, max: 500 } }}/>
                    </Grid>
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <TextField label="事業所運営形態" select fullWidth className="Mui-required"
                            {...register("type")}
                            value={current.type ?? ""}
                            error={"type" in errors}
                            helperText={errors.type?.message}>
                                {settings?.a.bizHoursTypes.map(item => (
                                    <MenuItem key={item.selectionCode} value={item.selectionCode}>{item.description}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>                        
                    </Grid>
                </Grid>
                <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="flex-start">
                    <FormControlLabel control={<Checkbox checked={!sameCorp} onChange={handleSameCorp} />} 
                    label="法人情報と別の情報を入力する。"
                    sx={{ mt: 1, mb: 1 }} />
                    <Collapse in={!sameCorp}>
                        <Grid container spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <TextField label="事業所メールアドレス" className="Mui-required"  
                                    fullWidth
                                    placeholder={Define.placeholders["mail"]}
                                    {...register("mail")}
                                    error={"mail" in errors}
                                    helperText={errors.mail?.message} />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <TextField label="事業所電話番号" className="Mui-required"  
                                    fullWidth
                                    placeholder={Define.placeholders["tel"]}
                                    {...register("tel")}
                                    error={"tel" in errors}
                                    helperText={errors.tel?.message} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField label="事業所ＦＡＸ番号" 
                                    fullWidth
                                    placeholder={Define.placeholders["fax"]}
                                    {...register("fax")}                                    
                                    error={"fax" in errors}
                                    helperText={errors.fax?.message} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">事業所住所</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label="郵便番号"
                                {...register("zip")}
                                fullWidth
                                sx={{imeMode: "disabled"}}
                                placeholder={Define.placeholders["zip"]}
                                error={"zip" in errors}
                                helperText={errors.zip?.message as string} 
                                inputRef={zipRef}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment title="郵便番号検索" position="end" sx={{cursor: "pointer"}} onClick={handleSearchAddress}>
                                            {zipLoading ? <CircularProgress size="1em" /> : <SearchOutlined />}
                                        </InputAdornment>
                                    )
                                }} />
                                <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeAnchor}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }} 
                                keepMounted
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                {addresses && addresses.map((value, index) => (
                                    <MenuItem key={index} onClick={() => selectAddress(value)}>
                                        <ListItemText>{`${value.address}`}</ListItemText>
                                    </MenuItem>
                                ))}                
                                </Menu>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label="都道府県" select className="Mui-required" 
                                fullWidth
                                {...register("addressPref")}
                                value={current.addressPref ?? ''}
                                error={"addressPref" in errors}
                                helperText={errors.addressPref?.message}>
                                    {Prefectures.map((value) => <MenuItem key={value.code} value={value.code}>{value.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField label="住所1" className="Mui-required" 
                                fullWidth
                                {...register("addressMunicipalities")}
                                placeholder={Define.placeholders["addressMunicipalities"]}
                                error={"addressMunicipalities" in errors}
                                helperText={errors.addressMunicipalities?.message} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField label="住所2" 
                                {...register("addressDetails")}
                                placeholder={Define.placeholders["addressDetails"]}
                                error={"addressDetails" in errors}
                                helperText={errors.addressDetails?.message}
                                fullWidth />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ p: 1 }}>
                <Button variant="contained" onClick={handleBack}>戻る</Button>
                <Button type="submit" variant="contained" color="primary">次へ</Button>
            </Box>
        </EnhancedForm>
    );
};
export default InputOffice;