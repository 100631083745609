import { Box, Button, Card, CardContent, Container, Typography } from "@mui/material";
import { Home, ReportProblem } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { MultilineLabel } from "../parts/TabPanel";
import { useUser } from "../providers/UserProvider";
import React from "react";


const Maintenance = () => {
    const {errorMessage, setErrorMessage, clearError, user, signout} = useUser();
    const navigate = useNavigate();

    const subtitle = "ただいまメンテナンス中です。";
    const messages = React.useMemo(() => [
        `メンテナンス期間: ${errorMessage}`,
        "", 
        "ご利用の皆様にはご迷惑をおかけし、大変申し訳ございません。", 
        "メンテナンス終了までしばらくお待ちください。"
    ].join("\n"), [errorMessage]);

    const handleTop = React.useCallback(() => {
        clearError();
        setErrorMessage("");
        if (user!) {
            signout(user?.userId!, () => navigate("/"));
        } else {
            navigate("/");
        }
    }, [user, signout, clearError, setErrorMessage, navigate]);

    return (
        <Container sx={{ pt: 5, pl: "auto", pr: "auto", height: "100%" }}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%", p: 2 }}>
                <Card sx={{width: "100%", maxWidth: "sm", m: 1, margin: "auto"}}>
                    <CardContent>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <Typography variant="h4" textAlign="center">{"503 ServiceUnavailable"}</Typography>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <ReportProblem fontSize="large" color="error" />
                                <Typography component="p" variant="h6">{subtitle}</Typography>
                            </Box>
                            <Container sx={{ mt:1, mb: 1, textAlign: "center"}}>
                                <MultilineLabel variant="subtitle2" text={messages} />
                            </Container>
                            
                            <Box textAlign="center">
                                <Button variant="contained" sx={{width: "15rem"}} startIcon={<Home />} onClick={handleTop}>{"TOPページに戻る"}</Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};
export default Maintenance;