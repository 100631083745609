import yup from "../extends/yup.extends";

/** 参照カテゴリー */
export const ReferenceMenus = {
    Attendance: 0x2,
    Customers: 0x8,
    Plans: 0x10,
    Assess: 0x20,
    Monitoring: 0x40,
};
export type ReferenceMenu = typeof ReferenceMenus[keyof typeof ReferenceMenus];

/** 認証トークン */
export type UserTokens = {
    /** アクセストークン */
    accessToken: string;
    /** 更新用トークン */
    refreshToken: string;
    /** AWSアクセストークン */
    awsToken: string;
};

/** 認証ユーザー情報 */
export default interface User extends UserTokens {
    /** 法人コード */
    corporate: string;
    /** 事業所コード */
    office: number;
    /** 事業所名 */
    officeName: string;
    /** ユーザーID */
    userId: string;

    /** 利用可能なメニュー */
    effective: number;
    /** ログイン後の表示ページ */
    initial: number;
    /** 参照のみ可能 */
    referenceOnly: number;
    /** 解約後のデータ保持 */
    dataPreserve: boolean;
    /** ADL維持加算個人別 */
    adlPersonalized: boolean;
    /** 事業書運営形態 */
    bizType: number;
    
    /** ログイン時刻 */
    loggedInAt: Date;
    /** レセプト有効 */
    availableReceipt: boolean;
    /** 運動器機能向上計画有効 */
    availablePhysicalPlan: boolean;
}

/** サインインユーザー情報 */
export interface UserSign {
    /** 法人コード */
    corporate: string;
    /** ユーザーID */
    userId?: string;
    /** パスワード */
    password?: string;
}


/** サインインユーザー情報スキーマ */
export const UserSignSchema = yup.object({
    corporate: yup.string().required("入力必須項目です。"),
    userId: yup.string().required("入力必須項目です。"),
    password: yup.string().required("入力必須項目です。"),//.matches(passwordPattern, passwordMessage),
});

/** ユーザーIDの正規表現とエラーメッセージ */
export const userIdPattern = /^[a-zA-Z0-9.\-_]{1,30}$/;
export const userIdMessage = "半角英数字または記号の一部(. - _)の30文字以下で入力してください。";

/** パスワードの正規表現とエラーメッセージ */
export const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~])[a-zA-Z0-9!-/:-@[-`{-~]{8,256}$/
export const passwordMessage = "半角英字・半角数字・記号(! @ # $ % ^ & * ( ) _ + - = [ ] { } | ')を各1字以上含んだ8文字以上256文字以下で入力してください。";

/** ユーザー入力情報 */
export type InputUserForm = Omit<UserSign, "corporate"> & {
    /** パスワード確認 */
    passwordConfirm?: string;
}
/** ユーザー入力情報スキーマ */
export const InputUserSchema = yup.object({
    userId: yup.string().required("入力必須項目です。").matches(userIdPattern, userIdMessage),
    password: yup.string().required("入力必須項目です。").matches(passwordPattern, passwordMessage),
    passwordConfirm: yup.string().required("入力必須項目です。").oneOf([yup.ref("password"), null], "パスワードと一致させてください。"),
});