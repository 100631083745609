import React from "react";

import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseLine from "@mui/material/CssBaseline";

import { createAppTheme } from "./configurations/Theme";
import ResourceProvider from "./components/providers/ResourceProvider";
import UserProvider from "./components/providers/UserProvider";
import FullScreen from "./components/FullScreen";
import MessageProvider from "./components/providers/MessageProvider";
import UserRoutes from "./components/UserRoutes";
import UpdateNotification from "./components/UpdateNotification";

const App = () => {
    const theme = createAppTheme();
    return (
        <ResourceProvider>
            <ThemeProvider theme={theme}>
                <CssBaseLine />
                <FullScreen />
                {false && <UpdateNotification />}
                <UserProvider>
                    <MessageProvider>
                        <BrowserRouter>
                            <UserRoutes />
                        </BrowserRouter>
                    </MessageProvider>
                </UserProvider>
            </ThemeProvider>
        </ResourceProvider>            
    );
};
export default App;