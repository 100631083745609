import React from "react";

import { TextField, Typography, Button, Box, Grid, List, ListItem, ListItemText } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { InputCodeForm, InputCodeSchema, SignupStepProps } from "./types";
import Register from "../../../services/Register";
import Define from "../../../configurations/Define";
import { getErrorMessage } from "../../../services/Rest";
import { MessageContext } from "../../providers/MessageProvider";
import EnhancedForm from "../../parts/EnhancedForm";

const InputCode = (props: SignupStepProps) => {
    const msgBox = React.useContext(MessageContext);
    const { register, handleSubmit, formState: { errors } } = useForm<InputCodeForm>({
        mode: "onBlur",
        resolver: yupResolver(InputCodeSchema)
    });
    const handleBack = () => {
        if (props.onBack !== undefined) {
            props.onBack();
        }
    };

    const onSubmit = (data: InputCodeForm) => {
        Register.authorization(props.data.corporate.mail!, data.code!).then(() => {
            props.onNext! && props.onNext({...props.data, ...data});
        }).catch(error => {
            console.error(error);
            getErrorMessage(error).then(message => {
                msgBox.showMessage({
                    type: "error",
                    title: "認証コードの確認", 
                    description: ["認証コードの検証に失敗しました。", message.message],
                    onClose: handleBack
                });
            });
        });        
    };

    return (
        <EnhancedForm onSubmit={handleSubmit(onSubmit)} 
        display="flex" flexDirection="column" justifyContent="space-between"
        sx={{ height: "100%", p: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>認証コード</Typography>
            <Box flexGrow={1} sx={{ p: 3 }}>
                <Typography variant="body2">メールアドレスを確認するため、受信されたメールの本文に記載された、認証コードを入力してください。</Typography>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TextField label="認証コード" className="Mui-required" 
                        {...register("code")}
                        sx={{ mt: 2, mb: 2 }} 
                        autoFocus fullWidth
                        placeholder={Define.placeholders["code"]}
                        error={"code" in errors}
                        helperText={errors.code?.message as string ?? ""} />
                    </Grid>
                </Grid>
                <Box>
                    <Typography variant="body2">メールが届かない場合は、以下の可能性があります。もう一度はじめからお手続きしてください。</Typography>
                    <List>
                        <ListItem>
                            <ListItemText>メールアドレスに誤りがある。</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>メールの受信制限をされている。</ListItemText>
                        </ListItem>
                    </List>
                </Box>
            </Box>            
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ p: 1 }}>
                <Button variant="contained" onClick={handleBack}>戻る</Button>
                <Button type="submit" variant="contained" color="primary">確認</Button>
            </Box>
        </EnhancedForm>
    );
};
export default InputCode;