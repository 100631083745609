import React from "react";

import { useTheme, Box, IconButton, Button, TextField, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { CommentOutlined, CommentsDisabledOutlined } from "@mui/icons-material";
import { Notepad } from "../../services/Notepad";
import { useUser } from "../providers/UserProvider";
import { MessageContext } from "../providers/MessageProvider";
import { MultilineLabel } from "./TabPanel";

export type MemoListProps = {
    open: boolean;
};

/** メモリスト */
const MemoList = (props: MemoListProps) => {
    const theme = useTheme();
    const {user, getAccessToken} = useUser();
    const {showMessage} = React.useContext(MessageContext);
    const [memo, setMemo] = React.useState<string[]>([]);
    const [selectedIndex, setSelectedIndex] = React.useState<number>();

    const contentRef = React.useRef<HTMLInputElement>(null);
    React.useLayoutEffect(() => {
        if (props.open) {
            getAccessToken().then(accessToken => {
                Notepad.getAll(accessToken!, user!.office).then(data => {
                    setMemo(data);
                }).catch(error => console.error(error));
            });
        } else {
            setSelectedIndex(undefined);
        }
    }, [user, getAccessToken, props.open]);
    React.useLayoutEffect(() => {
        if (contentRef.current!) {
            const content = selectedIndex === undefined ? "": memo[selectedIndex];
            contentRef.current.value = content;
        }
    }, [memo, selectedIndex]);
    const updateMemo = React.useCallback((index: number, content: string) => {
        getAccessToken().then(accessToken => {
            Notepad.updateMemo(accessToken!, user!.office, index, content).then(() => {
                const newMemo = [...memo];
                newMemo[index] = content;
                setMemo(newMemo);
            }).catch(error => console.error(error));
        });
    }, [user, getAccessToken, memo]);

    const handleSelectItem = (index: number) => {
        setSelectedIndex(selectedIndex === index ? undefined : index);
    };
    const handleDeleteItem = (index: number) => {
        showMessage({
            ask: true, type: "info", title: "削除確認", description: ["メモを削除します。", "よろしいですか？"],
            onClose: yes => (yes ?? false) && updateMemo(index, "")
        });
    };
    const handleUpdateItem = () => {
        if (selectedIndex !== undefined) {
            const content = contentRef.current?.value ?? "";
            updateMemo(selectedIndex, content);
        }
    };

    return (
        <Box sx={{ width: "18.75rem", display: "flex", flexDirection: "column", height: "100%", backgroundColor: theme.palette.background.paper }}>
            <Typography variant="subtitle2" sx={{pl: 2, pt: 1, pb: 1, boxShadow: 3}}>MEMO</Typography>
            <List sx={{ 
                p: 0,
                flexGrow: 1, 
                overflowY: "auto", 
                background: theme.palette.background.default,
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": { width: "5px" },
                scrollbarColor: "rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)",
                "&::-webkit-scrollbar-thumb": { background: "rgba(0, 0, 0, 0.3)" },
                "&::-webkit-scrollbar-track": { background: "rgba(0, 0, 0, 0.1)" },
                "& .Memo": { minHeight: "5rem", cursor: "pointer" },
                "& .Memo:nth-of-type(odd)": { backgroundColor: "#eee" },
                "& .Memo.Selected": { backgroundColor: "#fee" },
            }}>
                {memo.map((value, index) => {
                    return (
                        <React.Fragment key={index}>
                            <ListItem className={selectedIndex === index ? "Memo Selected":"Memo"} secondaryAction={
                                <Tooltip title="クリア">
                                <IconButton onClick={() => handleDeleteItem(index)}>
                                    <CommentsDisabledOutlined />
                                </IconButton>
                                </Tooltip>
                            } onClick={() => handleSelectItem(index)}>
                                <ListItemText sx={{ overflow: "wrap", wordBreak: "break-all", pr: 1 }}>
                                    <MultilineLabel text={value}/>
                                </ListItemText>
                            </ListItem>
                        </React.Fragment>
                    );
                })}
            </List>
            <Box sx={{ p: 2, boxShadow: 3,  }}>
                <Box>
                    <TextField id="memo-content" label="メモ" variant="outlined" InputProps={{readOnly: selectedIndex === undefined}} fullWidth multiline rows="3" size="small" inputRef={contentRef} />
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button variant="contained" disabled={selectedIndex === undefined} startIcon={<CommentOutlined />} onClick={handleUpdateItem}>更新</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default MemoList;