import * as yup from "yup";
import { suggestive } from "yup-locale-ja";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject, Maybe } from "yup/lib/types";

// デフォルトのエラーメッセージの日本語化
yup.setLocale(suggestive);

/** 正規表現パターンチェック */
const patternTest = (regex: RegExp, value?: string) => value !== undefined ? regex.test(value) : false;

/** ひらがなの正規表現 */
const hiraganaPattern = /^[\u3040-\u309F]+$/;
/** ひらがな検証 */
yup.addMethod<yup.StringSchema>(yup.string, "kana", function (message?: string) {
    return this.test("kana", message ?? "", value => patternTest(hiraganaPattern, value));
});

/** カタカナの正規表現 */
const katakanaPattern = /^[\u30A0-\u30FF]+$/;
/** カタカナ検証 */
yup.addMethod<yup.StringSchema>(yup.string, "katakana", function (message?: string) {
    return this.test("katakana", message ?? "", value=> patternTest(katakanaPattern, value));
});

/** ふりがなの正規表現 */
const nameKanaPattern = /^(([\u3040-\u309F]+)|([\u3040-\u309F]+ [\u3040-\u309F]+))$/;
/** ふりがな検証 */
yup.addMethod<yup.StringSchema>(yup.string, "nameKana", function (message?: string) {
    return this.test("nameKana", message ?? "", value => patternTest(nameKanaPattern, value));
});

/** フリガナの正規表現 */
const nameKatakanaPattern = /^(([\u30A0-\u30FF]+)|([\u30A0-\u30FF]+( |　)+[\u30A0-\u30FF]+))$/
/** フリガナ検証 */
yup.addMethod<yup.StringSchema>(yup.string, "nameKatakana", function (message?: string) {
    return this.test("nameKatakana", message ?? "", value => patternTest(nameKatakanaPattern, value));
});

/** 電話番号の正規表現 */
const phonePattern = /^(0(\d{9})|((050|070|080|090)\d{8}))$/;
//const phonePattern = ^(0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4})|((050|070|080|090)-\d{4}-\d{4})$;
/** 電話番号検証 */
yup.addMethod<yup.StringSchema>(yup.string, "phone", function (message?: string) {
    return this.test("phone", message ?? "", value => patternTest(phonePattern, value));
});
/** 携帯電話の電話番号 */
const mobilePhonePattern = /^(050|070|080|090)\d{8}$/;
//const mobilePhonePattern = /^(050|070|080|090)-\d{4}-\d{4}$/;
/** 電話番号検証 */
yup.addMethod<yup.StringSchema>(yup.string, "mobilePhone", function (message?: string) {
    return this.test("mobilePhone", message ?? "", value => patternTest(mobilePhonePattern, value));
});
/** 固定電話の電話番号 */
const homePhonePattern = /^(0(\d{9}))$/;
//const oldPhonePattern = /^0(\d-\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{4}-\d)-\d{4}$/
/** 電話番号検証 */
yup.addMethod<yup.StringSchema>(yup.string, "homePhone", function (message?: string) {
    return this.test("homePhone", message ?? "", value => patternTest(homePhonePattern, value));
});

/** 郵便番号の正規表現 */
const zipPattern = /^[0-9]{7}$/;
//const zipPattern = /^[0-9]{3}-[0-9]{4}$/;
/** 郵便番号検証 */
yup.addMethod<yup.StringSchema>(yup.string, "zip", function (message?: string) {
    return this.test("zip", message ?? "", value => patternTest(zipPattern, value));
});

/** 行頭の都道府県の正規表現 */
const beginPrefPattern = /^(群馬県|埼玉県|千葉県|東京都|神奈川県|新潟県|富山県|石川県|福井県|山梨県|長野県|岐阜県|静岡県|愛知県|三重県|滋賀県|京都府|大阪府|兵庫県|奈良県|和歌山県|鳥取県|島根県|岡山県|広島県|山口県|徳島県|香川県|愛媛県|高知県|福岡県|佐賀県|長崎県|熊本県|大分県|宮崎県|鹿児島県|沖縄県|北海道|青森県|岩手県|宮城県|秋田県|山形県|福島県|茨城県|栃木県)/;
/** 行頭の都道府県検証 */
yup.addMethod<yup.StringSchema>(yup.string, "beginPref", function (message?: string){
    return this.test("beginPref", message ?? "", value => patternTest(beginPrefPattern, value));
});

/** 数値の正規表現 */
yup.addMethod<yup.StringSchema>(yup.string, "numeric", function (digits: number, message?: string) {
    const pattern = new RegExp(`^[0-9]{${digits}}$`);
    return this.test("numeric", message ?? "", value => patternTest(pattern, value));
});

/** メールアドレスの正規表現 */
const mailPattern = /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+$/;
/** メールアドレス検証 */
yup.addMethod<yup.StringSchema>(yup.string, "mailAddress", function (message?: string) {
    return this.test("mailAddress", message ?? "", value => patternTest(mailPattern, value));
});

/** 定義の追加 */
declare module "yup" {
    interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
        /** ひらがな入力のバリデーション */
        kana(message?: string): RequiredStringSchema<TType, TContext>;
        /** ふりがな入力のバリデーション */
        nameKana(message?: string): RequiredStringSchema<TType, TContext>;
        /** カタカナ入力のバリデーション */
        katakana(message?: string): RequiredStringSchema<TType, TContext>;
        /** フリガナ入力のバリデーション */
        nameKatakana(message?: string): RequiredStringSchema<TType, TContext>;
        /** 電話番号入力のバリデーション */
        phone(message?: string): RequiredStringSchema<TType, TContext>;
        /** 携帯電話の電話番号入力のバリデーション */
        mobilePhone(message?: string): RequiredStringSchema<TType, TContext>;
        /** 固定電話の電話番号入力のバリデーション */
        homePhone(message?: string): RequiredStringSchema<TType, TContext>;
        /** 郵便番号入力のバリデーション */
        zip(message?: string): RequiredStringSchema<TType, TContext>;
        /** 数値入力のバリデーション */
        numeric(digits: number, message?: string): RequiredStringSchema<TType, TContext>;
        /** メールアドレスのバリデーション */
        mailAddress(message?: string): RequiredStringSchema<TType, TContext>;
        /** 行頭に都道府県のバリデーション */
        beginPref(message?: string): RequiredStringSchema<TType, TContext>;
    }
}
export default yup;

/** サンプル
export interface Employee {
    id?: number;
    name?: string;
    kana?: string;
    tel?: string;
    birthday?: Date;
    description?: string;
}

export type EmployeeForm = Omit<Employee, "id">;

export const EmployeeSchema = typ.object({
    name: yup.string().label("氏名").required("入力必須項目です。").max(40, "40文字以内で入力してください。"),
    kana: yup.string().label("氏名(カナ)").required("入力必須項目です。").nameKatakana("全角カタカナのみで入力してください。").max(40, "40文字以内で入力してください。"),
    tel: yup.string().label("TEL").phone("ハイフンなしの電話番号を入力してください。"),
    birthday: yup.date().label("生年月日").typeError("正確な年月日を入力してください。").required("入力必須項目です。"),
    description: yup.string().label("備考").max(500, "500文字以内で入力してください。"),
});
*/