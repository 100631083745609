export interface HelpItem {
    crtName: string;
    tabName: string;
    titleName: string;
    value: string;
};

export interface HelpMaster {
    help_item: HelpItem[];
}

export const mappingHelp = (data: HelpMaster | null): Help | null => (data === null ? null : { items: data.help_item.map(item => ({
    feature: item.crtName, classes: item.tabName, name: item.titleName, value: item.value
})) ?? [] });

export type HelpText = {
    feature: string;
    classes: string;
    name: string;
    value: string;
};

export default interface Help {
    items: HelpText[];
};