import React from "react";
import { RouteObject } from "react-router-dom";

import { 
    HomeOutlined,
    AssignmentIndOutlined,
    RecentActorsOutlined,
    PostAddOutlined,
    FactCheckOutlined,
    DownloadOutlined, 
    MiscellaneousServicesOutlined, 
    PlaylistAddCircleOutlined, 
    SettingsOutlined, 
    //ReceiptLongOutlined
} from "@mui/icons-material";

import { AccessFilters, MenuItemProps } from "../components/parts/SubMenu";

import SignIn from "../components/pages/SignIn";
import SignUp from "../components/pages/signup";
import NotFound from "../components/pages/NotFound";

const Top = React.lazy(() => import("../components/pages/Top"));
const Attendance = React.lazy(() => import("../components/pages/Attendance"));
const Customers = React.lazy(() => import("../components/pages/customers"));
const CustomersEdit = React.lazy(() => import("../components/pages/customers/CustomersEdit"));

const Planning = React.lazy(() => import("../components/pages/planning"));
const PhysicalPlan = React.lazy(() => import("../components/pages/planning/physical"));
const PhysicalPlanEdit = React.lazy(() => import("../components/pages/planning/physical/PhysicalPlanEdit"));
const PersonalPlan = React.lazy(() => import("../components/pages/planning/personal"));
const PersonalPlanEdit = React.lazy(() => import("../components/pages/planning/personal/PersonalPlanEdit"));
const OralPlan = React.lazy(() => import("../components/pages/planning/oral"));
const OralPlanEdit = React.lazy(() => import("../components/pages/planning/oral/OralPlanEdit"));
const DaycarePlan = React.lazy(() => import("../components/pages/planning/daycare"));
const DaycarePlanEdit = React.lazy(() => import("../components/pages/planning/daycare/DaycarePlanEdit"));

const Assessment = React.lazy(() => import("../components/pages/assessment"));
const PhysicalAssess = React.lazy(() => import("../components/pages/assessment/physical"));
const PhysicalAssessEdit = React.lazy(() => import("../components/pages/assessment/physical/PhysicalAssessEdit"));
const PersonalAssess = React.lazy(() => import("../components/pages/assessment/personal"));
const PersonalAssessEdit = React.lazy(() => import("../components/pages/assessment/personal/PersonalAssessEdit"));
const DaycareAssess = React.lazy(() => import("../components/pages/assessment/daycare"));
const DaycareAssessEdit = React.lazy(() => import("../components/pages/assessment/daycare/DaycareAssessEdit"));
const DaycareMonitor = React.lazy(() => import("../components/pages/assessment/monitoring"));
const DaycareMonitorEdit = React.lazy(() => import("../components/pages/assessment/monitoring/DaycareMonitorEdit"));
const OralAssess = React.lazy(() => import("../components/pages/assessment/oral"));
const OralAssessEdit = React.lazy(() => import("../components/pages/assessment/oral/OralAssessEdit"));

const Tasks = React.lazy(() => import("../components/pages/tasks"));
const DailyTask = React.lazy(() => import("../components/pages/tasks/daily"));
const ReportTask = React.lazy(() => import("../components/pages/tasks/report"));
const ScheduleTask = React.lazy(() => import("../components/pages/tasks/schedule"));
const UtilizerTask = React.lazy(() => import("../components/pages/tasks/utilizer"));

const Life = React.lazy(() => import("../components/pages/life"));
const LifeScience = React.lazy(() => import("../components/pages/life/science"));
const LifeScienceEdit = React.lazy(() => import("../components/pages/life/science/ScienceEdit"));
const LifeAdl = React.lazy(() => import("../components/pages/life/adl"));
const LifeAdlEdit = React.lazy(() => import("../components/pages/life/adl/ADLEdit"));
const LifeOral = React.lazy(() => import("../components/pages/life/oral"));
const LifePersonal = React.lazy(() => import("../components/pages/life/personal"));

const Setting = React.lazy(() => import("../components/pages/setting"));
const CorporateSetting = React.lazy(() => import("../components/pages/setting/corporate"));
const OfficeSetting = React.lazy(() => import("../components/pages/setting/office"));
const SupportSetting = React.lazy(() => import("../components/pages/setting/support"));
const SupportEdit = React.lazy(() => import("../components/pages/setting/support/SupportEdit"));
const CareManagerSetting = React.lazy(() => import("../components/pages/setting/caremanager"));
const CareManagerEdit = React.lazy(() => import("../components/pages/setting/caremanager/CareManagerEdit"));
const TimetableSetting = React.lazy(() => import("../components/pages/setting/timetable"));
const TimetableEdit = React.lazy(() => import("../components/pages/setting/timetable/TimetableEdit"));
const PlanSetting = React.lazy(() => import("../components/pages/setting/plan"));
const EmployeeSetting = React.lazy(() => import("../components/pages/setting/employee"));
const DefaultEdit = React.lazy(() => import("../components/pages/setting/employee/DefaultEdit"));
const TaskSetting = React.lazy(() => import("../components/pages/setting/task"));
const ReceiptSetting = React.lazy(() => import("../components/pages/setting/receipt"));

const Service = React.lazy(() => import("../components/pages/service"));
const ServiceContract = React.lazy(() => import("../components/pages/service/contract"));
const ServiceContractOffice = React.lazy(() => import("../components/pages/service/contract/offices"));
const ServiceUsers = React.lazy(() => import("../components/pages/service/user"));
//const ServiceUtilizer = React.lazy(() => import("../components/pages/service/utilizer"));

const Receipt = React.lazy(() => import("../components/pages/receipt"));
const ReceiptManagement = React.lazy(() => import("../components/pages/receipt/management"));
const ReceiptUnion = React.lazy(() => import("../components/pages/receipt/union"));
const ReceiptCustomer = React.lazy(() => import("../components/pages/receipt/customer"));
const ReceiptOutput = React.lazy(() => import("../components/pages/receipt/output"));

export const MenuNames = {
    Home: "ホーム",
    Attendance: "勤怠",
    Customers: "利用者管理",
    
    Planning: "計画作成",
    Plan_Daycare: "通所介護計画",
    Plan_Physical: "運動器機能向上計画",
    Plan_Personal: "個別機能訓練計画",
    Plan_Oral: "口腔機能向上計画",
    
    Assessment: "計画評価",
    Assess_Daycare: "通所介護計画",
    Assess_Monitor: "モニタリング",
    Assess_Physical: "運動器機能向上計画",
    Assess_Personal: "個別機能訓練計画",
    Assess_Oral: "口腔機能向上計画",

    Life: "LIFE",
    Life_Personal: "個別機能訓練加算Ⅱ",
    Life_Science: "科学的介護推進体制加算",
    Life_ADL: "ADL維持等加算",
    Life_Oral: "口腔機能向上加算Ⅱ",

    Task: "DAY記録",
    Task_Daily: "実施記録",
    Task_Schedule: "スケジュール登録",
    Task_Report: "帳票",
    Task_Utilizer: "利用者構成",

    Receipt: "レセプト",
    Receipt_Manage: "予定・実績管理",
    Receipt_Union: "国保連請求管理",
    Receipt_Customer: "利用者請求管理",
    Receipt_Output: "各種情報出力",

    Setting: "設定",
    Set_Corporate: "法人情報",
    Set_Office: "事業所情報",
    Set_Support: "支援事業所情報",
    Set_CareMana: "ケアマネ情報",
    Set_Plan: "計画書情報",
    Set_TimeTable: "タイムテーブル",
    Set_Employee: "職員情報",
    Set_Task: "実施記録",
    Set_Receipt: "レセプト",

    Service: "サービス管理",
    Service_User: "ログインユーザー管理",
    Service_Contract: "利用内容の確認",
} as const;

/** パンくずリスト */
export const AppBreadcrumbs:  { [url: string]: string } = {
    "/customers/": `${MenuNames.Customers}(利用者選択)`,
    "/customers/edit/": "編集",

    "/planning/": MenuNames.Planning,
    "/planning/daycare/": `${MenuNames.Plan_Daycare}(利用者選択)`,
    "/planning/daycare/edit/": "編集",
    "/planning/physical/": `${MenuNames.Plan_Physical}(利用者選択)`,
    "/planning/physical/edit/": "編集",
    "/planning/personal/": `${MenuNames.Plan_Personal}(利用者選択)`,
    "/planning/personal/edit/": "編集",
    "/planning/oral/": `${MenuNames.Plan_Oral}(利用者選択)`,
    "/planning/oral/edit/": "編集",

    "/assessment/": MenuNames.Assessment,
    "/assessment/daycare/": `${MenuNames.Assess_Daycare}(利用者選択)`,
    "/assessment/daycare/edit/": "編集",
    "/assessment/physical/": `${MenuNames.Assess_Physical}(利用者選択)`,
    "/assessment/physical/edit/": "編集",
    "/assessment/personal/": `${MenuNames.Assess_Personal}(利用者選択)`,
    "/assessment/personal/edit/": "編集",
    "/assessment/oral/": `${MenuNames.Assess_Oral}(利用者選択)`,
    "/assessment/oral/edit/": "編集",
    "/assessment/monitoring/": MenuNames.Assess_Monitor,
    "/assessment/monitoring/edit/": "編集",

    "/life/": MenuNames.Life,
    "/life/personal/": MenuNames.Life_Personal,
    "/life/science/": MenuNames.Life_Science,
    "/life/science/edit/": "編集",
    "/life/adl/": MenuNames.Life_ADL,
    "/life/adl/edit/": "編集",
    "/life/oral/": MenuNames.Life_Oral,

    "/tasks/": MenuNames.Task,
    "/tasks/daily/": MenuNames.Task_Daily,
    "/tasks/report/": MenuNames.Task_Report,
    "/tasks/schedule/": MenuNames.Task_Schedule,
    "/tasks/utilizer/": MenuNames.Task_Utilizer,

    "/setting/": MenuNames.Setting,
    "/setting/corporate/": MenuNames.Set_Corporate,
    "/setting/office/": MenuNames.Set_Office,
    "/setting/support/": `${MenuNames.Set_Support}(選択)`,
    "/setting/support/edit/": "編集",
    "/setting/caremanager/": `${MenuNames.Set_CareMana}(選択)`,
    "/setting/caremanager/edit/": "編集",
    "/setting/timetable/": `${MenuNames.Set_TimeTable}(選択)`,
    "/setting/timetable/edit/": "編集",
    "/setting/plan/": MenuNames.Set_Plan,
    "/setting/employee/": `${MenuNames.Set_Employee}(選択)`,
    "/setting/employee/default/": "職種登録",
    "/setting/task/": MenuNames.Set_Task,
    "/setting/receipt/": MenuNames.Set_Receipt,

    "/service/": MenuNames.Service,
    "/service/user/": MenuNames.Service_User,
    "/service/contract/": MenuNames.Service_Contract, 
    "/service/contract/offices/": "契約事業所",

    "/receipt/": MenuNames.Receipt,
    "/receipt/management/": MenuNames.Receipt_Manage,
    "/receipt/union/": MenuNames.Receipt_Union,
    "/receipt/customer/": MenuNames.Receipt_Customer,
    "/receipt/output/": MenuNames.Receipt_Output,
};

/** メニューツリー */
export const AppMenuTree: MenuItemProps[] = [{ 
    menuKey: 0x1, name: MenuNames.Home, path: "/", icon: <HomeOutlined /> 
}, {
    menuKey: 0x2, name: MenuNames.Attendance, path: "/attendance/", icon: <AssignmentIndOutlined />
}, {
    menuKey: 0x8, name: MenuNames.Customers, path: "/customers/", icon: <RecentActorsOutlined />
}, {
    menuKey: 0x10, name: MenuNames.Planning, path: "/planning/", icon: <PostAddOutlined />, items: [{
        menuKey: 0x10, name: MenuNames.Plan_Daycare, path: "/planning/daycare/"
    }, {
        menuKey: 0x10, name: MenuNames.Plan_Physical, path: "/planning/physical/", accessFilter: AccessFilters.PhysicalPlan
    }, {
        menuKey: 0x10, name: MenuNames.Plan_Personal, path: "/planning/personal/"
    }, {
        menuKey: 0x10, name: MenuNames.Plan_Oral, path: "/planning/oral/"
    }]
}, {
    menuKey: 0x20, name: MenuNames.Assessment, path: "/assessment/", icon: <FactCheckOutlined />, items: [{
        menuKey: 0x20, name: MenuNames.Assess_Daycare, path: "/assessment/daycare/"
    }, {
        menuKey: 0x20, name: MenuNames.Assess_Monitor, path: "/assessment/monitoring/"
    }, {
        menuKey: 0x20, name: MenuNames.Assess_Physical, path: "/assessment/physical/", accessFilter: AccessFilters.PhysicalPlan
    }, {
        menuKey: 0x20, name: MenuNames.Assess_Personal, path: "/assessment/personal/"
    }, {
        menuKey: 0x20, name: MenuNames.Assess_Oral, path: "/assessment/oral/"
    }]
}, {
    menuKey: 0x100, name: MenuNames.Life, path: "/life/", icon: <DownloadOutlined />, items: [{
        menuKey: 0x100, name: MenuNames.Life_Personal, path: "/life/personal/"
    }, {
        menuKey: 0x100, name: MenuNames.Life_Science, path: "/life/science/"
    }, {
        menuKey: 0x100, name: MenuNames.Life_ADL, path: "/life/adl/"
    }, {
        menuKey: 0x100, name: MenuNames.Life_Oral, path: "/life/oral/"
    }]
}, {
    menuKey: 0xf000, name: MenuNames.Task, path: "/tasks/", icon: <PlaylistAddCircleOutlined />, items: [{
        menuKey: 0x1000, name: MenuNames.Task_Daily, path: "/tasks/daily/"
    }, {
        menuKey: 0x4000, name: MenuNames.Task_Schedule, path: "/tasks/schedule/"
    }, {
        menuKey: 0x2000, name: MenuNames.Task_Report, path: "/tasks/report/"
    }, {
        menuKey: 0x8000, name: MenuNames.Task_Utilizer, path: "/tasks/utilizer/"
    }]
}/*, {
    menuKey: 0x4000000, name: MenuNames.Receipt, path: "/receipt/", icon: <ReceiptLongOutlined />, items: [{
        menuKey: 0x4000000, name: MenuNames.Receipt_Manage, path: "/receipt/management/"
    }, {
        menuKey: 0x4000000, name: MenuNames.Receipt_Union, path: "/receipt/union/"
    }, {
        menuKey: 0x4000000, name: MenuNames.Receipt_Customer, path: "/receipt/customer/"
    }, {
        menuKey: 0x4000000, name: MenuNames.Receipt_Output, path: "/receipt/output/"
    }]
}*/, {
    menuKey: 0xff0000, name: MenuNames.Setting, path: "/setting/", icon: <SettingsOutlined />, items: [{
        menuKey: 0x10000, name: MenuNames.Set_Corporate, path: "/setting/corporate/"
    }, {
        menuKey: 0x20000, name: MenuNames.Set_Office, path: "/setting/office/"
    }, {
        menuKey: 0x80000, name: MenuNames.Set_Support, path: "/setting/support/"
    }, {
        menuKey: 0x100000, name: MenuNames.Set_CareMana, path: "/setting/caremanager/"
    }, {
        menuKey: 0x400000, name: MenuNames.Set_Plan, path: "/setting/plan/"
    }, {
        menuKey: 0x200000, name: MenuNames.Set_TimeTable, path: "/setting/timetable/"
    }, {
        menuKey: 0x40000, name: MenuNames.Set_Employee, path: "/setting/employee/"
    }, {
        menuKey: 0x800000, name: MenuNames.Set_Task, path: "/setting/task/"
    }/*, {
        menuKey: 0x10000000, name: MenuNames.Set_Receipt, path: "/setting/receipt/"
    }*/]
}, {
    menuKey: 0x3000000, name: MenuNames.Service, path: "/service/", icon: <MiscellaneousServicesOutlined />, items: [{
        menuKey: 0x1000000, name: MenuNames.Service_User, path: "/service/user/"
    }, {
        menuKey: 0x2000000, name: MenuNames.Service_Contract, path: "/service/contract/"
    }]
}];

export type RoutePage = RouteObject & { access: number };
export const LayoutRoutes: RoutePage[] = [{
    access: 0x1, index: true, element: <Top />
}, {
    access: 0x2, path: "attendance/", element: <Attendance />
}, {
    access: 0x8, path: "customers/", element: <Customers />
}, {
    access: 0x8, path: "customers/edit/", element: <CustomersEdit />
}, {
    access: 0x10, path: "planning/", element: <Planning />
}, {
    access: 0x10, path: "planning/daycare/", element: <DaycarePlan />
}, {
    access: 0x10, path: "planning/daycare/edit/", element: <DaycarePlanEdit />
}, {
    access: 0x10, path: "planning/physical/", element: <PhysicalPlan />
}, {
    access: 0x10, path: "planning/physical/edit/", element: <PhysicalPlanEdit />
} ,{
    access: 0x10, path: "planning/personal/", element: <PersonalPlan />
}, {
    access: 0x10, path: "planning/personal/edit", element: <PersonalPlanEdit />
}, {
    access: 0x10, path: "planning/oral/", element: <OralPlan />
}, {
    access: 0x10, path: "planning/oral/edit/", element: <OralPlanEdit />
}, {
    access: 0x20, path: "assessment/", element: <Assessment />
}, {
    access: 0x20, path: "assessment/daycare/", element: <DaycareAssess />
}, {
    access: 0x20, path: "assessment/daycare/edit/", element: <DaycareAssessEdit />
}, {
    access: 0x20, path: "assessment/physical/", element: <PhysicalAssess />
}, {
    access: 0x20, path: "assessment/physical/edit/", element: <PhysicalAssessEdit />
}, {
    access: 0x20, path: "assessment/personal/", element: <PersonalAssess />
}, {
    access: 0x20, path: "assessment/personal/edit/", element: <PersonalAssessEdit />
}, {
    access: 0x20, path: "assessment/oral/", element: <OralAssess />
}, {
    access: 0x20, path: "assessment/oral/edit/", element: <OralAssessEdit />
}, {
    access: 0x20, path: "assessment/monitoring/", element: <DaycareMonitor />
}, {
    access: 0x20, path: "assessment/monitoring/edit/", element: <DaycareMonitorEdit />
}, {
    access: 0x100, path: "life/", element: <Life />
}, {
    access: 0x100, path: "life/science/", element: <LifeScience />
}, {
    access: 0x100, path: "life/science/edit", element: <LifeScienceEdit />
}, {
    access: 0x100, path: "life/personal/", element: <LifePersonal />
}, {
    access: 0x100, path: "life/adl/", element: <LifeAdl />
}, {
    access: 0x100, path: "life/adl/edit", element: <LifeAdlEdit />
}, {
    access: 0x100, path: "life/oral/", element: <LifeOral />
}, {
    access: 0xf000, path: "tasks/", element: <Tasks />
}, {
    access: 0x1000, path: "tasks/daily/", element: <DailyTask />
}, {
    access: 0x2000, path: "tasks/report/", element: <ReportTask />
}, {
    access: 0x4000, path: "tasks/schedule/", element: <ScheduleTask />
}, {
    access: 0x8000, path: "tasks/utilizer/", element: <UtilizerTask />
}, {
    access: 0xff0000, path: "setting/", element: <Setting />
}, {
    access: 0x10000, path: "setting/corporate/", element: <CorporateSetting />
}, {
    access: 0x20000, path: "setting/office/", element: <OfficeSetting />
}, {
    access: 0x80000, path: "setting/support/", element: <SupportSetting />
}, {
    access: 0x80000, path: "setting/support/edit/", element: <SupportEdit />
}, {
    access: 0x100000, path: "setting/caremanager/", element: <CareManagerSetting />
}, {
    access: 0x100000, path: "setting/caremanager/edit/", element: <CareManagerEdit />
}, {
    access: 0x200000, path: "setting/timetable/", element: <TimetableSetting />
}, {
    access: 0x200000, path: "setting/timetable/edit/", element: <TimetableEdit />
}, {
    access: 0x400000, path: "setting/plan/", element: <PlanSetting />
}, {
    access: 0x40000, path: "setting/employee/", element: <EmployeeSetting />
}, {
    access: 0x40000, path: "setting/employee/default/", element: <DefaultEdit />
}, {
    access: 0x800000, path: "setting/task/", element: <TaskSetting />
}, {
    access: 0x10000000, path: "setting/receipt/", element: <ReceiptSetting />
}, {
    access: 0x3000000, path: "service/", element: <Service />
}, {
    access: 0x1000000, path: "service/user/", element: <ServiceUsers />
}, {
    access: 0x2000000, path: "service/contract/", element: <ServiceContract />
}, {
    access: 0x2000000, path: "service/contract/offices/", element: <ServiceContractOffice/>
}, {
    access: 0x4000000, path: "receipt/", element: <Receipt />
}, {
    access: 0x4000000, path: "receipt/management/", element: <ReceiptManagement />
}, {
    access: 0x4000000, path: "receipt/union/", element: <ReceiptUnion />
}, {
    access: 0x4000000, path: "receipt/customer/", element: <ReceiptCustomer />
}, {
    access: 0x4000000, path: "receipt/output/", element: <ReceiptOutput />
}];

/** 全体のルーティング情報 */
const Routing: RouteObject[] = [{ 
    path: "/signin/", element: <SignIn /> 
}, { 
    path: "/signup/", element: <SignUp /> 
}, { 
    path: "*", element: <NotFound /> 
}];
export default Routing;