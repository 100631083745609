import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app";


const GlobalDebug = (() => {
  const savedConsole = { log: console.log, debug: console.debug, info: console.info, warn: console.warn, error: console.error };
  return (debugOn: boolean) => {
    if (debugOn) {
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
      console.error = () => {};
    } else {
      console.log = savedConsole.log;
      console.debug = savedConsole.debug;
      console.info = savedConsole.info;
      console.warn = savedConsole.warn;
      console.error = savedConsole.error;
    }
  };
})();
if (process.env.NODE_ENV !== "development") {
  GlobalDebug(false);
}

console.log("begin react web app REHAQUM");
const root = document.getElementById("root") as HTMLElement;
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);